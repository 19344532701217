export const mainColor = "#9c27b0";

export const mainBlue = "#1976d2";

export const lightBlue = "#3c8bd9";

export const ytRedColor = "#f00"

export const fugaGreen = "#56cc82";

export const redWarning = "rgb(111, 2, 2)";

export const yellowPending = "rgb(231, 190, 66)";

export const lightPurple = "rgb(230, 230, 250)";

export const metalizedColor = "rgb(66, 90, 87)";

export const materialColor = "#353a40";

export const primaryColor = ["#9c27b0", "#ab47bc", "#8e24aa", "#af2cc5"];
export const warningColor = ["#ff9800", "#ffa726", "#fb8c00", "#ffa21a"];
export const dangerColor = ["#f44336", "#ef5350", "#e53935", "#f55a4e"];
export const successColor = ["#4caf50", "#66bb6a", "#43a047", "#5cb860"];
export const infoColor = ["#00acc1", "#26c6da", "#00acc1", "#00d3ee"];
export const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#eb3573"];
export const grayColor = [
  "#999",
  "#777",
  "#3C4858",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#b4b4b4",
  "#555555",
  "#333",
  "#a9afbb",
  "#eee",
  "#e7e7e7"
];
export const blackColor = "#000";
export const whiteColor = "#FFF";

export const planChipsColor = "#53364e";

// COHESIVE PALETTE: https://javier.xyz/cohesive-colors/
export const cohesiveGrey = "#555e7b";
export const cohesiveGreen = "#b7d968";
export const cohesivePurple = "#b576ad";
export const cohesiveRed = "#e04644";
export const cohesiveYellow = "#fde47f";
export const cohesiveOrange = "#FF9C5B";
export const cohesiveLigthBlue = "#7ccce5";
export const cohesiveViola = "#351330bd";

export const greenSuccess = '#109110';
export const redDelete = '#c20202';
export const subtitleColor = '#aaaaaa';