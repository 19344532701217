import React from 'react';
import { Grid, Button } from '@mui/material';
import AlbumLinksButton from './AlbumLinksButton';

const AlbumActionButtons = ({
  canRedeliver,
  canEditRelease,
  isEditing,
  areMissingTracks,
  handleEditOrRedeliver,
  handleCancelEditing,
  handleActionDialog,
  handleOpenDsps,
  albumData,
}) => {

  const buttonColorStyle = {
    width: '100px', backgroundColor: "#508062", '&:hover': { backgroundColor: "#508062" }
  };

  return (
    <Grid item xs={12} textAlign='center' sx={{ display: 'block ruby' }} padding={1}>
      {isEditing && canRedeliver && canEditRelease && (
        <Grid item padding={1}>
          <Button
            variant="contained"
            onClick={handleEditOrRedeliver}
            sx={buttonColorStyle}
          >
            {areMissingTracks ? 'Distribuir' : 'Redistribuir'}
          </Button>
        </Grid>
      )}

      {!isEditing && canEditRelease && (
        <Grid item padding={1}>
          <Button
            variant="contained"
            onClick={handleEditOrRedeliver}
            sx={buttonColorStyle}
          >
            Editar
          </Button>
        </Grid>
      )}

      {isEditing && (
        <Grid item padding={1}>
          <Button variant="contained" onClick={handleCancelEditing} sx={buttonColorStyle}>
            Cancelar
          </Button>
        </Grid>
      )}

      {!isEditing && canEditRelease && (
        <Grid item padding={1}>
          <Button variant="contained" onClick={handleActionDialog} sx={buttonColorStyle}>
            Opciones
          </Button>
        </Grid>
      )}

      {canRedeliver && canEditRelease && !isEditing && (
        <Grid item padding={1}>
          <Button variant="contained" onClick={handleOpenDsps} sx={buttonColorStyle}>
            DSP's
          </Button>
        </Grid>
      )}

      {!isEditing && (
        <AlbumLinksButton albumData={albumData} buttonColorStyle={buttonColorStyle} />
      )}
    </Grid>
  );
};

export default AlbumActionButtons;
