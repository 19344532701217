import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button, Dialog, DialogContent, DialogActions, Grid, DialogContentText
} from '@mui/material';
import { toWithOutError, formatAllNumber } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import SuccessDialog from '../../components/Dialogs/SuccessDialog';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import { Delete, Edit } from '@mui/icons-material/';
import { mainBlue, lightBlue, grayColor } from 'variables/colors';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import { getIdToEdit, getMethodPayFromPayout, getPayoutById } from "utils/payouts.utils";
import { payoutDeleteRedux } from "redux/actions/PayoutsActions";
import { completePayoutSubtitle } from "utils/textToShow.utils";
import { payoutCompleteRequestRedux, payoutEditRedux } from '../../redux/actions/PayoutsActions';
import { getUserAvailableMoney } from "services/BackendCommunication";
import CopyableEditText from "components/TextField/CopyableEditText";
import { getPayoutByIdFS } from "services/FirestoreServices";

const PayoutActionsDialog = (props) => {

  const { isOpen, handleClose, payoutId } = props;

  const dispatch = useDispatch();
  const payouts = useSelector(store => store.payouts.payouts);

  const payout = getPayoutById(payouts, payoutId);
  const userFromPayout = { id: payout.ownerId, email: payout.ownerEmail };
  const methodPayToShow = getMethodPayFromPayout(payout.paymentMethod);
  const hasComissionOfFive = methodPayToShow === "Payoneer" || methodPayToShow === "Cripto" || methodPayToShow === "Wise" || methodPayToShow === "Depósito USD";

  const loadingFinancialDataState = { payed: "...", royalties: "...", available: "...", lastRequest: "...", taxesUsd: "..." };
  // const errorFinancialDataState = { payed: "ERROR", royalties: "ERROR", available: "ERROR", lastRequest: "ERROR", taxesUsd: "ERROR" };
  const [financialInfo, setFinancialInfo] = useState(loadingFinancialDataState);
  const [payoutFromFS, setPayoutFromFS] = useState({});
  const [userFinancialData, setUserFinancialData] = useState({});

  const [loading, setLoading] = useState(false);
  const [buttonState, setButtonState] = useState('none');
  const [completePayoutState, setCompletePayoutState] = useState('none');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, title: "", subtitle: [""], initialValues: "", values: "" });

  useEffect(() => {
    const getUserFinancialInfo = async () => {
      let { payed, royalties, available, lastRequest, taxesUsd } = await getUserAvailableMoney(userFromPayout, dispatch);
      setFinancialInfo({ payed, royalties, available, lastRequest, taxesUsd });
    }

    if (isOpen && payout.ownerId) {
      getUserFinancialInfo();
    }
  }, [payout])

  useEffect(() => {
    const getPayout = async () => {
      let payoutInFS = await getPayoutByIdFS(payout.id, dispatch);
      if (payoutInFS === "ERROR") return;
      setPayoutFromFS(payoutInFS);
      setUserFinancialData(payoutInFS.userFinancialData);
    }

    if (isOpen && payout.ownerId) {
      getPayout();
    }
  }, [payout])

  let successDialogTitle = "Pago actualizado correctamente.";
  let successDialogText = ["Se le envío un Email al usuario para notificarlo."];

  const handleCloseDelete = () => setOpenDeleteDialog(false);

  const handleDelete = async () => {
    setLoading(true);
    let result = await toWithOutError(dispatch(payoutDeleteRedux(payoutId)));
    if (result === "ERROR") { setButtonState("error"); setLoading(false); }
    else { setLoading(false); setOpenDeleteDialog(false); handleCloseActions(); }
  }

  const handleAddPayId = () => setOpenEditDialog({
    open: true, title: "ID del Pago o Cupón", subtitle: completePayoutSubtitle, handleConfirm: (paymentId) => handleCompletePayout(paymentId),
    initialValues: "", values: "", handleCloseDialog: handleCloseEditDialog, type: ''
  });

  const handleEditId = () => setOpenEditDialog({
    open: true, title: "Editar ID", subtitle: "", handleConfirm: (newPayId) => handleEditPayId(newPayId),
    initialValues: userFinancialData.paymentId, values: "", handleCloseDialog: handleCloseEditDialog, type: ''
  });

  const handleEditPayId = async newPayId => {
    setButtonState("loading");
    let userFinancialDataTemp = { ...userFinancialData, paymentId: newPayId };
    let editResult = await toWithOutError(dispatch(payoutEditRedux({ ...payout, commercialId: newPayId, userFinancialData: userFinancialDataTemp })));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleCompletePayout = async paymentId => {
    setButtonState("loading");
    let userFinancialDataTemp = { ...userFinancialData, paymentId };
    let editResult = await toWithOutError(dispatch(payoutCompleteRequestRedux({ ...payout, commercialId: paymentId, userFinancialData: userFinancialDataTemp })));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleCloseEditDialog = () => {
    setOpenEditDialog({ open: false, title: "", subtitle: [""], values: "" });
  }

  const handleCloseActions = () => {
    setFinancialInfo(loadingFinancialDataState);
    handleClose();
  }

  const jsxUserName = <DialogContentText key={'user name'}>
    Nombre Usuario: <b>{`${payoutFromFS.userName} ${payoutFromFS.userLastName}`}</b>
  </DialogContentText>

  const jsxOwnerEmail = <DialogContentText key={'owner email'}>
    Email de Usuario: <b>{payout.ownerEmail}</b>
  </DialogContentText>

  const jsxCuit = <DialogContentText key={'cuit'}>
    CUIT: <b>{`${userFinancialData.cuit}`}</b>
  </DialogContentText>

  const jsxCbu = <DialogContentText key={'cbu'}>
    CBU/CVU: <b>{`${userFinancialData.cbuCvuAlias}`}</b>
  </DialogContentText>

  const jsxOtherPayId = <DialogContentText key={'coelsa-id'}>
    {`${userFinancialData.cbuCvuAlias ? "COELSA ID: " : "Cupón ID: "}`} <b>{`${userFinancialData.coelsaId}`}</b>
  </DialogContentText>

  const jsxPayPalEmail = <DialogContentText key={'paypal-email'}>
    Email de Paypal: <b>{`${userFinancialData.email}`}</b>
  </DialogContentText>

  const jsxPayPalId = <DialogContentText key={'paypal-id'}>
    ID de Paypal: <b>{`${userFinancialData.paymentId ? userFinancialData.paymentId : "no provisto"}`}</b>
  </DialogContentText>

  const jsxWiseEmail = <DialogContentText key={'wise-email'}>
    Email de Wise: <b>{`${userFinancialData.email}`}</b>
  </DialogContentText>

  const jsxWiseId = <DialogContentText key={'wise-id'}>
    ID de Wise: <b>{`${userFinancialData.paymentId ? userFinancialData.paymentId : "completar"}`}</b>
  </DialogContentText>

  const jsxPayoneerEmail = <DialogContentText key={'payoneer-email'}>
    Email de Payoneer: <b>{`${userFinancialData.email}`}</b>
  </DialogContentText>

  const jsxPayoneerId = <DialogContentText key={'payoneer-id'}>
    ID de Payoneer: <b>{`${userFinancialData.paymentId}`}</b>
  </DialogContentText>

  const jsxPayoutId = <DialogContentText key={'pay-id'}>
    ID del pago en la APP: <b>{`${payout.id}`}</b>
  </DialogContentText>

  const jsxMpId = <DialogContentText key={'mp-id'}>
    Id de Mercado Pago: <b>{`${userFinancialData.paymentId ? userFinancialData.paymentId : "completar"}`}</b>
  </DialogContentText>

  const jsxCriptoCurrencie = <DialogContentText key={'currencie-cripto'}>
    Moneda Cripto: <b>{`${userFinancialData.cryptoType ? userFinancialData.cryptoType : "no provista"}`}</b>
  </DialogContentText>

  const jsxRedCripto = <DialogContentText key={'red-cripto'}>
    Red Cripto: <b>{`${userFinancialData.network ? userFinancialData.network : "no provista"}`}</b>
  </DialogContentText>

  // Hasta que haga la refactorizacion del doc de PAYOUT: Payoneer email sera address cripto cuando sea cripto.
  const jsxAddressCripto = <DialogContentText key={'address-cripto'}>
    Dirección Cripto: <b>{`${userFinancialData.address ? userFinancialData.address : "no provista"}`}</b>
  </DialogContentText>

  const jsxCupon = <DialogContentText key={'cupon'}>
    Cupón: <b>{payout.commercialId}</b>
  </DialogContentText>

  const jsxUsdTransferAccountNumber = <DialogContentText key={'usd-accountNumber'}>
    Número de cuenta/IBAN: <b>{userFinancialData.accountNumber}</b>
  </DialogContentText>

  const jsxUsdTransferACH = <DialogContentText key={'usd-ach'}>
    Número de ruta ACH: <b>{userFinancialData.achRoutingNumber}</b>
  </DialogContentText>

  const jsxUsdTransferAchAccountType = <DialogContentText key={'usd-ach-account-type'}>
    Tipo de Cuenta: <b>{userFinancialData.accountType}</b>
  </DialogContentText>

  const jsxUsdTransferWire = <DialogContentText key={'usd-wire'}>
    Número de ruta Fedwire: <b>{userFinancialData.wireRoutingNumber}</b>
  </DialogContentText>

  const jsxUsdTransferSwift = <DialogContentText key={'usd-swift'}>
    Código SWIFT/BIC: <b>{userFinancialData.swiftCode}</b>
  </DialogContentText>

  const jsxUsdTransferPayId = <DialogContentText key={'usd-pay-id'}>
    ID de pago USD: <b>{userFinancialData.paymentId || "completar"}</b>
  </DialogContentText>

  return (
    <>
      <SuccessDialog isOpen={completePayoutState !== 'none' && completePayoutState !== 'processing'} title={successDialogTitle}
        contentTexts={successDialogText} handleClose={() => setCompletePayoutState('none')}
        successImageSource="/images/success.jpg" size="sm" />

      <DeleteDialog isOpen={openDeleteDialog} setIsOpen={setOpenDeleteDialog} handleClose={handleCloseDelete}
        title={"Eliminar Solicitud"} textName={payout.ownerEmail} textContent={''}
        deleteAction={handleDelete} deleteButtonText={"Eliminar"} openLoader={loading} buttonState={'delete'}
      />

      <EditOrAddFieldsDialog loading={buttonState === "loading"}
        buttonState={buttonState} type={""} editOptions={openEditDialog} setEditOptions={setOpenEditDialog} />

      <Dialog
        maxWidth="sm"
        id="info-dialog"
        open={isOpen}
        onClose={handleCloseActions}>

        <DialogContent>
          <DialogContentText key={'subtitle'}>
            <p style={{ fontSize: "20px" }}><b>Información del Pago</b></p>
          </DialogContentText>

          {payout.ownerEmail && <CopyableEditText textToCopy={payout.ownerEmail} color='grey' jsxElementText={jsxOwnerEmail} />}
          {payoutFromFS.userName && <CopyableEditText textToCopy={payoutFromFS.userName} color='grey' jsxElementText={jsxUserName} />}

          {methodPayToShow === 'Depósito' && <CopyableEditText textToCopy={userFinancialData.paymentId ? userFinancialData.paymentId : "completar"} color='grey' jsxElementText={jsxMpId} />}
          {methodPayToShow === 'Depósito' && <CopyableEditText textToCopy={userFinancialData.cuit} color='grey' jsxElementText={jsxCuit} />}
          {userFinancialData.cbuCvuAlias && <CopyableEditText textToCopy={userFinancialData.cbuCvuAlias} color='grey' jsxElementText={jsxCbu} />}
          {userFinancialData.coelsaId && <CopyableEditText textToCopy={userFinancialData.coelsaId} color='grey' jsxElementText={jsxOtherPayId} />}

          {methodPayToShow === 'Depósito USD' && <CopyableEditText textToCopy={userFinancialData.paymentId ? userFinancialData.paymentId : "completar"} color='grey' jsxElementText={jsxUsdTransferPayId} />}
          {methodPayToShow === 'Depósito USD' && userFinancialData.transferType === "ACH" && <CopyableEditText textToCopy={userFinancialData.achRoutingNumber} color='grey' jsxElementText={jsxUsdTransferACH} />}
          {methodPayToShow === 'Depósito USD' && userFinancialData.transferType === "ACH" && <CopyableEditText color='grey' jsxElementText={jsxUsdTransferAchAccountType} />}
          {methodPayToShow === 'Depósito USD' && userFinancialData.transferType === "Wire" && <CopyableEditText textToCopy={userFinancialData.wireRoutingNumber} color='grey' jsxElementText={jsxUsdTransferWire} />}
          {methodPayToShow === 'Depósito USD' && userFinancialData.transferType === "SWIFT" && <CopyableEditText textToCopy={userFinancialData.swiftCode} color='grey' jsxElementText={jsxUsdTransferSwift} />}
          {methodPayToShow === 'Depósito USD' && <CopyableEditText textToCopy={userFinancialData.accountNumber} color='grey' jsxElementText={jsxUsdTransferAccountNumber} />}


          {methodPayToShow === 'PayPal' && userFinancialData.email && <CopyableEditText textToCopy={userFinancialData.email} color='grey' jsxElementText={jsxPayPalEmail} />}
          {methodPayToShow === 'PayPal' && userFinancialData.paymentId && <CopyableEditText textToCopy={userFinancialData.paymentId} color='grey' jsxElementText={jsxPayPalId} />}

          {methodPayToShow === 'Cupón' && payout.commercialId && <CopyableEditText textToCopy={payout.commercialId} color='grey' jsxElementText={jsxCupon} />}

          {methodPayToShow === 'Wise' && <CopyableEditText textToCopy={userFinancialData.email} color='grey' jsxElementText={jsxWiseEmail} />}
          {methodPayToShow === 'Wise' && <CopyableEditText textToCopy={userFinancialData.paymentId ? userFinancialData.paymentId : "completar"} color='grey' jsxElementText={jsxWiseId} />}

          {payout.id && <CopyableEditText textToCopy={payout.id} color='grey' jsxElementText={jsxPayoutId} />}

          {methodPayToShow === 'Payoneer' && <CopyableEditText textToCopy={userFinancialData.email} color='grey' jsxElementText={jsxPayoneerEmail} />}
          {methodPayToShow === 'Payoneer' && <CopyableEditText textToCopy={userFinancialData.paymentId ? userFinancialData.paymentId : "completar"} color='grey' jsxElementText={jsxPayoneerId} />}

          {methodPayToShow === 'Cripto' && <CopyableEditText textToCopy={userFinancialData.cryptoType} color='grey' jsxElementText={jsxCriptoCurrencie} />}
          {methodPayToShow === 'Cripto' && <CopyableEditText textToCopy={userFinancialData.network} color='grey' jsxElementText={jsxRedCripto} />}
          {methodPayToShow === 'Cripto' && <CopyableEditText textToCopy={userFinancialData.address} color='grey' jsxElementText={jsxAddressCripto} />}

          {methodPayToShow === "Payoneer" && <DialogContentText key={'amount-to-transfer-payoneer'} paddingTop={2}>
            Pedido: <b>{`${payout.currency === "USD" ? formatAllNumber(payout.transferTotalUsd, 2, '.') + ' USD' : formatAllNumber(payout.transferTotalAskedCurrency, 2, '.') + ' ARS'}`}</b>
          </DialogContentText>}

          {hasComissionOfFive && <DialogContentText key={'amount-to-transfer-original'} paddingTop={2}>
            Monto Solicitado: <b>{formatAllNumber(payout.transferTotalUsd, 2, '.') + ' USD'}</b>
          </DialogContentText>}

          <DialogContentText key={'amount-to-transfer'}>
            A pagar: <b>{`${payout.currency === "USD"
              ? formatAllNumber(hasComissionOfFive ? payout.transferTotalUsd * .95 : payout.transferTotalUsd, 2, '.') + ' USD'
              : formatAllNumber(payout.transferTotalAskedCurrency, 2, '.') + ' ARS'}`}</b>
          </DialogContentText>

          <DialogContentText sx={{ paddingTop: 1 }} key={'royalties'}>
            Regalías generadas por el Usuario: <b>{`${financialInfo?.royalties} USD`}</b>
          </DialogContentText>

          <DialogContentText key={'taxes'}>
            Impuestos del Usuario: <b>{`${financialInfo?.taxesUsd} USD`}</b>
          </DialogContentText>

          <DialogContentText key={'withdawals'}>
            Dinero retirado por el Usuario: <b>{`${financialInfo?.payed} USD`}</b>
          </DialogContentText>

          <DialogContentText sx={{ paddingTop: 1, textDecoration: 'underline' }} key={'available money'}>
            Dinero disponible del Usuario: <b>{`${financialInfo?.available} USD`}</b>
          </DialogContentText>

          <Grid container direction="column" paddingTop={2}>

            <Grid item xs={6} padding={1}>
              <Button
                onClick={handleAddPayId}
                sx={{
                  backgroundColor: payout.status === "REQUESTED" ? mainBlue : grayColor[11], color: 'white',
                  '&:hover': { backgroundColor: payout.status === "REQUESTED" ? lightBlue : grayColor[11] }
                }}
                endIcon={<Edit />}
                disabled={payout.status !== "REQUESTED"}
                fullWidth>
                {payout.status === "REQUESTED" ? `Completar Pago: ${methodPayToShow}` : "Pago Realizado"}
              </Button>
            </Grid>

            {methodPayToShow === 'Depósito' && <Grid item xs={6} padding={1}>
              <Button
                onClick={handleEditId}
                sx={{
                  backgroundColor: !userFinancialData.paymentId ? mainBlue : grayColor[11], color: 'white',
                  '&:hover': { backgroundColor: !userFinancialData.paymentId ? lightBlue : grayColor[11] }
                }}
                endIcon={<Edit />}
                disabled={userFinancialData.paymentId !== ""}
                fullWidth>
                {!userFinancialData.paymentId ? "Agregar MP ID" : "MP ID Agregado"}
              </Button>
            </Grid>}

            <Grid item xs={6} padding={1}>
              <Button
                onClick={handleEditId}
                sx={{ backgroundColor: mainBlue, color: 'white', '&:hover': { backgroundColor: lightBlue } }}
                endIcon={<Edit />}
                fullWidth>
                {getIdToEdit(payout.paymentMethod)}
              </Button>
            </Grid>

            <Grid item xs={6} padding={1}>
              <Button
                onClick={() => setOpenDeleteDialog(true)}
                sx={{ backgroundColor: '#c20202', color: 'white', '&:hover': { backgroundColor: '#c20202' } }}
                endIcon={<Delete />}
                fullWidth>
                Eliminar
              </Button>
            </Grid>

          </Grid>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseActions} color="primary">
            Atrás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PayoutActionsDialog;

PayoutActionsDialog.defaultProps = {
  isOpen: false,
}

PayoutActionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  payoutId: PropTypes.string.isRequired,
}