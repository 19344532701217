import * as React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const BasicCheckbox = ({ checked, label, onChecked, color, labelColor, disabled }) => {

  const checkBoxStyle = {
    color: color,
    '&.Mui-checked': {
      color: color,
    },
  }

  return (
    <FormControlLabel
      key={label}
      control={<Checkbox disabled={disabled ? disabled : false} key={label} checked={checked} onChange={onChecked} sx={color ? { mr: 0, ...checkBoxStyle } : { mr: 0 }} />}
      label={label}
      sx={{ display: "inline-flex", fontSize: "20px", color: labelColor ? labelColor : "" }}
    />
  );
}

export default BasicCheckbox;
