import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toWithOutError } from 'utils';
import { getAlbumsPropsForAdminDataTable, getAlbumsPropsForUsersDataTable } from "utils/tables.utils";
import { getSearchedUserRedux, usersGetOneByIdRedux } from "../../redux/actions/UsersActions";
import UserDialog from '../Users/UserDialog';
import { getEmailIfNotHaveUser } from '../../utils/users.utils';
import useFirestoreQuery from '../../customHooks/useFirestoreQuery';
import { getElementsAdminQueryFS } from "services/FirestoreServices";
import { filterAlbumsByAdminOrNot, getAlbumsByCustomQuery, sortAlbumsByField, stateNameToStateId } from '../../utils/albums.utils';
import { albumsAddStore, getAlbumsByFieldsRedux, getAlbumsByFieldRedux } from "redux/actions/AlbumsActions";


import AlbumActionsDialog from "views/Albums/AlbumActionsDialog";
import CustomizedTable from 'components/Table/CustomizedTable';
import { cohesiveGrey, whiteColor } from "variables/colors";
import AccountingBar from "components/Navbars/AccountingBar";
import { albumFilterStates, albumFilterStatesForUsers, getQueryAlbums } from "utils/albums.table.utils";
import { userIsAdmin } from 'utils/users.utils';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import { resourceNotYoursText, tooltipOrderByCreationDate } from "utils/textToShow.utils";

import TypographyWithEndIcon from "components/Typography/TypographyWithEndIcon";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const AlbumsTable = (props) => {

  const { setOpenErrorSearch, setOpenEmptySearch } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const albums = useSelector(store => store.albums.albums);
  const artists = useSelector(store => store.artists.artists);
  const currentUserData = useSelector(store => store.userData);
  const users = useSelector(store => store.users);
  const rol = currentUserData.rol;
  const isAdmin = userIsAdmin(rol);
  const queryStore = useSelector(store => store.query);

  // COSAS DEL BUSCADOR
  const [openNotAdminWarningAlbums, setOpenNotAdminWarningAlbums] = useState(false);
  const [albumsLoading, setAlbumsLoading] = useState(false);
  const [openAlbumActionsDialog, setOpenAlbumActionsDialog] = useState({ open: false, albumId: "" });

  const [emailSearchValue, setEmailSearchValue] = useState("");
  const [upcSearchValue, setUpcSearchValue] = useState("");
  const [artistSearchValue, setArtistSearchValue] = useState("");
  const [albumNameSearchValue, setAlbumNameSearchValue] = useState("");

  let defaultFilterParams = {
    field: "state", values: [],
    groupBy: { id: 'all', name: "Todos los estados" }, orderBy: { field: 'lastUpdateTS', order: 'desc' }
  }

  const [albumsTableIsOpen, setAlbumsTableIsOpen] = useState(true);
  const [pageAlbums, setPageAlbums] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchAlbumsParams, setSearchAlbumsParams] = useState({ field: 'none', value: "" });
  const [filterAlbumsParams, setFilterAlbumsParams] = useState(defaultFilterParams);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [sortedAlbums, setSortedAlbums] = useState(sortAlbumsByField(albums, filterAlbumsParams.orderBy.field));
  const [recentlyOrdered, setRecentlyOrdered] = useState(false);
  const [userSelected, setUserSelected] = useState(false);

  useEffect(() => {
    let groupBy = filterAlbumsParams.groupBy;
    let albumsQueried = getQueryAlbums(albums, searchAlbumsParams, { field: filterAlbumsParams.field, value: groupBy.id });
    let filteredAlbums = filterAlbumsByAdminOrNot(albumsQueried, isAdmin);
    let albumsSorted = sortAlbumsByField(filteredAlbums, filterAlbumsParams.orderBy.field);
    setSortedAlbums(albumsSorted);
    setRecentlyOrdered(true);
  }, [albums, filterAlbumsParams.orderBy])

  // Solo para users
  useEffect(() => {
    if (isAdmin) return;
    let groupBy = filterAlbumsParams.groupBy;
    let albumsQueried = getQueryAlbums(albums, searchAlbumsParams, { field: filterAlbumsParams.field, value: groupBy.id });
    let filteredAlbums = filterAlbumsByAdminOrNot(albumsQueried, isAdmin);
    setSortedAlbums(filteredAlbums);
    setRecentlyOrdered(true);
  }, [filterAlbumsParams.groupBy])

  // Deberia pasar por REDUX y agregarlo al store asi me evito otro useEffect
  const stateAlbumSnap = useFirestoreQuery(getElementsAdminQueryFS("albums", 50, sortedAlbums[0]?.lastUpdateTS || new Date().getTime(),
    isAdmin ? "admin" : currentUserData.id, "lastUpdateTS"));

  useEffect(() => {
    if (stateAlbumSnap.status === "loading") return "Loading...";
    if (stateAlbumSnap.status === "error") return `Error al cargar los ultimos Albums: ${stateAlbumSnap.error.message}`;
    if (stateAlbumSnap.status === "success" && stateAlbumSnap.data.length > 0) dispatch(albumsAddStore(stateAlbumSnap.data));
  }, [stateAlbumSnap])

  const handleCloseUserDialog = () => setUserSelected(false);

  const handleOpenUsuarioDialog = async userId => {
    const userData = await toWithOutError(dispatch(usersGetOneByIdRedux(userId, false)));
    setUserSelected(userData);
  }

  const handleGoToAlbum = albumId => navigate(`/admin/albums/${albumId}`);

  const handleReorderAlbums = orderByField => {
    setFilterAlbumsParams({ ...defaultFilterParams, orderBy: { field: orderByField, order: 'desc' } });
  }

  const fechaCreacionHeader = <TypographyWithEndIcon infoTooltip={tooltipOrderByCreationDate} title="Fecha de Creación"
    sx={{ fontWeight: "500", fontSize: ".875rem" }} containerSize={12} infoSx={{}}
    handleOnClick={() => handleReorderAlbums("whenCreatedTS")} endIcon={<ArrowDownwardIcon />} />

  useEffect(() => {
    if (queryStore.name === 'all' || queryStore.collection !== 'albums') return;
    let albumsCustomQueried = getAlbumsByCustomQuery(albums, queryStore);
    setSortedAlbums(albumsCustomQueried);
    setRecentlyOrdered(true);
    setAlbumsLoading(false);
  }, [queryStore]);

  useEffect(() => {
    if (recentlyOrdered) {
      setRecentlyOrdered(false);
      if (Array.isArray(sortedAlbums)) setRowsToShow(isAdmin
        ? getAlbumsPropsForAdminDataTable(sortedAlbums, handleOpenUsuarioDialog, handleGoToAlbum, setOpenAlbumActionsDialog) || []
        : getAlbumsPropsForUsersDataTable(sortedAlbums, isAdmin, handleGoToAlbum))
    }
  }, [sortedAlbums, recentlyOrdered]);


  const albumsTableHeaders = isAdmin
    ? ["Acciones", "Lanzamiento", "Nombre Lanzamiento", "Artista Principal", "Estado", "Usuario", "UPC", "Formato", fechaCreacionHeader, "Fecha de Lanzamiento"]
    : ["Lanzamiento", "Nombre Lanzamiento", "Artista Principal", "Estado", "UPC", "Formato", "Fecha de Creación", "Fecha de Lanzamiento"];
  const albumsTableWidth = isAdmin
    ? ["5%", "5%", "15%", "10%", "8%", "5%", "10%", "5%", "10%", "10%"]
    : ["4%", "18%", "14%", "18%", "10%", "10%", "13%", "13%"];

  const cleanSearchResults = () => {
    setSortedAlbums(albums);
    setSearchAlbumsParams({ field: 'none', value: '' });
    setFilterAlbumsParams(defaultFilterParams);
    setEmailSearchValue("");
    setUpcSearchValue("");
    setArtistSearchValue("");
    setAlbumNameSearchValue("");
  }

  const onSearchEmailHandler = async email => {
    if (!email) return;
    setAlbumsLoading(true);
    let userFinded = users.find(userFromStore => userFromStore.email === email);
    if (!userFinded) {
      userFinded = await toWithOutError(dispatch(getSearchedUserRedux(email)));
      if (userFinded === "ERROR") { setAlbumsLoading(false); setOpenErrorSearch(true); setSortedAlbums([]); return "ERROR"; }
      if (userFinded === "EMPTY") { setAlbumsLoading(false); setOpenEmptySearch(true); setSortedAlbums([]); return "EMPTY"; }
    }

    setSearchAlbumsParams({ field: 'ownerId', value: userFinded.id });
    let albumsFinded = await toWithOutError(dispatch(getAlbumsByFieldRedux('ownerId', userFinded.id, 500)));
    if (albumsFinded === "ERROR") { setAlbumsLoading(false); setOpenErrorSearch(true); setSortedAlbums([]); return "ERROR"; }
    if (albumsFinded === "EMPTY") { setAlbumsLoading(false); setOpenEmptySearch(true); setSortedAlbums([]); return "EMPTY"; }
    setPageAlbums(0);
    setAlbumsLoading(false);
  }

  const onSearchUPCHandler = async upc => {
    if (!upc) return;
    if (!isAdmin && !albums.map(album => album.upc).includes(upc.toString())) {
      setOpenNotAdminWarningAlbums(true);
      return;
    }

    setAlbumsLoading(true);
    setSearchAlbumsParams({ field: 'upc', value: upc });

    let albumFinded = albums.find(albumFromStore => albumFromStore.upc === upc);
    if (!albumFinded) {
      albumFinded = await toWithOutError(dispatch(getAlbumsByFieldRedux('upc', upc, 2)));
      if (albumFinded === "ERROR") { setAlbumsLoading(false); setOpenErrorSearch(true); setSortedAlbums([]); return "ERROR"; }
      if (albumFinded === "EMPTY") { setAlbumsLoading(false); setOpenEmptySearch(true); setSortedAlbums([]); return "EMPTY"; }
    }
    else {
      albumFinded = Array.isArray(albumFinded) ? albumFinded[0] : albumFinded;
      dispatch(albumsAddStore([albumFinded]))
    }
    setPageAlbums(0);
    setAlbumsLoading(false);
  }

  const onSearchArtistNameHandler = async artistName => {
    if (!artistName) return;
    if (!isAdmin && !artists.map(artist => artist.name).includes(artistName.trim())) {
      setOpenNotAdminWarningAlbums(true);
      return;
    }

    setAlbumsLoading(true);
    setSearchAlbumsParams({ field: 'nombreArtist', value: artistName.trim() });
    let albumsFinded = await toWithOutError(dispatch(getAlbumsByFieldRedux('nombreArtist', artistName.trim(), 300)));
    if (albumsFinded === "ERROR") { setAlbumsLoading(false); setOpenErrorSearch(true); setSortedAlbums([]); return "ERROR"; }
    if (albumsFinded === "EMPTY") { setAlbumsLoading(false); setOpenEmptySearch(true); setSortedAlbums([]); return "EMPTY"; }
    setPageAlbums(0);
    setAlbumsLoading(false);
  }

  const onSearchAlbumNameHandler = async releaseName => {
    if (!releaseName) return;
    if (!isAdmin && !albums.map(album => album.title).includes(releaseName.trim())) {
      setOpenNotAdminWarningAlbums(true);
      return;
    }

    setAlbumsLoading(true);
    setSearchAlbumsParams({ field: 'title', value: releaseName.trim() });
    let albumsFinded = await toWithOutError(dispatch(getAlbumsByFieldRedux('title', releaseName.trim(), 20)));
    if (albumsFinded === "ERROR") { setAlbumsLoading(false); setOpenErrorSearch(true); setSortedAlbums([]); return "ERROR"; }
    if (albumsFinded === "EMPTY") { setAlbumsLoading(false); setOpenEmptySearch(true); setSortedAlbums([]); return "EMPTY"; }
    setPageAlbums(0);
    setAlbumsLoading(false);
  }

  const handleEnterKeyPress = (event, searchProps) => {
    if (event.key === 'Enter') {
      if (searchProps.name === "Email") onSearchEmailHandler(searchProps.value);
      if (searchProps.name === "UPC") onSearchUPCHandler(searchProps.value);
      if (searchProps.name === "Artista") onSearchArtistNameHandler(searchProps.value);
      if (searchProps.name === "Nombre Lanzamiento") onSearchAlbumNameHandler(searchProps.value);
    }
  }

  const emailSearchProps = { name: "Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler, value: emailSearchValue.trim().toLowerCase(), setValue: setEmailSearchValue };
  const upcSearchProps = { name: "UPC", handleEnterKeyPress, onSearchHandler: onSearchUPCHandler, value: upcSearchValue.trim(), setValue: setUpcSearchValue };
  const artistSearchProps = { name: "Artista", handleEnterKeyPress, onSearchHandler: onSearchArtistNameHandler, value: artistSearchValue, setValue: setArtistSearchValue }
  const albumNameSearchProps = { name: "Nombre Lanzamiento", handleEnterKeyPress, onSearchHandler: onSearchAlbumNameHandler, value: albumNameSearchValue, setValue: setAlbumNameSearchValue };

  const allSearchers = isAdmin
    ? [emailSearchProps, upcSearchProps, albumNameSearchProps, artistSearchProps]
    : [upcSearchProps, albumNameSearchProps, artistSearchProps];

  let appBarSx = { borderRadius: '0em', backgroundColor: whiteColor };

  // TODO Aca hay que cambiarlo luego, usar Redux Querys etc etc
  const handleChangePage = (event, newPage) => {
    setPageAlbums(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  let albumsTableParams = {
    headers: albumsTableHeaders, columnsWidth: albumsTableWidth, loading: albumsLoading,
    totalCount: albums.length > 5 ? albums.length : 0, handleChangePage, page: pageAlbums,
    headerColor: cohesiveGrey, handleChangeRowsPerPage, rowsPerPage,
    headersHeight: 65, maxLengthChars: 25, maxWidthText: 200, rowsAlignHeaders: 'center',
    rowsAlignCells: 'center', rowsHeight: 30, needPaginationInTable: true
  };

  const handleChangeGroupBy = async groupByName => {
    setAlbumsLoading(true);
    setFilterAlbumsParams({
      ...filterAlbumsParams,
      groupBy: { id: stateNameToStateId(groupByName), name: groupByName || "Todos los estados" }
    });
    let fields = stateNameToStateId(groupByName) !== 'all' ? ['state'] : [];
    let values = stateNameToStateId(groupByName) !== 'all' ? [stateNameToStateId(groupByName)] : [];
    if (searchAlbumsParams.field !== 'none') { fields.push(searchAlbumsParams.field); values.push(searchAlbumsParams.value) };

    let albumsFinded = await toWithOutError(dispatch(getAlbumsByFieldsRedux(fields, values, rowsPerPage)));
    if (albumsFinded === "ERROR") { setAlbumsLoading(false); setOpenErrorSearch(true); setSortedAlbums([]); return "ERROR"; }
    if (albumsFinded === "EMPTY") { setAlbumsLoading(false); setOpenEmptySearch(true); setSortedAlbums([]); return "EMPTY"; }
    setAlbumsLoading(false);
  }

  const handleUserChangeGroupBy = async groupByName => {
    setFilterAlbumsParams({
      ...filterAlbumsParams,
      groupBy: { id: stateNameToStateId(groupByName), name: groupByName || "Todos los estados" }
    });
  }

  const groupByProps = [{
    helper: "Agrupar según", values: isAdmin ? albumFilterStates : albumFilterStatesForUsers,
    handleChangeGroupBy: isAdmin ? handleChangeGroupBy : handleUserChangeGroupBy, value: filterAlbumsParams.groupBy
  }];

  return (
    <Grid item xs={12} sx={{ textAlign: "center" }}>

      <InfoActionDialog id='warning-admin' isOpen={openNotAdminWarningAlbums} handleClose={() => setOpenNotAdminWarningAlbums(false)}
        title={"Necesitas permisos de Administrador"} contentTexts={resourceNotYoursText} />

      {openAlbumActionsDialog.open && <AlbumActionsDialog isOpen={openAlbumActionsDialog.open} handleClose={() => setOpenAlbumActionsDialog({ open: false, albumId: "" })}
        albumId={openAlbumActionsDialog.albumId} caller={'albums-table'} />}

      {userSelected && <UserDialog userData={userSelected} isOpen={Boolean(userSelected.id)} title={`${getEmailIfNotHaveUser(userSelected)}`}
        handleClose={handleCloseUserDialog} contentTexts={["Proximamente datos del usuario"]} rolAdmin={rol} />}

      <Grid item xs={12} padding={0} >
        <AccountingBar searchArrayProps={allSearchers} cleanSearchResults={cleanSearchResults}
          appBarSx={appBarSx} appBarTitle='Lanzamientos' mainSearchColor={cohesiveGrey} isOpen={albumsTableIsOpen}
          handleCollapseTable={() => setAlbumsTableIsOpen(!albumsTableIsOpen)} groupByArray={groupByProps} />
      </Grid>

      {albumsTableIsOpen && <Grid item xs={12} sx={{ margin: 'auto' }}>
        <CustomizedTable rows={rowsToShow} {...albumsTableParams} />
      </Grid>}
    </Grid>
  )
}

export default AlbumsTable;