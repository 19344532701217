import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import CheckboxWithInfo from "components/Checkbox/CheckboxWithInfo";
import TextFieldWithInfo from "components/TextField/TextFieldWithInfo";
import { cohesiveGrey, fugaGreen } from "variables/colors";

const AccountDataForCripto = ({ formData, setFormData, validator }) => {
  const { cryptoType, address, network } = formData;

  const handleCheckboxChange = (type) => {
    setFormData({ target: { name: "cryptoType", value: type } });
  };

  return (
    <Grid container spacing={2}>

      {/* Selección de Criptomoneda */}
      <Grid item xs={12} textAlign="center">
        <Typography sx={subSubtitlesStyles}>Selecciona la criptomoneda</Typography>
      </Grid>

      <Grid item xs={12} container justifyContent="center" spacing={2}>
        <Grid item>
          <CheckboxWithInfo
            checked={cryptoType === "USDT"}
            onChecked={() => handleCheckboxChange("USDT")}
            label="USDT (Tether)"
            color={fugaGreen}
            checkBoxHelper="Stablecoin anclada al USD. Disponible en múltiples redes."
          />
        </Grid>

        <Grid item>
          <CheckboxWithInfo
            checked={cryptoType === "USDC"}
            onChecked={() => handleCheckboxChange("USDC")}
            label="USDC (USD Coin)"
            color={fugaGreen}
            checkBoxHelper="Stablecoin regulada con respaldo en USD."
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderWidth: "2px" }} />
      </Grid>

      {/* Dirección de billetera */}
      <Grid item xs={12}>
        <TextFieldWithInfo
          fullWidth
          required
          name="address"
          label="Dirección de Billetera"
          value={address}
          onChange={setFormData}
          helperTextDown="Asegúrate de ingresar la dirección correcta. Las transacciones en blockchain son irreversibles."
          validatorProps={{
            restrictions: 'required',
            message: "Debés ingresar la dirección de tu cuenta.",
            validator,
          }}
        />
      </Grid>

      {/* Campo para la Red */}
      <Grid item xs={12}>
        <TextFieldWithInfo
          fullWidth
          required
          name="network"
          label="Red de la Criptomoneda"
          value={network}
          onChange={setFormData}
          helperTextDown="Ejemplo: BSC BNB Smart Chain (BEP 20). Ingresa la red correcta para evitar pérdidas de fondos."
          validatorProps={{
            restrictions: 'required',
            message: "Debés ingresar una Red.",
            validator,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AccountDataForCripto;

const subSubtitlesStyles = { fontSize: "25px", fontWeight: "", color: cohesiveGrey };
