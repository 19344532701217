import { SUB_MP, SUB_ORDER_CANCELLED_BY_USER, SUB_ORDER_NEED_CHECK, SUB_ORDER_NEED_PROOF, SUB_ORDER_NEED_REFUND, SUB_ORDER_PAYED, SUB_ORDER_PENDING_PAY, SUB_ORDER_PLAN_SELECTED, SUB_ORDER_REFUNDED, SUB_ORDER_REJECTED, SUB_ORDER_REJECTED_PROOF, planesLaFlota, subPaymentMethodToString } from '../variables/financial';
import {
  USER_ACTIVE, userStatesShowAdmin, userActiveStates, USER_PENDING_PROOF_CHECK,
  USER_SUSPEND_INFRACTION, userStatesListShowAdmin, USER_CANCELLED, userStateColors,
  userNotActiveStates, USER_INACTIVE, USER_PENDING_PAY, USER_PENDING_PROOF, USER_NEED_RENOVATION,
  USER_NEED_VERIFICATION_EMAIL, USER_NEED_REFUND, userPendingPayStates, USER_REJECTED_PROOF, USER_NEED_MIGRATION, userStatusNotNeedAttention, userStatesShowUser, USER_SUSPEND_NO_PAY, USER_VIP
} from 'variables/user.variables';
import { iconOpenActions } from './tables.utils';
import { Typography, Button } from '@mui/material';
import { differenceInDaysFromTwoTS, getDateWithHoursFromTs, getNextYearDate, getNextYearDateFromTsToTs, getTextDate } from './timeRelated.utils';
import {
  cancelledSubscriptionText, inactiveSubscriptionText, needMigrationText, needRenovationSubscriptionText,
  needVerificationEmailText,
  pendingPaySubscriptionText, pendingProofCheckSubscriptionText, pendingProofSubscriptionText,
  suspendInfractionSubscriptionText, suspendNoPaySubscriptionText, warningProofRejectedText
} from './textToShow.utils';
import { getCantDaysInMS } from 'utils/timeRelated.utils';
import { truncateFloat } from 'utils';
import { mapCustomQueryNameToStatuses } from './users.queries.utils';

const defaultSubInfo = { lastPaymentDate: "Sin Info", paymentMethod: "Sin Info", currency: "ARS", orderTotal: 0, netOrderTotal: 0 };

export const getEmailIfNotHaveUser = userData => userData.nombre ? `${userData.nombre} ${userData.apellido}` : `${userData.email}`;

export const userIsAdmin = rol => rol.indexOf('admin') >= 0;
export const userIsRRSS = rol => rol.indexOf('rrss') >= 0;
export const userIsTester = rol => rol.indexOf('tester') >= 0;
export const userIsRoot = rol => userIsAdmin(rol) && !userIsRRSS(rol);

export const getUserById = (users, userId) => {
  let userFounded = users.find(u => u.id === userId);
  if (!userFounded) return {};
  else return userFounded;
}

export const getPlanNameFromId = planId => {
  return planesLaFlota.find(plan => plan.id === planId)?.name || "Charly García - 1"
}

export const getPlanFromId = planId => {
  return planesLaFlota.find(plan => plan.id === planId) || {}
}

export const getUserStatusToShow = (sx, userStatusCode, setOpenUserPayProof, userId) => {
  if (userStatusCode === USER_PENDING_PROOF_CHECK) {
    return <Button sx={{ color: sx.color, textTransform: 'none', fontSize: '1.1rem' }} onClick={() => setOpenUserPayProof({ open: true, userId })}>
      {userStatesShowAdmin[userStatusCode]}
    </Button>
  }
  return <Typography sx={sx} >{userStatesShowAdmin[userStatusCode] || "SIN ESTADO"}</Typography>
}

export const getPlanMaxArtistsFromId = planId => {
  return planesLaFlota.find(plan => plan.id === planId)?.maxArtists || 1
}

export const getPlanIdFromName = planName => {
  return planesLaFlota.find(plan => plan.name === planName)?.id || "charly-garcia"
}

export const getPlanObjectFromName = planName => {
  return planesLaFlota.find(plan => plan.name === planName) || {};
}

export const getPlanIdFromMaxArtists = maxArtistsPlan => {
  return planesLaFlota.find(plan => plan.maxArtists === Number(maxArtistsPlan))?.id || {};
}

export const getSubscriptionStatusFromIdToUser = subId => {
  return userStatesShowUser[subId] || "SIN ESTADO";
}

export const getSubscriptionStatusFromId = subId => {
  return userStatesListShowAdmin.find(sub => sub.id === subId)?.name || "Activo"
}

export const getSubscriptionStatusIdFromName = subName => {
  return userStatesListShowAdmin.find(sub => sub.name === subName)?.id || USER_ACTIVE
}

export const userStatusHasPendingPay = userStatus => userPendingPayStates.includes(userStatus);

export const userNotNeedAttention = user => {
  if (userIsAdmin(user.rol)) return true;
  const nextPaymentDateTS = user?.subscription?.nextPaymentDate || "";
  const daysToRenew = differenceInDaysFromTwoTS(nextPaymentDateTS, new Date().getTime());
  if (daysToRenew !== "ERROR" && daysToRenew <= 30) return false;
  return userStatusNotNeedAttention.includes(user.userStatus);
}

export const userIsActive = userStatus => userActiveStates.includes(userStatus);
export const userNeedEmailVerification = userStatus => userStatus === USER_NEED_VERIFICATION_EMAIL;
export const userIsInactive = userStatus => userNotActiveStates.includes(userStatus);

const redTextSub = { color: "red", fontSize: "18px", fontWeight: 400, margin: 0 };
const yellowTextSub = { color: "rgb(141, 109, 11)", fontSize: "18px", fontWeight: 400, margin: 0 };

const warningInactiveJsx = <p style={redTextSub}>{inactiveSubscriptionText}</p>
const inactiveDataToShow = { subtitle: warningInactiveJsx, contact: true }

const warningPendingPay = <p style={yellowTextSub}>{pendingPaySubscriptionText}</p>
const pendingPayDataToShow = { subtitle: warningPendingPay, contact: true }

const warningProofRejected = <p style={yellowTextSub}>{warningProofRejectedText}</p>
const warningProofRejectedToShow = { subtitle: warningProofRejected, contact: true }

const warningPendingProof = <p style={yellowTextSub}>{pendingProofSubscriptionText}</p>
const pendingProofDataToShow = { subtitle: warningPendingProof, contact: true }

const warningPendingProofCheck = <p style={yellowTextSub}>{pendingProofCheckSubscriptionText}</p>
const pendingProofCheckDataToShow = { subtitle: warningPendingProofCheck, contact: true }

const warningCancelledJsx = <p style={redTextSub}>{cancelledSubscriptionText}</p>
const cancelledDataToShow = { subtitle: warningCancelledJsx, contact: true }

const warningNeedRenovationJsx = nextPaymentDate => <>
  <b style={{ fontWeight: 500, fontSize: '18px' }}>{`Fecha de Renovación: ${nextPaymentDate}`}</b>
  <p style={redTextSub}>{needRenovationSubscriptionText}</p></>
const needRenotavionDataToShow = nextPaymentDate => { return { subtitle: warningNeedRenovationJsx(nextPaymentDate), contact: true } }

const warningNeedVerificationJsx = <p style={redTextSub}>{needVerificationEmailText}</p>;
const needVerificationDataToShow = { subtitle: warningNeedVerificationJsx, contact: true }

const warningInfractionJsx = <p style={redTextSub}>{suspendInfractionSubscriptionText}</p>
const suspendInfractionDataToShow = { subtitle: warningInfractionJsx, contact: true }

const warningSuspendNoPayJsx = <p style={redTextSub}>{suspendNoPaySubscriptionText}</p>
const suspendNoPayDataToShow = { subtitle: warningSuspendNoPayJsx, contact: true }

const needMigrationJsx = <p style={yellowTextSub}>{needMigrationText}</p>
const needMigrationDataToShow = { subtitle: needMigrationJsx, contact: true }

export const renewIsNearToExpire = (userStatus, nextPaymentDateTS) => {
  return (userStatus === USER_ACTIVE) && ((nextPaymentDateTS - getCantDaysInMS(30)) < new Date().getTime());
}

export const renewIsExpiredInTerm = (userStatus, nextPaymentDateTS) => {
  return (userStatus === USER_NEED_RENOVATION) && (nextPaymentDateTS < (new Date().getTime() + getCantDaysInMS(5)));
}

export const getUserSubscriptionDataToShow = (userStatus, nextPaymentDateTS) => {
  const nextPaymentDate = userIsActive(userStatus) ? getTextDate(nextPaymentDateTS) : "";
  const userActiveJsx = nextPaymentDate ? <b style={{ fontWeight: 500, fontSize: '18px' }}>{`Fecha de Renovación: ${nextPaymentDate}`}</b> : null;
  let dataToShow = { statusToShow: getSubscriptionStatusFromIdToUser(userStatus), subtitle: userActiveJsx, contact: false }
  if (userStatus === USER_NEED_MIGRATION) return { ...dataToShow, ...needMigrationDataToShow };
  if (userStatus === USER_ACTIVE) return dataToShow;
  if (userStatus === USER_INACTIVE) return { ...dataToShow, ...inactiveDataToShow };
  if (userStatus === USER_PENDING_PAY) return { ...dataToShow, ...pendingPayDataToShow };
  if (userStatus === USER_PENDING_PROOF) return { ...dataToShow, ...pendingProofDataToShow };
  if (userStatus === USER_PENDING_PROOF_CHECK) return { ...dataToShow, ...pendingProofCheckDataToShow };
  if (userStatus === USER_REJECTED_PROOF) return { ...dataToShow, ...warningProofRejectedToShow };
  if (userStatus === USER_CANCELLED) return { ...dataToShow, ...cancelledDataToShow };
  if (userStatus === USER_NEED_RENOVATION) return { ...dataToShow, ...needRenotavionDataToShow(nextPaymentDate) };
  if (userStatus === USER_SUSPEND_INFRACTION) return { ...dataToShow, ...suspendInfractionDataToShow };
  if (userStatus === USER_SUSPEND_NO_PAY) return { ...dataToShow, ...suspendNoPayDataToShow };
  if (userStatus === USER_NEED_VERIFICATION_EMAIL) return { ...dataToShow, ...needVerificationDataToShow }
  return dataToShow;
}

export const getUserStatusColor = userStatus => {
  return userStateColors[userStatus];
}

export const getCuponAmountUsed = (cuponInfo, currencyPay) => {
  if (!cuponInfo) return 0;
  if (currencyPay === "USD") return cuponInfo.amountUsd;
  return cuponInfo.amountArs;
}

export const getUserRenovationToShow = subscriptionInfo => {
  if (subscriptionInfo.nextPaymentDate === 'no-pay') return "Sin Suscripción";
  const daysToRenew = differenceInDaysFromTwoTS(subscriptionInfo.nextPaymentDate, new Date().getTime());
  if (daysToRenew >= 0 && daysToRenew < 30) {
    let daysTextRenew = daysToRenew === 0 ? "Hoy" : `en ${daysToRenew} ${daysToRenew === 1 ? " día" : " días"}`;
    return <Typography sx={{ color: 'darkorange', fontWeight: 500 }} >{`Renovación: ${daysTextRenew}`}</Typography>
  }
  if (daysToRenew < 0 && daysToRenew > -300) {
    let daysTextExp = daysToRenew === 0 ? "Hoy" : `por ${-1 * (daysToRenew)} ${daysToRenew === -1 ? " día" : " días"}`;
    return <Typography sx={{ color: 'red', fontWeight: 500 }} >{`Cuenta exp: ${daysTextExp}`}</Typography>
  }
  return getDateWithHoursFromTs(subscriptionInfo.nextPaymentDate)
}

export const getUsersPropsForDataTable = (users, setOpenUserActionsDialog, setOpenUserPayProof) => {
  let usersPropsToTable = [];
  users.forEach(user => {
    const subscriptionInfo = user.subscription || defaultSubInfo;
    const noNeedToPay = false;
    const stateInfoStyle = { color: getUserStatusColor(user.userStatus), fontSize: "1em", fontWeight: 600 };
    usersPropsToTable.push([
      iconOpenActions(user.id, 'userId', setOpenUserActionsDialog),
      user.email,
      getDateWithHoursFromTs(user.timestampWhenCreatedUserInFB),
      getPlanMaxArtistsFromId(user.plan),
      getUserStatusToShow(stateInfoStyle, user.userStatus, setOpenUserPayProof, user.id),
      getDateWithHoursFromTs(subscriptionInfo.lastPaymentDate),
      getUserRenovationToShow(subscriptionInfo),
      subPaymentMethodToString(subscriptionInfo.paymentMethod, noNeedToPay),
      subscriptionInfo.payId,
      truncateFloat(getCuponAmountUsed(subscriptionInfo.cupon, subscriptionInfo.currency), 2, '.'),
      <b sx={{ fontWeight: 500 }}>{Number.isFinite(subscriptionInfo.orderTotal) ? truncateFloat(subscriptionInfo.orderTotal, 2, '.') : "-"}</b>,
      <b sx={{ fontWeight: 500 }}>{Number.isFinite(subscriptionInfo.netOrderTotal) ? truncateFloat(subscriptionInfo.netOrderTotal, 2, '.') : "-"}</b>,
      <b sx={{ fontWeight: 500 }}>{(["ARS", "USD"].includes(subscriptionInfo.currency)) ? subscriptionInfo.currency : "-"}</b>,
    ]);
  });
  return usersPropsToTable;
}

export const sortUsersByFieldOrQuery = (users, field, query) => {
  if (query.name === 'next-to-expire') return sortUsersBySubField(users, "nextPaymentDate", 'asc');
  else return sortUsersByField(users, field, 'desc');
}

export const sortUsersBySubField = (users, field, order) => {
  let orderFactor = 1;
  if (order === 'asc') orderFactor = -1;
  return users.sort((uA, uB) => {
    if (!uA['subscription']) return (orderFactor * 1);
    if (!uB['subscription']) return (orderFactor * -1);
    if (!uA['subscription'][field]) return (orderFactor * 1);
    if (!uB['subscription'][field]) return (orderFactor * -1);
    if (uA['subscription'][field] > uB['subscription'][field]) return (orderFactor * -1);
    else return (orderFactor * 1)
  });
}

export const sortUsersByField = (users, field, order) => {
  let orderFactor = 1;
  if (order === 'asc') orderFactor = -1;
  return users.sort((uA, uB) => {
    if (!uA[field]) return (orderFactor * 1);
    if (!uB[field]) return (orderFactor * -1);
    if (uA[field] > uB[field]) return (orderFactor * -1);
    else return (orderFactor * 1)
  });
}

export const getUsersByQuery = (users, searchParams, filterParams) => {
  let usersResult = users;
  usersResult = usersResult.filter(user => user.email !== "");

  if (searchParams.field !== 'none')
    usersResult = usersResult.filter(user => searchParams.values.includes(user[`${searchParams.field}`]));

  if (filterParams.value !== 'all')
    usersResult = usersResult.filter(user => user[`${filterParams.field}`] === filterParams.value);

  return usersResult;
}

export const getUsersByCustomQuery = (users, query) => {
  let nextXDays = new Date().getTime() + getCantDaysInMS(15);
  if (query.name.includes('byPlan-')) {
    let keyName = query.name.split('-')[1];
    if (keyName === 'shareDeal') return users.filter(user => user.shareDeal && user.shareDeal !== 'no-deal');
    else return users.filter(user => user.plan === getPlanIdFromMaxArtists(keyName) && user.userStatus === USER_ACTIVE);
  }
  if (query.name === 'all') return users;
  let usersResult = users;
  usersResult = usersResult.filter(user => user.email !== "");
  if (query.name === 'next-to-expire') {
    usersResult = usersResult.filter(user => user.userStatus === USER_ACTIVE
      && user.subscription && user.subscription.nextPaymentDate <= nextXDays);
  }
  else {
    let statusToGet = mapCustomQueryNameToStatuses(query.name);
    usersResult = usersResult.filter(user => statusToGet.includes(user.userStatus));
  }
  return usersResult;
}

export const userCanMakeRelease = (userStatus, userId) => {
  return userStatus === USER_ACTIVE || userStatus === USER_VIP;
}

export const userCanEditRelease = (userStatus, userId) => {
  return userStatus === USER_ACTIVE || userStatus === USER_VIP;
}

export const userCanEditArtist = (userStatus, userId) => {
  return userStatus === USER_ACTIVE || userStatus === USER_VIP;
}

export const getNextRenewDate = (paymentIsCompleted, userSub, renovating, upgrading) => {
  if (renovating) return getTextDate(getNextYearDateFromTsToTs(userSub.nextPaymentDate));
  if (paymentIsCompleted || upgrading) return getTextDate(userSub.nextPaymentDate);
  return getNextYearDate();
}

export const isValidEmail = email => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const userCouldHadPayProof = user => {
  if (user.userStatus === USER_PENDING_PROOF_CHECK || user.userStatus === USER_NEED_REFUND) return true;
  if (user.userStatus === USER_ACTIVE && user?.subscription?.paymentMethod !== SUB_MP) return true;
  return false;
}

export const getUserTsPaymentData = (user, isRenew) => {
  let whenCreatedUser = user.timestampWhenCreatedUserInFB ? user.timestampWhenCreatedUserInFB : new Date().getTime();
  let nextYear = getNextYearDateFromTsToTs(whenCreatedUser);
  if (!isRenew) return { lastPaymentDate: whenCreatedUser, nextPaymentDate: nextYear, firstPaymentDate: whenCreatedUser };
  return { firstPaymentDate: whenCreatedUser };
}

export const userStatusNameToStatusId = userStatusName => {
  return userStatesListShowAdmin.find(statusInfo => statusInfo.name === userStatusName)?.id || "all";
}

export const getUserStatusFromPaymentState = payment => {
  if (payment.status === SUB_ORDER_PAYED) return USER_ACTIVE;
  if ([SUB_ORDER_PENDING_PAY, SUB_ORDER_PLAN_SELECTED].includes(payment.status)) return USER_PENDING_PAY;
  if ([SUB_ORDER_NEED_PROOF, SUB_ORDER_REJECTED_PROOF].includes(payment.status)) return USER_PENDING_PROOF;
  if (payment.status === SUB_ORDER_NEED_CHECK) return USER_PENDING_PROOF_CHECK;
  if ([SUB_ORDER_CANCELLED_BY_USER, SUB_ORDER_REFUNDED, SUB_ORDER_REJECTED].includes(payment.status)) return USER_CANCELLED;
  if (payment.status === SUB_ORDER_NEED_REFUND) return USER_NEED_REFUND;
  if (payment.status === SUB_ORDER_NEED_REFUND) return USER_NEED_REFUND;
  return USER_INACTIVE;
}

export const generateStrongPassword = length => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#&:;";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
}