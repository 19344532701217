import * as React from 'react';
import { Grid, Tooltip, IconButton, FormControlLabel } from '@mui/material';
import { Info } from '@mui/icons-material';
import BasicCheckbox from 'components/Checkbox/BasicCheckbox';

const CheckboxWithInfo = ({ checked, label, onChecked, color, checkBoxHelper, onClickInfo, disabled }) => {

  return (
    <Grid item xs>
      <FormControlLabel
        control={
          <BasicCheckbox key={label}
            label={label}
            onChecked={onChecked}
            checked={checked}
            color={color}
            disabled={disabled ? disabled : false} />}
        label={
          checkBoxHelper ? <span>
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{checkBoxHelper}</span>}>
              <IconButton sx={{ ml: 0 }} onClick={onClickInfo ? onClickInfo : null} >{<Info />}</IconButton>
            </Tooltip>
          </span>
            : null
        }
      />
    </Grid>
  );
}

export default CheckboxWithInfo;
