import React, { useEffect, useState } from "react";
import { Grid, Typography, InputAdornment, IconButton } from "@mui/material";
import TextFieldWithInfo from "components/TextField/TextFieldWithInfo";
import TypographyWithInfo from "components/Typography/TypographyWithInfo";
import { formatAllNumber } from "utils";
import { getUserAvailableMoney } from "services/BackendCommunication";
import { useDispatch } from "react-redux";
import { getPlanesLaFlota, getUserDataByEmailInFS } from "services/FirestoreServices";
import RefreshIcon from '@mui/icons-material/Refresh';
import { helperTextAvailable, transferTotalIsValid } from 'utils/payouts.utils';

const UserFinancialData = (financialParams) => {

  const {
    userData, isAdmin, usdToArsRate, userToAttachPayout, setUserToAttachPayout, setForm,
    transferTotalUsd, medioDePago, setMedioDePago, setAutoRenewPayout, totalRoyaltiesAndPayed,
    setTotalRoyaltiesAndPayed, validator, autoRenewPayout, planPriceInArs, setPlanPriceInArs,
    usdTransferFormData
  } = financialParams;

  const dispatch = useDispatch();
  const minimumPlanArs = 36500;

  const [refreshData, setRefreshData] = useState('not-clicked');
  const [emailTarget, setEmailTarget] = useState("");
  const [laFlotaPlanes, setLaFlotaPlanes] = useState("");

  // Planes
  useEffect(() => {
    const getLaFlotaPlanes = async () => {
      const planesLaFlota = await getPlanesLaFlota(dispatch);
      setLaFlotaPlanes(planesLaFlota.filter(plan => plan.status === 'available'));
    }
    getLaFlotaPlanes();
  }, []);

  // Planes en ARS
  useEffect(() => {
    if (!isAdmin && Array.isArray(laFlotaPlanes)) {
      const userPlanData = laFlotaPlanes.find(plan => plan.id === userData.plan);
      setPlanPriceInArs(userPlanData?.priceArs || minimumPlanArs);
    }
  }, [laFlotaPlanes]);

  // Solo ADMIN: datos del usuario a realizarle el pedido de retiro
  useEffect(() => {
    const getTargetUserByEmail = async () => {
      const userTargetData = await getUserDataByEmailInFS(emailTarget, dispatch);
      if (["ERROR", "EMPTY"].includes(userTargetData)) return "ERROR";
      setUserToAttachPayout(userTargetData);
      setMedioDePago({ name: "ARS", currency: "ars", account: 'cupon' });
      setAutoRenewPayout({
        ...autoRenewPayout, ownerEmail: userTargetData.email, userLastName: userTargetData.apellido,
        ownerId: userTargetData.id, userName: userTargetData.nombre
      });
    }
    if (isAdmin && emailTarget) getTargetUserByEmail();
  }, [refreshData]);

  // Solo ADMIN: User financial data del usuario a generarle el retiro
  useEffect(() => {
    const getTargetUserFinancial = async () => {
      const { payed, royalties, available, lastRequest, taxesUsd } = await getUserAvailableMoney(userToAttachPayout, dispatch);
      setTotalRoyaltiesAndPayed({ payed, royalties, loading: false, available, lastRequest, taxesUsd });
      const userPlanData = laFlotaPlanes.find(plan => plan.id === userToAttachPayout.plan) || { priceArs: minimumPlanArs };
      const planInUsdForCupon = Math.ceil((userPlanData.priceArs / usdToArsRate) * 100) / 100;
      const planInArs = Math.ceil((planInUsdForCupon * usdToArsRate) * 100) / 100;
      setPlanPriceInArs(planInArs);
      setForm({ target: { name: 'transferTotalUsd', value: planInUsdForCupon } });
    }
    if (isAdmin && usdToArsRate > 0 && userToAttachPayout.id && laFlotaPlanes) getTargetUserFinancial();
  }, [userToAttachPayout, usdToArsRate]);

  // User financial data
  useEffect(() => {
    const getUserFinancialInfo = async () => {
      const { payed, royalties, available, lastRequest, taxesUsd } = await getUserAvailableMoney(userData, dispatch);
      setTotalRoyaltiesAndPayed({ payed, royalties, loading: false, available, lastRequest, taxesUsd });
      setForm({ target: { name: 'transferTotalUsd', value: available } });
    }
    if (!isAdmin && userData.id) getUserFinancialInfo();
  }, [usdToArsRate]);

  const hasNoTaxes = totalRoyaltiesAndPayed.taxesUsd === 0;

  const changeOwnerEmail = event => setEmailTarget(event.target.value);
  const handleRefresh = () => setRefreshData('clicked');

  const handleUsdToWithdraw = event => {
    const regex = /^\d*([.,]\d*)?$/;
    const onlyNums = event.target.value;
    if (regex.test(onlyNums)) {
      setForm({ target: { name: 'transferTotalUsd', value: onlyNums.replace(',', '.') } });
      if (isAdmin) setAutoRenewPayout({ ...autoRenewPayout, transferTotalUsd: onlyNums.replace(',', '.') })
    }
  }

  return (
    <Grid container spacing={2} textAlign="center">

      {isAdmin && <Grid container item xs={12} sx={{ justifyContent: "center", alignItems: "center" }} spacing={1}>
        <Grid item xs={6} textAlign="center">
          <TextFieldWithInfo
            name="email"
            required
            fullWidth
            sx={{ width: '100%' }}
            label="Email del dueño del Retiro"
            value={emailTarget}
            onChange={changeOwnerEmail}
            validatorProps={{ restrictions: 'email|max:100', message: "Debes ingresar un email.", validator: validator }}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleRefresh}>
            <RefreshIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Grid>
      </Grid>}

      {/* Última Solicitud */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "22px", fontWeight: 500 }}>
          {`Última Solicitud: ${totalRoyaltiesAndPayed.loading
            ? "..."
            : totalRoyaltiesAndPayed.lastRequest || "No tienes solicitudes."}`}
        </Typography>
      </Grid>

      {/* Regalías Totales */}
      <Grid item xs={4}>
        <Typography sx={{ fontSize: "18px", fontWeight: 400 }}>
          {`Regalías Totales: ${totalRoyaltiesAndPayed.loading
            ? "... USD"
            : formatAllNumber(totalRoyaltiesAndPayed.royalties, 2, ".") + " USD"}`}
        </Typography>
      </Grid>

      {/* Regalías Ya Solicitadas */}
      <Grid item xs={4}>
        <Typography sx={{ fontSize: "18px", fontWeight: 400 }}>
          {`Regalías ya Solicitadas: ${totalRoyaltiesAndPayed.loading
            ? "... USD"
            : formatAllNumber(totalRoyaltiesAndPayed.payed, 2, ".") + " USD"}`}
        </Typography>
      </Grid>

      {/* Impuestos (Si aplica) */}
      {!hasNoTaxes && (
        <Grid item xs={4}>
          <TypographyWithInfo
            title={`Impuestos: ${formatAllNumber(totalRoyaltiesAndPayed.taxesUsd, 2, ".")} USD`}
            sx={{ fontSize: "18px", fontWeight: 400 }}
            infoTooltip="Haz click para ver más información."
          />
        </Grid>
      )}

      {/* Disponible a Retirar */}
      <Grid item xs={hasNoTaxes ? 4 : 12}>
        {hasNoTaxes ? (
          <Typography sx={{ fontSize: "18px", fontWeight: 400 }}>
            {`Disponible a Retirar: ${formatAllNumber(totalRoyaltiesAndPayed.available, 2, ".")} USD`}
          </Typography>
        ) : (
          <TypographyWithInfo
            title={`Disponible a Retirar: ${formatAllNumber(totalRoyaltiesAndPayed.available, 2, ".")} USD`}
            sx={{ fontSize: "18px", fontWeight: 400 }}
            infoTooltip="Haz click para ver más información."
          />
        )}
      </Grid>

      {/* Campo de Ingreso de Retiro */}
      <Grid item xs={12}>
        <TextFieldWithInfo
          name="transferTotalUsd"
          required
          sx={{ width: "300px" }}
          disabled={totalRoyaltiesAndPayed.available < 10}
          error={!transferTotalIsValid(transferTotalUsd, totalRoyaltiesAndPayed.available, usdTransferFormData.transferType, usdToArsRate, planPriceInArs, medioDePago)}
          label="Dólares a Retirar"
          helperTextDown={helperTextAvailable(transferTotalUsd, totalRoyaltiesAndPayed.available, usdTransferFormData.transferType, usdToArsRate, planPriceInArs, medioDePago)}
          value={transferTotalUsd}
          onChange={handleUsdToWithdraw}
          startAdormentObject={<InputAdornment position="start">USD</InputAdornment>}
          validatorProps={{
            restrictions: "required|numeric",
            message: "Ingresa un monto válido, debe ser menor que la cantidad de regalías disponibles.",
            validator,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UserFinancialData;
