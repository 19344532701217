import { useState, useCallback } from 'react';
import Danger from 'components/Typography/Danger';

// Esto siempre tiene que estar en production cuando hago el build
export const nodeEnv = "production";
// export const nodeEnv = "development";

/** 
 * Llamado 'promise wrappers'
 * Wrapper para manejar promesas con sintaxis await, devolviendo [error, data].
 * @param {Promise} promise - La promesa a manejar.
 * @returns {Array} [error, data] - Devuelve un array con el error o los datos.
 */
export const to = promise => {
  return promise
    .then(data => {
      return [null, data];
    })
    .catch(err => [err]);
}

export const toWithOutError = (promise) => {
  return promise.then(result => result);
}

export const errorFormat = (message) => (
  <Danger color="error" variant="h6">{message}</Danger>
);

export const getChildData = (obj, key) => obj[key];

// Limited to only JSON supported types.
export const cloneDeepLimited = obj => JSON.parse(JSON.stringify(obj));

export const copyFormDataToJSON = maybeFormData => {
  const isFormData = maybeFormData instanceof FormData;
  if (!isFormData) return maybeFormData;
  if (!maybeFormData || typeof maybeFormData.forEach !== 'function') {
    return JSON.parse(JSON.stringify(object));
  }
  let object = {};
  maybeFormData.forEach((value, key) => {
    if (key === "cover" || key === "track") {
      object[key] = { originalname: value.originalname, sizeInMB: value.size / 1000000 }
    }
    else object[key] = value
  });
  let json = JSON.parse(JSON.stringify(object));
  return json;
}

export const useForceUpdate = () => {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}

export const combineArraysWithNoDuplicates = (arr1, arr2) => [...new Set(arr1.concat(arr2))];

export const deepEqual = function (x, y) {
  if (x === y) return true;
  else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) if (!deepEqual(x[prop], y[prop])) return false;
      else return false;
    }
    return true;
  }
  else return false;
}

export const formatAllNumber = (number, decimals, separator) => {
  return formatThousandsPoint(formatPeriodComma(truncateFloat(number, decimals, separator)));
}

export const findAnyValueWithError = targetObj => {
  for (let key in targetObj) {
    if (targetObj[key] === "ERROR") return true;
  }
  return false;
}

export const truncateFloat = (number, decimals, separator) => {
  if (number === 0) return 0;
  if (!number) return 0;
  if (decimals === 0) return Math.floor(number);
  if (number.toString().split(separator).length < 2) return `${number.toString()}${separator}${'00'}`;
  let decimalsTruncated = number.toString().split(separator)[1].slice(0, decimals);

  if (decimalsTruncated.length === 1) {
    return `${number.toString().split(separator)[0]}${separator}${decimalsTruncated}${'0'}`;
  }
  return `${number.toString().split(separator)[0]}${separator}${decimalsTruncated}`;
}

export const formatThousandsPoint = number => number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0;
export const formatPeriodComma = number => number ? number.toString().replace(".", ",") : 0;

export const addToObjectInPosition = (obj, key, value, index) => {
  var temp = {};
  var i = 0;

  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      if (i === index && key && value) temp[key] = value;
      temp[prop] = obj[prop];
      i++;
    }
  }

  if (!index && key) Object.assign(temp, { [key]: value });
  return temp
}

export const generateOTP = () => {
  const digits = '0123456789';
  let otp = '';
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * digits.length);
    otp += digits[randomIndex];
  }
  return otp;
}

// Función para normalizar el texto: convertir a minúsculas y eliminar tildes
export const normalizeText = (text) => {
  return text
    .toLowerCase()
    .normalize("NFD") // Descompone caracteres acentuados
    .replace(/[\u0300-\u036f]/g, ""); // Elimina los diacríticos (tildes)
};
