import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { artistsStateNameToStateId, getArtistPropsForDataTable, getArtistPropsForUserDataTable, getArtistsByCustomQuery, getQueryArtists } from "utils/artists.utils";
import { toWithOutError } from 'utils';
import { getSearchedUserRedux, usersGetOneByIdRedux } from "../../redux/actions/UsersActions";
import UserDialog from '../Users/UserDialog';
import { getEmailIfNotHaveUser } from '../../utils/users.utils';
import useFirestoreQuery from '../../customHooks/useFirestoreQuery';
import { getElementsAdminQueryFS } from "services/FirestoreServices";
import { sortArtistsByField } from '../../utils/artists.utils';
import { artistsAddStore, getArtistByFieldRedux, getArtistByFieldsRedux, getLastCreatedArtistsRedux } from '../../redux/actions/ArtistsActions';

import CustomizedTable from 'components/Table/CustomizedTable';
import { cohesivePurple, whiteColor } from "variables/colors";
import AccountingBar from "components/Navbars/AccountingBar";
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import { resourceNotYoursText, tooltipOrderByCreationDate, tooltipOrderByCreationDateArtists } from "utils/textToShow.utils";
import { userIsAdmin } from 'utils/users.utils';
import ArtistActionsDialog from './ArtistActionsDialog';
import { artistFilterStates } from "utils/artists.table.utils";
import TypographyWithEndIcon from "components/Typography/TypographyWithEndIcon";
import { ArrowDownward, ArrowUpward } from '@mui/icons-material/';

const ArtistsTable = (props) => {

  const { setOpenErrorSearch, setOpenEmptySearch } = props;

  const dispatch = useDispatch();

  const queryStore = useSelector(store => store.query);
  const artists = useSelector(store => store.artists.artists);
  const currentUserData = useSelector(store => store.userData);
  const users = useSelector(store => store.users);
  const rol = currentUserData.rol;
  const isAdmin = userIsAdmin(rol);

  const [openArtistActionsDialog, setOpenArtistActionsDialog] = useState({ open: false, artistId: "" });
  // COSAS DEL BUSCADOR
  const [openNotAdminWarningArtists, setOpenNotAdminWarningArtists] = useState(false);
  const [artistsLoading, setArtistsLoading] = useState(false);

  const [emailSearchValue, setEmailSearchValue] = useState("");
  const [artistSearchValue, setArtistSearchValue] = useState("");
  const [fugaIdSearchValue, setFugaIdSearchValue] = useState("");

  let defaultFilterParams = {
    field: "state", values: [],
    groupBy: { id: 'all', name: "Todos los estados" }, orderBy: { field: 'lastUpdateTS', order: 'desc' }
  }

  const [artistsTableIsOpen, setArtistsTableIsOpen] = useState(true);
  const [pageArtists, setPageArtists] = useState(0);
  const [rowsPerPageArtists, setRowsPerPageArtists] = useState(10);
  const [searchArtistsParams, setSearchArtistsParams] = useState({ field: 'none', value: "" });
  const [filterArtistsParams, setFilterArtistsParams] = useState(defaultFilterParams);
  const [recentlyOrderedArtists, setRecentlyOrderedArtists] = useState(false);
  const [sortedArtists, setSortedArtists] = useState(sortArtistsByField(artists, filterArtistsParams.orderBy.field));
  const [rowsToShow, setRowsToShow] = useState([]);

  useEffect(() => {
    let groupBy = filterArtistsParams.groupBy;
    let artistsQueried = getQueryArtists(artists, searchArtistsParams,
      { field: filterArtistsParams.field, value: groupBy.id });
    let artistsSorted = sortArtistsByField(artistsQueried, filterArtistsParams.orderBy.field);
    setSortedArtists(artistsSorted);
    setRecentlyOrderedArtists(true);
  }, [artists, filterArtistsParams.orderBy]);

  useEffect(() => {
    if (queryStore.name === 'all' || queryStore.collection !== 'artists') return;
    let artistsCustomQueried = getArtistsByCustomQuery(artists, queryStore);
    setSortedArtists(artistsCustomQueried);
  }, [queryStore]);

  useEffect(() => {
    if (recentlyOrderedArtists) {
      setRecentlyOrderedArtists(false);
      if (Array.isArray(sortedArtists)) setRowsToShow(isAdmin
        ? getArtistPropsForDataTable(sortedArtists, handleOpenUsuarioDialog, setOpenArtistActionsDialog) || []
        : getArtistPropsForUserDataTable(sortedArtists, setOpenArtistActionsDialog) || []);
    }
  }, [sortedArtists, recentlyOrderedArtists]);

  const [userSelected, setUserSelected] = useState(false);

  const stateArtistsSnap = useFirestoreQuery(getElementsAdminQueryFS("artists", 50, sortedArtists[0]?.lastUpdateTS || new Date().getTime(),
    isAdmin ? "admin" : currentUserData.id, "lastUpdateTS"));

  //onSnapshots
  useEffect(() => {
    if (stateArtistsSnap.status === "loading") return "Loading...";
    if (stateArtistsSnap.status === "error") return `Error al cargar los ultimos Artistas: ${stateArtistsSnap.error.message}`;
    if (stateArtistsSnap.status === "success" && stateArtistsSnap.data.length > 0) {
      dispatch(artistsAddStore(stateArtistsSnap.data));
    }
  }, [stateArtistsSnap]);


  const handleCloseUserDialog = () => setUserSelected(false);

  const handleOpenUsuarioDialog = async userId => {
    const userData = await toWithOutError(dispatch(usersGetOneByIdRedux(userId, false)));
    setUserSelected(userData);
  }

  const cleanSearchResults = () => {
    setSearchArtistsParams({ field: 'none', value: '' });
    setFilterArtistsParams(defaultFilterParams);
    setSortedArtists(artists);
    setEmailSearchValue("");
    setArtistSearchValue("");
    setFugaIdSearchValue("");
    setPageArtists(0);
  }

  const onGetLastCreatedArtists = async () => {
    if (!isAdmin) {
      setOpenNotAdminWarningArtists(true);
      return;
    }

    // Si ya estaba ordenado, vuelvo al default;
    if (filterArtistsParams.orderBy.field === 'whenCreatedTS') { cleanSearchResults(); return; }

    setArtistsLoading(true);
    handleReorderArtists("whenCreatedTS");
    let artistsFinded = await toWithOutError(dispatch(getLastCreatedArtistsRedux(50)));
    if (artistsFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setSortedArtists([]); return "ERROR"; }
    if (artistsFinded === "EMPTY") { setArtistsLoading(false); setOpenEmptySearch(true); setSortedArtists([]); return "EMPTY"; }
    setPageArtists(0);
    setArtistsLoading(false);
  }

  const onSearchEmailHandler = async email => {
    if (!email) return;
    setArtistsLoading(true);
    let userFinded = users.find(userFromStore => userFromStore.email === email);
    if (!userFinded) {
      userFinded = await toWithOutError(dispatch(getSearchedUserRedux(email)));
      if (userFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setSortedArtists([]); return "ERROR"; }
    }

    setSearchArtistsParams({ field: 'ownerId', value: userFinded.id });
    let artistsFinded = await toWithOutError(dispatch(getArtistByFieldRedux('ownerId', userFinded.id, 50)));
    if (artistsFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setSortedArtists([]); return "ERROR"; }
    if (artistsFinded === "EMPTY") { setArtistsLoading(false); setOpenEmptySearch(true); setSortedArtists([]); return "EMPTY"; }
    setPageArtists(0);
    setArtistsLoading(false);
  }

  const onSearchArtistNameHandler = async artistName => {
    if (!artistName) return;
    if (!isAdmin && !artists.map(artist => artist.name).includes(artistName.trim())) {
      setOpenNotAdminWarningArtists(true);
      return;
    }

    setArtistsLoading(true);
    setSearchArtistsParams({ field: 'name', value: artistName });
    let artistsFinded = await toWithOutError(dispatch(getArtistByFieldRedux('name', artistName.trim(), 100)));
    if (artistsFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setSortedArtists([]); return "ERROR"; }
    if (artistsFinded === "EMPTY") { setArtistsLoading(false); setOpenEmptySearch(true); setSortedArtists([]); return "EMPTY"; }
    setPageArtists(0);
    setArtistsLoading(false);
  }

  const onSearchFugaIdHandler = async artistFugaId => {
    setArtistsLoading(true);
    setSearchArtistsParams({ field: 'fugaId', value: parseFloat(artistFugaId) });
    let artistsFinded = await toWithOutError(dispatch(getArtistByFieldRedux('fugaId', parseFloat(artistFugaId), 5)));
    if (artistsFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setSortedArtists([]); return "ERROR"; }
    if (artistsFinded === "EMPTY") { setArtistsLoading(false); setOpenEmptySearch(true); setSortedArtists([]); return "EMPTY"; }
    setPageArtists(0);
    setArtistsLoading(false);
  }


  const handleEnterKeyPress = (event, searchProps) => {
    if (event.key === 'Enter') {
      if (searchProps.name === "Email") onSearchEmailHandler(searchProps.value);
      if (searchProps.name === "Artista") onSearchArtistNameHandler(searchProps.value);
      if (searchProps.name === "Fuga ID") onSearchFugaIdHandler(searchProps.value);
    }
  }

  const emailSearchProps = { name: "Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler, value: emailSearchValue.trim().toLowerCase(), setValue: setEmailSearchValue };
  const artistSearchProps = { name: "Artista", handleEnterKeyPress, onSearchHandler: onSearchArtistNameHandler, value: artistSearchValue, setValue: setArtistSearchValue };
  const fugaIdSearchProps = { name: "Fuga ID", handleEnterKeyPress, onSearchHandler: onSearchFugaIdHandler, value: fugaIdSearchValue.trim(), setValue: setFugaIdSearchValue };

  const allSearchers = isAdmin ? [emailSearchProps, artistSearchProps, fugaIdSearchProps] : [artistSearchProps];
  let appBarSx = { borderRadius: '0em', backgroundColor: whiteColor };

  //TODO lo mismo que para los albums, usar Redux Query etc etc
  const handleChangePage = (event, newPage) => {
    setPageArtists(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageArtists(parseInt(event.target.value, 10));
  };

  const handleChangeGroupBy = async groupByName => {
    setArtistsLoading(true);
    let artistsStateId = artistsStateNameToStateId(groupByName);
    setFilterArtistsParams({
      ...filterArtistsParams,
      groupBy: { id: artistsStateId, name: groupByName || "Todos los estados" }
    });
    let fields = artistsStateId !== 'all' ? ['state'] : [];
    let values = artistsStateId !== 'all' ? [artistsStateId] : [];
    if (searchArtistsParams.field !== 'none') { fields.push(searchArtistsParams.field); values.push(searchArtistsParams.value) };
    let artistsFinded = await toWithOutError(dispatch(getArtistByFieldsRedux(fields, values, rowsPerPageArtists * 2)));
    if (artistsFinded === "ERROR") { setArtistsLoading(false); setOpenErrorSearch(true); setSortedArtists([]); return "ERROR"; }
    if (artistsFinded === "EMPTY") { setArtistsLoading(false); setOpenEmptySearch(true); setSortedArtists([]); return "EMPTY"; }
    setArtistsLoading(false);
  }

  const groupByProps = isAdmin
    ? [{ helper: "Agrupar según", values: artistFilterStates, handleChangeGroupBy, value: filterArtistsParams.groupBy }]
    : [{ values: [] }];

  const handleReorderArtists = orderByField => {
    setFilterArtistsParams({ ...defaultFilterParams, orderBy: { field: orderByField, order: 'desc' } });
  }

  const fechaCreacionHeader = <TypographyWithEndIcon infoTooltip={tooltipOrderByCreationDateArtists} title="Fecha de Creación" sx={{ fontWeight: "500", fontSize: ".875rem" }}
    containerSize={12} infoSx={{}} handleOnClick={onGetLastCreatedArtists} endIcon={recentlyOrderedArtists ? <ArrowUpward /> : <ArrowDownward />} />

  const artistsTableHeaders = isAdmin
    ? ["Opciones", "Editar", "Nombre", "Spotify Uri", "Apple ID", "Email", "Usuario", "Fuga ID", "Estado", fechaCreacionHeader]
    : ["Opciones", "Nombre", "Spotify Uri", "Apple ID", "Estado"];
  const artistsTableWidth = isAdmin ? ["3%", "2%", "10%", "25%", "8%", "14%", "3%", "10%", "10%", "10%"] : ["10%", "30%", "27%", "23%", "10%"];

  let artistsTableParams = {
    headers: artistsTableHeaders, columnsWidth: artistsTableWidth,
    totalCount: artists.length > 5 ? artists.length : 0, handleChangePage, page: pageArtists,
    headerColor: cohesivePurple, handleChangeRowsPerPage, rowsPerPage: rowsPerPageArtists,
    headersHeight: 65, maxLengthChars: isAdmin ? 40 : 60, maxWidthText: 300, rowsAlignHeaders: 'center',
    rowsAlignCells: 'center', rowsHeight: 30, needPaginationInTable: true, loading: artistsLoading
  };

  return (
    <Grid item xs={12} sx={{ textAlign: "center" }}>

      <InfoActionDialog id='need-permisos' isOpen={openNotAdminWarningArtists} handleClose={() => setOpenNotAdminWarningArtists(false)}
        title={"Necesitas permisos de Administrador"} contentTexts={resourceNotYoursText} />

      {userSelected && <UserDialog userData={userSelected} isOpen={Boolean(userSelected.id)} title={`${getEmailIfNotHaveUser(userSelected)}`}
        handleClose={handleCloseUserDialog} contentTexts={["Proximamente datos del usuario"]} rolAdmin={rol} />}

      <ArtistActionsDialog isOpen={openArtistActionsDialog.open} handleClose={() => setOpenArtistActionsDialog({ open: false, artistId: "" })}
        artistId={openArtistActionsDialog.artistId} caller={'artist-table'} />

      <Grid item xs={12} padding={0} >
        <AccountingBar searchArrayProps={allSearchers} cleanSearchResults={cleanSearchResults}
          appBarSx={appBarSx} appBarTitle='Artistas' mainSearchColor={cohesivePurple} isOpen={artistsTableIsOpen}
          handleCollapseTable={() => setArtistsTableIsOpen(!artistsTableIsOpen)} groupByArray={groupByProps} />
      </Grid>

      {artistsTableIsOpen && <Grid item xs={12} sx={{ margin: 'auto' }}>
        <CustomizedTable rows={rowsToShow} {...artistsTableParams} />
      </Grid>}
    </Grid>
  )
}

export default ArtistsTable;