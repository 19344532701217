import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Link
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ArtificialStreamingWarningDialog = ({ open, onConfirm }) => {

  const avoidCloseIfNotConfirm = (e, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return; // Evita que el diálogo se cierre con clic fuera o con "Esc"
    }
  }

  return (

    <Dialog open={open} onClose={avoidCloseIfNotConfirm} maxWidth="sm" fullWidth disableEscapeKeyDown>

      {/* Título con icono de advertencia */}
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          fontWeight: "bold",
          color: "red"
        }}
      >
        <WarningAmberIcon sx={{ fontSize: 30, color: "red" }} />
        IMPORTANTE: PROHIBIDO EL STREAMING ARTIFICIAL
      </DialogTitle>

      <DialogContent>
        {/* Mensaje de advertencia */}
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Spotify y otras plataformas detectan y penalizan el uso de{" "}
          <strong>bots, granjas de streams o cualquier método artificial</strong>
          para aumentar reproducciones.
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: 2, color: "red", fontWeight: "bold" }}>
          🚨 Si detectan fraude en alguno de tus tracks, <strong>nos cobrarán una multa de $15 USD por cada ISRC</strong>,
          y este monto será descontado de tus regalías o cobrado directamente.
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          🔴 <strong>Evita riesgos:</strong> No contrates servicios que prometan aumentar streams.
        </Typography>

        {/* Sub-bullets adicionales */}
        <Typography variant="body2" sx={{ marginLeft: 3, fontWeight: "bold", color: "black" }}>
          ➤ NO PAGUES POR SERVICIOS QUE OFRECEN MÁS STREAMS <br />
          ➤ NO PAGUES POR SERVICIOS QUE OFRECEN MÁS SEGUIDORES <br />
          ➤ NO PAGUES POR SERVICIOS QUE OFRECEN LUGARES EN PLAYLISTS
        </Typography>

        <Typography variant="body1" sx={{ marginTop: 2 }}>
          🔴 <strong>Tu cuenta podría ser bloqueada o eliminada.</strong> <br />
          🔴 Nos reservamos el derecho de <strong>retener pagos y aplicar sanciones</strong> si detectamos actividad sospechosa.
        </Typography>

        <Typography variant="body1" sx={{ marginTop: 2, fontWeight: "bold" }}>
          ✅ Distribuye tu música de forma legítima y segura.
        </Typography>

        {/* Enlace a la guía de Spotify */}
        <Typography variant="body2" sx={{ marginTop: 3, textAlign: "center" }}>
          📖 Más información sobre streaming artificial en la guía oficial de Spotify:
          <Link
            href="https://artists.spotify.com/es-419/artificial-streaming"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ fontWeight: "bold", textDecoration: "underline", marginLeft: 1 }}
          >
            artists.spotify.com/artificial-streaming
          </Link>
        </Typography>
      </DialogContent>

      {/* Botón de confirmación */}
      <DialogActions sx={{ justifyContent: "center", paddingBottom: 3 }}>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: "#4CAF50",
            fontWeight: "bold",
            textTransform: "none",
            fontSize: "16px",
            padding: "10px 20px",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "#388E3C" }
          }}
        >
          ✋ Juro no usar plataformas artificiales
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArtificialStreamingWarningDialog;
