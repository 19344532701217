import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Grid
} from '@mui/material';
import ProgressButton from '../../components/CustomButtons/ProgressButton';
import { toWithOutError } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { albumsDeliverAllWhenTakenDownRedux, albumsEditRedux, albumsPublishAndDeliveryRedux } from "redux/actions/AlbumsActions";
import SuccessDialog from '../../components/Dialogs/SuccessDialog';
import { albumCanBeRedeliveredByState, albumCouldGenerateRoyalties, generateAppleObservations, getDeliveredTitleDialog, getOurStateFromFugaState } from "utils/albums.utils";
import { getDeliveredContentTextDialog, getAlbumById, albumCouldBeDeliveredToApple } from '../../utils/albums.utils';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';
import { albumsDeleteOrTakenDownRedux } from '../../redux/actions/AlbumsActions';
import { deleteAlbumDialogText, subtitleEditFugaId, titleAppleReject, subtitleAppleReject } from '../../utils/textToShow.utils';
import { Delete, Edit, Mail } from '@mui/icons-material/';
import { useNavigate } from 'react-router-dom';
import { mainBlue } from 'variables/colors';
import { lightBlue } from '../../variables/colors';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import {
  ourListOfDeliveryStates, DELIVERED_NEED_APPLE_REVISION, INVESTIGATE_INFRACTION,
  DELIVERED_APPLE_REJECTED, colorFromFugaState, appleRejectionReasonsList, COPYRIGHT_INFRACTION, TAKEN_DOWN, DELETED, DELIVERED_APPLE_USER_CORRECTION
} from '../../variables/varias';
import { subtitleEditUPC } from 'utils/textToShow.utils';
import { userIsAdmin } from 'utils/users.utils';
import { sendAppleRejection } from "services/BackendCommunication";
import AlbumInfractionDialog from "./AlbumInfractionDialog";


const AlbumActionsDialog = (props) => {

  const { isOpen, handleClose, albumId, caller } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const albums = useSelector(store => store.albums.albums);
  const calledFromTotalInfoAlbum = caller === 'total-info-album';
  const userData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(userData.rol);

  const album = getAlbumById(albums, albumId);
  let canRedeliver = albumCanBeRedeliveredByState(album.state, isAdmin);
  const albumWithPossibleRoyalties = albumCouldGenerateRoyalties(album);
  const newStateToDefine = "";
  const albumIsDeleted = album.state === DELETED || album.state === TAKEN_DOWN || album.state === COPYRIGHT_INFRACTION;

  let deliverToApple = album.state && albumCouldBeDeliveredToApple(album);
  const editOptionsDefault = { open: false, title: "", subtitle: [""], values: "", type: "", handleConfirm: () => null };

  const couldRejectApple = (album.state === DELIVERED_NEED_APPLE_REVISION || album.state === DELIVERED_APPLE_USER_CORRECTION);
  const userName = (userData.nombre + " " + userData.apellido).trim() || "";

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [buttonState, setButtonState] = useState('none');
  const [deliveryState, setDeliveryState] = useState('none');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(editOptionsDefault)
  const [openInfractionDialog, setOpenInfractionDialog] = useState(false);

  const handleDeliveryTo = async targetDelivery => {
    setDeliveryState(targetDelivery);
    let deliveryResponse = "ERROR";
    if (targetDelivery === 'redelivering') {
      let updateState = true;
      deliveryResponse = await toWithOutError(dispatch(albumsDeliverAllWhenTakenDownRedux(album, updateState)));
    }
    else deliveryResponse = await toWithOutError(dispatch(albumsPublishAndDeliveryRedux(album, album.dsps, targetDelivery, deliverToApple)))

    if (deliveryResponse === "ERROR") setButtonState('error');
    if (deliveryResponse === "PUBLISHED") {
      setButtonState('error');
      setDeliveryState(targetDelivery === 'delivering-others' ? 'published-others' : 'published-apple');
      return;
    }
    deliveryResponse !== "ERROR" && setButtonState('success');
    if (deliveryResponse === "DELIVERED") {
      setDeliveryState(targetDelivery === 'delivering-others' ? 'delivered-others' : 'delivered-apple');
    }
    if (deliveryResponse === "REDELIVERED") setDeliveryState('redelivered');
    return;
  }

  let successDialogTitle = getDeliveredTitleDialog(deliveryState);
  let successDialogText = getDeliveredContentTextDialog(deliveryState);

  const handleCloseDelete = () => setOpenDeleteDialog(false);

  const handleCloseActionDialog = () => {
    setButtonState('none');
    setDeliveryState('none');
    handleClose();
  }

  const handleDelete = async () => {
    setLoadingDelete(true);
    let result = await toWithOutError(dispatch(albumsDeleteOrTakenDownRedux(album, albumWithPossibleRoyalties, newStateToDefine)));
    if (result === "ERROR") { setButtonState("error"); setLoadingDelete(false); }
    else {
      if (calledFromTotalInfoAlbum) navigate('/admin/dashboard')
      else setLoadingDelete(false); setOpenDeleteDialog(false); handleCloseActionDialog();
    }
  }

  const goToAlbumInfoAndEdit = () => navigate(`/admin/albums/${album.id}?edit=true`);

  const handleEditDeliveryState = () => setOpenEditDialog({
    open: true, title: "Cambiar el estado de Delivery", subtitle: ["Solo se cambiará en la APP"],
    handleConfirm: (newValue) => handleConfirmEditAlbum(newValue, 'state'),
    initialValues: getOurStateFromFugaState(album.state, isAdmin) || "", optionsValues: ourListOfDeliveryStates,
    values: "", handleCloseDialog: handleCloseEditDialog, type: "only-one-select"
  });

  const handleEditFugaId = () => setOpenEditDialog({
    open: true, title: "Enlazar con lanzamiento de Fuga", subtitle: subtitleEditFugaId,
    handleConfirm: (newValue) => handleConfirmEditAlbum(Number(newValue), 'fugaId'),
    initialValues: album.fugaId || "", values: "", type: '', handleCloseDialog: handleCloseEditDialog
  });

  const handleEditUPC = () => setOpenEditDialog({
    open: true, title: "Editar UPC", subtitle: subtitleEditUPC, handleConfirm: (newValue) => handleConfirmEditAlbum(newValue, 'upc'),
    initialValues: album.upc, values: "", handleCloseDialog: handleCloseEditDialog, type: ''
  });

  const handleConfirmEditAlbum = async (newValue, fieldName) => {
    setButtonState("loading");
    let finalValue = fieldName === 'state' ? newValue.id : newValue;
    let editResult = await toWithOutError(dispatch(albumsEditRedux(album, { [fieldName]: finalValue }, album.ownerEmail, false)));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog(editOptionsDefault);
    if (fieldName === 'state' && (newValue.id === INVESTIGATE_INFRACTION || newValue.id === COPYRIGHT_INFRACTION)) {
      setOpenInfractionDialog(true);
    }
  }

  const handleCloseEditDialog = () => setOpenEditDialog(editOptionsDefault);

  const handleRejectApple = async observationsArrayObject => {
    setButtonState("loading");
    let observationsText = generateAppleObservations(observationsArrayObject)
    let newValues = { state: DELIVERED_APPLE_REJECTED, appleObs: observationsText };

    let editResult = await toWithOutError(dispatch(albumsEditRedux(album, newValues, album.ownerEmail, false)));
    if (editResult === "ERROR") { setButtonState("error"); return; }

    let notificateResult = await toWithOutError(sendAppleRejection(album.ownerEmail, userName, album.title, album.upc, album.nombreArtist, observationsText, dispatch));
    if (notificateResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleOpenRejectApple = () => setOpenEditDialog({
    open: true, title: titleAppleReject, subtitle: subtitleAppleReject, handleConfirm: (observation) => handleRejectApple(observation),
    initialValues: "", values: "", handleCloseDialog: handleCloseEditDialog, optionsValues: appleRejectionReasonsList, type: 'multiple-select'
  });

  const successDelivering = deliveryState === 'delivered-others' || deliveryState === 'delivered-apple' || deliveryState === 'redelivered';

  return (
    <>
      <SuccessDialog isOpen={successDelivering} title={successDialogTitle} contentTexts={successDialogText}
        handleClose={() => setDeliveryState('none')} successImageSource="/images/success.jpg" size="sm" />

      <DeleteDialog isOpen={openDeleteDialog} setIsOpen={setOpenDeleteDialog} handleClose={handleCloseDelete}
        title={"Eliminar Lanzamiento"} textName={album.title} textContent={deleteAlbumDialogText}
        deleteAction={handleDelete} deleteButtonText={"Eliminar"} openLoader={loadingDelete} buttonState={'delete'}
      />

      <EditOrAddFieldsDialog loading={buttonState === "loading"}
        buttonState={buttonState} editOptions={openEditDialog} setEditOptions={setOpenEditDialog} />

      {openInfractionDialog && <AlbumInfractionDialog albumData={album} openInfractionDialog={openInfractionDialog}
        setOpenInfractionDialog={setOpenInfractionDialog} />}

      <Dialog
        maxWidth="sm"
        id="info-dialog"
        open={isOpen}
        onClose={handleCloseActionDialog}>

        <DialogTitle id="title-info-dialog">
          {`Acciones rápidas del Lanzamiento: ${album.title}`}
        </DialogTitle>

        <DialogContent>
          {isAdmin && <DialogContentText key={0}>
            Las acciones que se lleven a cabo en Editar, se verán reflejadas en Fuga.
          </DialogContentText>}
          {isAdmin && <DialogContentText key={1}>
            Si se edita la metadata de un Lanzamiento, al mismo tiempo se realizará el redelivery del mismo.
          </DialogContentText>}

          <Grid container direction="column" paddingTop={2}>
            {(isAdmin && !albumIsDeleted)
              ? <Grid item xs={6} padding={1} textAlign="center">
                <ProgressButton
                  textButton={"Delivery a las DSPs seleccionadas"}
                  loading={deliveryState === 'delivering-others'}
                  buttonState={buttonState}
                  onClickHandler={() => handleDeliveryTo('delivering-others')}
                  noFab={true}
                  buttonFullWidth={true} />
              </Grid>
              : canRedeliver && <Grid item xs={6} padding={1} textAlign="center">
                <ProgressButton
                  textButton={"Redelivery"}
                  loading={deliveryState === 'redelivering'}
                  buttonState={buttonState}
                  onClickHandler={() => handleDeliveryTo('redelivering')}
                  noFab={true}
                  buttonFullWidth={true} />
              </Grid>
            }

            {isAdmin && deliverToApple && !albumIsDeleted && <Grid item xs={6} padding={1} textAlign="center">
              <ProgressButton
                textButton={"Delivery solo a Apple"}
                loading={deliveryState === 'delivering-apple'}
                buttonState={buttonState}
                onClickHandler={() => handleDeliveryTo('delivering-apple')}
                noFab={true}
                buttonFullWidth={true} />
            </Grid>}

            {(isAdmin && !calledFromTotalInfoAlbum) && <Grid item xs={6} padding={1}>
              <Button
                onClick={goToAlbumInfoAndEdit}
                sx={{ backgroundColor: mainBlue, color: 'white', '&:hover': { backgroundColor: lightBlue } }}
                endIcon={<Edit />}
                fullWidth>
                Editar
              </Button>
            </Grid>}

            {isAdmin && <Grid item xs={6} padding={1}>
              <Button
                onClick={handleEditDeliveryState}
                sx={{ backgroundColor: mainBlue, color: 'white', '&:hover': { backgroundColor: lightBlue } }}
                endIcon={<Edit />}
                fullWidth>
                Estado
              </Button>
            </Grid>}

            {isAdmin && <Grid item xs={6} padding={1}>
              <Button
                onClick={handleEditFugaId}
                sx={{ backgroundColor: mainBlue, color: 'white', '&:hover': { backgroundColor: lightBlue } }}
                endIcon={<Edit />}
                fullWidth>
                FUGA ID
              </Button>
            </Grid>}

            {isAdmin && <Grid item xs={6} padding={1}>
              <Button
                onClick={handleEditUPC}
                sx={{ backgroundColor: mainBlue, color: 'white', '&:hover': { backgroundColor: lightBlue } }}
                endIcon={<Edit />}
                fullWidth>
                UPC
              </Button>
            </Grid>}

            {isAdmin && couldRejectApple && <Grid item xs={6} padding={1}>
              <Button
                onClick={handleOpenRejectApple}
                sx={appleRejectButtonSx}
                endIcon={<Mail />}
                fullWidth>
                Rechazar Apple
              </Button>
            </Grid>}

            {!albumIsDeleted && <Grid item xs={6} padding={1}>
              <Button
                onClick={() => setOpenDeleteDialog(true)}
                sx={{ backgroundColor: '#c20202', color: 'white', '&:hover': { backgroundColor: '#c20202' } }}
                endIcon={<Delete />}
                fullWidth>
                {albumWithPossibleRoyalties ? "Dar de baja" : "Eliminar"}
              </Button>
            </Grid>}

          </Grid>

        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleCloseActionDialog()} color="primary">
            Atrás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AlbumActionsDialog;

const appleRejectButtonSx = {
  backgroundColor: colorFromFugaState.DELIVERED_APPLE_REJECTED, color: 'white',
  '&:hover': { backgroundColor: colorFromFugaState.DELIVERED_APPLE_REJECTED }
}

AlbumActionsDialog.defaultProps = {
  isOpen: false,
}

AlbumActionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  albumId: PropTypes.string.isRequired,
}