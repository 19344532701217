import { getNextYearDateFromTsToTs } from "utils/timeRelated.utils";
import { getUserStatusFromPaymentState } from "utils/users.utils";
import { SUB_ORDER_NEED_CHECK, SUB_ORDER_PAYED } from "variables/financial";
import { USER_ACTIVE, USER_NEED_MIGRATION, USER_NEED_VERIFICATION_EMAIL, USER_PENDING_PROOF_CHECK } from "variables/user.variables";

export const createUserDocItem = userData => {
  let date = new Date();
  let lastTimeSignedInString = date.toLocaleString('es-ES', { timeZone: 'America/Argentina/Buenos_Aires' });

  return {
    email: userData.email,
    isNewInFBSystem: true,
    userLogin: userData.email,
    userRegisteredInWp: false,
    deletedInWp: false,
    userStatus: USER_NEED_VERIFICATION_EMAIL,
    nombre: userData.nombre,
    apellido: userData.apellido,
    subgenerosPropios: [],
    generos: [],
    id: userData.id,
    ciudad: "",
    provincia: "",
    telefono: "",
    dni: "",
    timestampWhenCreatedUserInFB: Date.now(),
    lastUpdateTS: Date.now(),
    lastPayUpdateTS: Date.now(),
    lastTimeSignedIn: date.getTime(),
    lastTimeSignedInString,
    rol: "user",
    plan: "charly-garcia",
    taxes: 0,
    stats: {
      totalAlbums: 0,
      totalArtists: 0,
      totalLabels: 0,
      totalTracks: 0,
      totalAlbumsDeleted: 0,
      totalAlbumsTakenDown: 0,
      totalArtistsDeleted: 0,
      totalLabelsDeleted: 0,
      totalTracksDeleted: 0,
      totalArtistsInvited: 0,
      totalCollaborators: 0
    },
    withdrawals: {
      cupones: {
        totalAmount: 0,
        totalWithdrawals: 0
      },
      pesos: {
        totalAmount: 0,
        totalWithdrawals: 0
      },
      usd: {
        totalAmount: 0,
        totalWithdrawals: 0
      }
    },
    subscription: {
      status: USER_NEED_VERIFICATION_EMAIL,
      nextPaymentDate: 0,
      lastPaymentDate: 0,
      cancelledDate: '-',
      currency: '-',
      orderTotal: '-',
      netOrderTotal: '-',
      paymentMethod: '-',
      payId: '-',
      cupon: 0,
      startDate: Date.now()
    },
    shareDeal: 'no-deal'
  };
}

export const getNewUserNeedCheckInfoFromPayment = (user, payment) => {
  return {
    userStatus: USER_PENDING_PROOF_CHECK,
    id: user.id,
    plan: payment.plan,
    subscription: {
      status: SUB_ORDER_NEED_CHECK,
      nextPaymentDate: payment.nextPaymentDate,
      lastPaymentDate: payment.lastPaymentDate,
      cancelledDate: "",
      startDate: user.timestampWhenCreatedUserInFB || payment.firstPaymentDate,
      currency: payment.currency || "ARS",
      orderTotal: payment.transactionAmount,
      netOrderTotal: Number.isFinite(payment.netReceivedAmount) ? payment.netReceivedAmount : payment.transactionAmount || "",
      paymentMethod: payment.payment_method,
      payId: payment.id,
      cupon: payment.cupon,
    }
  }
}

export const getNewUserActiveInfoFromPayment = (user, payment) => {
  return {
    userStatus: USER_ACTIVE,
    id: user.id,
    plan: payment.plan,
    subscription: {
      status: SUB_ORDER_PAYED,
      nextPaymentDate: payment.nextPaymentDate,
      lastPaymentDate: payment.lastPaymentDate,
      cancelledDate: '',
      currency: payment.currency || "ARS",
      orderTotal: payment.transactionAmount,
      netOrderTotal: Number.isFinite(payment.netReceivedAmount) ? payment.netReceivedAmount : payment.transactionAmount || "",
      paymentMethod: payment.payment_method,
      payId: payment.id,
      cupon: payment.cupon,
      startDate: user.timestampWhenCreatedUserInFB || payment.firstPaymentDate
    }
  }
}

export const getNewUserInfoFromPayment = (user, payment) => {
  return {
    userStatus: getUserStatusFromPaymentState(payment),
    id: user.id,
    plan: payment.plan,
    subscription: {
      status: payment.status,
      nextPaymentDate: payment.nextPaymentDate || getNextYearDateFromTsToTs(payment.whenCreatedTS),
      lastPaymentDate: payment.lastPaymentDate || payment.whenCreatedTS,
      cancelledDate: '',
      currency: payment.currency || "ARS",
      orderTotal: payment.transactionAmount,
      netOrderTotal: Number.isFinite(payment.netReceivedAmount) ? payment.netReceivedAmount : payment.transactionAmount || "",
      paymentMethod: payment.payment_method,
      payId: payment.id,
      cupon: payment.cupon,
      startDate: user.timestampWhenCreatedUserInFB || payment.firstPaymentDate
    }
  }
}
