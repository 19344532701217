import {
  SUB_BANK, SUB_CRIPTO, SUB_MP_PENDING, SUB_ORDER_CANCELLED_BY_USER, SUB_ORDER_NEED_PROOF, SUB_ORDER_PAYED,
  SUB_ORDER_PLAN_SELECTED, SUB_PAYPAL, SUB_WESTERN_UNION, SUB_WISE, allPendingPayPaymentsStates,
  allStartedPaymentsStates, allSuccesfullPaymentsStates, mpAlias, mpCVU, ourCriptoInfo, ourPayoneerEmail,
  ourPaypalEmail, ourWesternUnionData, payOptionInChooseReview, paymentStateColors, paymentStatesShowAdmin,
  paymentStatusCouldHadProof, subPaymentMethodToString, SUB_ORDER_PENDING_PAY, paymentStatesListShowAdmin, SUB_MP, paymentsMethodsListToSelect,
  SUB_BANK_USD
} from 'variables/financial';
import { differenceInDaysFromTwoTS, getCantDaysInMS, getDateWithHoursFromTs } from './timeRelated.utils';
import CopyableEditText from 'components/TextField/CopyableEditText';
import { DialogContentText, Typography, Link } from '@mui/material';
import { getCuponAmountUsed, getPlanMaxArtistsFromId } from './users.utils';
import { iconOpenActions } from './tables.utils';
import { truncateFloat } from 'utils';

export const paymentStatusIsSuccesfull = paymentStatus => {
  return allSuccesfullPaymentsStates.includes(paymentStatus)
}

export const paymentStatusIsStarted = paymentStatus => {
  return allStartedPaymentsStates.includes(paymentStatus)
}

export const paymentStatusIsPendingPay = paymentStatus => {
  if (paymentStatus === SUB_ORDER_NEED_PROOF) return false;
  return allPendingPayPaymentsStates.includes(paymentStatus)
}

export const getLastPendingPaymentIfHas = payments => {
  const fiveDaysInMS = getCantDaysInMS(5);
  const nowTS = new Date().getTime();
  let oldPaymentsActive = payments.filter(payment => (payment.whenCreatedTS > nowTS - fiveDaysInMS) && paymentStatusIsPendingPay(payment.status));
  oldPaymentsActive = oldPaymentsActive.sort((pA, pB) => pA.whenCreatedTS < pB.whenCreatedTS);
  if (oldPaymentsActive.length > 0) return oldPaymentsActive[0];
  return "NO_ACTIVE_PAYMENT";
}

export const getLastCompletedPaymentIfHas = payments => {
  let oldPaymentsCompleted = payments.filter(payment => paymentStatusIsStarted(payment.status));
  oldPaymentsCompleted = oldPaymentsCompleted.sort((pA, pB) => pA.whenCreatedTS < pB.whenCreatedTS);
  if (oldPaymentsCompleted.length > 0) return oldPaymentsCompleted[0];
  return "NO_COMPLETED_PAYMENT";
}

export const getPlanFromPayment = (payment, planes) => {
  let itemPlan = payment?.preference_data?.items?.length > 0
    ? payment.preference_data.items[0] : false;
  let planInfo = planes.find(plan => plan.id === itemPlan.id);
  return planInfo;
}

const jsxCvu = <DialogContentText component='span' key={'cvu'}>
  CVU: <b>{mpCVU}</b>
</DialogContentText>

const jsxAlias = <DialogContentText component='span' key={'mp-alias'}>
  Alias: <b>{mpAlias}</b>
</DialogContentText>

const jsxPaymentId = paymentId => <DialogContentText component='span' key={'pay-id'}>
  Numero de Referencia: <b>{paymentId}</b>
</DialogContentText>

export const getPaymentIdJsx = paymentId => <CopyableEditText textToCopy={paymentId} color="grey" jsxElementText={jsxPaymentId(paymentId)} />;
export const getJsxCVU = <CopyableEditText textToCopy={mpCVU} color="grey" jsxElementText={jsxCvu} />;
export const getJsxAlias = <CopyableEditText textToCopy={mpAlias} color="grey" jsxElementText={jsxAlias} />;


export const jsxPayoneer = <DialogContentText component='span' key={'payoneer'}>
  e-mail: <b>{ourPayoneerEmail}</b>
</DialogContentText>

export const jsxPaypal = <DialogContentText component='span' key={'paypal'}>
  e-mail: <b>{ourPaypalEmail}</b>
</DialogContentText>

const jsxTitularWise = <DialogContentText component='span' key={'bank-usd-titular'}>Titular de la cuenta: <b>Julián Olaso</b></DialogContentText>
const jsxEmailWise = <DialogContentText component='span' key={'bank-usd-email'}>Email: <b>finanzas@laflota.com.ar</b></DialogContentText>
const jsxACHWise = <DialogContentText component='span' key={'bank-usd-number-ach'}>Número de ruta ACH y wire: <b>026073150</b></DialogContentText>
const jsxAccountWise = <DialogContentText component='span' key={'bank-usd-number-acc'}>Número de cuenta: <b>8312385771</b></DialogContentText>
const jsxTypeAccountWise = <DialogContentText component='span' key={'bank-usd-type'}>Tipo de cuenta: <b>Corriente</b></DialogContentText>
const jsxAddressWise = <DialogContentText component='span' key={'bank-usd-address'}>Dirección del Banco: <b>Community Federal Savings Bank, 89-16 Jamaica Ave, Woodhaven, NY, 11421, United States</b></DialogContentText>
const jsxAccountWiseOut = <DialogContentText component='span' key={'bank-usd-number-acc-out'}>SWIFT/BIC: <b>CMFGUS33</b></DialogContentText>

export const jsxWuNombre = <DialogContentText component='span' key={'wu-name'}>  Nombre: <b>{ourWesternUnionData.nombre}</b></DialogContentText>
export const jsxWuCiudad = <DialogContentText component='span' key={'wu-ciudad'}>  Ciudad: <b>{ourWesternUnionData.ciudad}</b></DialogContentText>
export const jsxWuDNI = <DialogContentText component='span' key={'wu-dni'}>  DNI: <b>{ourWesternUnionData.dni}</b></DialogContentText>

export const jsxCriptoAddress = <DialogContentText component='span' key={'cripto-address'}>  Dirección: <b>{ourCriptoInfo.direccion}</b></DialogContentText>
export const jsxCriptoRed = <DialogContentText component='span' key={'cripto-red'}>  Red: <b>{ourCriptoInfo.red}</b></DialogContentText>

export const getCriptoJsxData = <>
  <b style={{ fontSize: "16px", fontWeight: 450 }}>Criptomonedas aceptadas:</b>
  <br />
  <span>{ourCriptoInfo.coinsAcceptedText}</span>
  <CopyableEditText textToCopy={ourCriptoInfo.red} color="grey" jsxElementText={jsxCriptoRed} />
  <CopyableEditText textToCopy={ourCriptoInfo.direccion} color="grey" jsxElementText={jsxCriptoAddress} />
</>

export const getWiseJsxData = <>
  Debés enviar el dinero a la siguiente cuenta de Wise:
  <CopyableEditText textToCopy={"finanzas@laflota.com.ar"} color="grey" jsxElementText={jsxEmailWise} />
</>

export const getPaypalJsxData = <>
  Debés enviar el dinero a la siguiente cuenta:
  <CopyableEditText textToCopy={ourPaypalEmail} color="grey" jsxElementText={jsxPaypal} />
  <Link target="_blank" href="https://paypal.me/laflota?country.x=AR&locale.x=es_XC">Link de Pago</Link>
</>
export const getWUJsxData = <>
  <CopyableEditText textToCopy={ourWesternUnionData.nombre} color="grey" jsxElementText={jsxWuNombre} />
  <CopyableEditText textToCopy={ourWesternUnionData.ciudad} color="grey" jsxElementText={jsxWuCiudad} />
  <CopyableEditText textToCopy={ourWesternUnionData.dni} color="grey" jsxElementText={jsxWuDNI} />
</>

export const ourWiseBankInfoJsx = <>
  <b style={{ fontSize: "16px", fontWeight: "bold" }}>Trasnferencias bancarias dentro de los EE.UU:</b>
  <CopyableEditText textToCopy={"Julián Olaso"} color="grey" jsxElementText={jsxTitularWise} />
  <CopyableEditText textToCopy={"finanzas@laflota.com.ar"} color="grey" jsxElementText={jsxEmailWise} />
  <CopyableEditText textToCopy={"026073150"} color="grey" jsxElementText={jsxACHWise} />
  <CopyableEditText textToCopy={"8312385771"} color="grey" jsxElementText={jsxAccountWise} />
  <CopyableEditText textToCopy={"Corriente"} color="grey" jsxElementText={jsxTypeAccountWise} />
  <CopyableEditText textToCopy={"Community Federal Savings Bank, 89-16 Jamaica Ave, Woodhaven, NY, 11421, United States"} color="grey" jsxElementText={jsxAddressWise} />
  <b style={{ fontSize: "16px", fontWeight: "bold" }}>Trasnferencias bancarias desde fuera de los EE.UU:</b>
  <CopyableEditText textToCopy={"CMFGUS33"} color="grey" jsxElementText={jsxAccountWiseOut} />
</>

export const getPaymentJsxDataByMethod = paymentMethod => {
  if (paymentMethod === SUB_BANK) return <>{getJsxCVU}{getJsxAlias}</>;
  if (paymentMethod === SUB_PAYPAL) return <>{getPaypalJsxData}</>;
  if (paymentMethod === SUB_CRIPTO) return <>{getCriptoJsxData}</>;
  if (paymentMethod === SUB_WESTERN_UNION) return <>{getWUJsxData}</>;
  if (paymentMethod === SUB_BANK_USD) return <>{ourWiseBankInfoJsx}</>;
  if (paymentMethod === SUB_WISE) return <>{getWiseJsxData}</>;
}
export const getPaymentChooseValue = payment_method => {
  return payOptionInChooseReview.find(payChooseOption => payChooseOption.subValues.includes(payment_method)) || payOptionInChooseReview[0];
}

export const paymentStatusNameToStatusId = paymentStatusName => {
  return paymentStatesListShowAdmin.find(statusInfo => statusInfo.name === paymentStatusName)?.id || "SIN ESTADO";
}

export const paymentMethodNameToId = paymentMethodName => {
  return paymentsMethodsListToSelect.find(methodInfo => methodInfo.name === paymentMethodName)?.id || "SUB_MP";
}

export const getItemToPay = payment => payment?.preference_data?.items?.length > 0 ? payment.preference_data.items[0] : false;

export const paymentIsFree = payment => {
  let itemPlan = getItemToPay(payment);
  return itemPlan.unit_price === 0;
}

export const paymentCanRefund = lastPaymentDate => {
  const diffInDays = differenceInDaysFromTwoTS(lastPaymentDate, Date.now());
  return Math.abs(diffInDays) < 5.2;
}

export const getQueryPayments = (payments, searchParams, filterParams) => {
  let paymentsResult = payments;
  paymentsResult = paymentsResult.filter(payment => payment.id !== "");

  if (searchParams.field === "outerPaymentId") {
    paymentsResult = paymentsResult.filter(payment => {
      return ((payment.mpPaymentId || payment.otherPaymentId) &&
        ((searchParams.values === payment.otherPaymentId || searchParams.values === payment.mpPaymentId) ||
          (Number(searchParams.values) === payment.otherPaymentId || Number(searchParams.values) === payment.mpPaymentId)))
    });
  }
  else if (searchParams.field !== 'none') {
    paymentsResult = paymentsResult.filter(payment => {
      let paymentValue = payment[`${searchParams.field}`];
      return (paymentValue && (searchParams.values.includes(paymentValue) ||
        searchParams.values === paymentValue ||
        Number(searchParams.values) === paymentValue))
    });
  }

  if (filterParams.value !== 'all')
    paymentsResult = paymentsResult.filter(payment => payment[`${filterParams.field}`] === filterParams.value);

  return paymentsResult;
}

export const sortPaymentsByField = (payments, field) => {
  return payments.sort((pA, pB) => {
    if (!pA[field]) return 1;
    if (!pB[field]) return -1;
    if (pA[field] > pB[field]) return - 1;
    else return 1
  });
}

export const getPaymentStatusToShow = paymentStatusCode => {
  const stateInfoStyle = { color: getPaymentStatusColor(paymentStatusCode), fontSize: "1em", fontWeight: 600 };
  return <Typography sx={stateInfoStyle} >{paymentStatesShowAdmin[paymentStatusCode] || "SIN ESTADO"}</Typography>
}

export const getPaymentStatusColor = paymentStatus => {
  return paymentStateColors[paymentStatus];
}

export const getOtherPaymentId = payment => {
  if (payment.mpPaymentId && payment.mpPaymentId.length < 15) return payment.mpPaymentId;
  return payment.otherPaymentId || "-";
}

export const getOtherPaymentIdField = payment => (payment.payment_method === SUB_MP || payment.payment_method === SUB_BANK)
  ? 'mpPaymentId' : 'otherPaymentId';

export const getPaymentsPropsForDataTable = (payments, setOpenPaymentActionsDialog) => {
  let paymentsPropsToTable = [];
  payments.forEach(payment => {
    const noNeedToPay = false;
    const order = getItemToPay(payment);
    paymentsPropsToTable.push([
      iconOpenActions(payment.id, 'paymentId', setOpenPaymentActionsDialog),
      payment.ownerEmail,
      getPlanMaxArtistsFromId(order.id),
      getPaymentStatusToShow(payment.status),
      getOtherPaymentId(payment),
      payment.id,
      getDateWithHoursFromTs(payment.whenCreatedTS),
      // payment.status === SUB_ORDER_PAYED ? getDateWithHoursFromTs(payment.lastPaymentDate) : "-",
      getDateWithHoursFromTs(payment.lastPaymentDate),
      // getDateWithHoursFromTs(payment.lastUpdateTS),
      subPaymentMethodToString(payment.payment_method, noNeedToPay),
      truncateFloat(getCuponAmountUsed(payment.cupon, payment.currency), 2, '.'),
      <b sx={{ fontWeight: 500 }}>{truncateFloat(payment.transactionAmount, 2, '.')}</b>,
      <b sx={{ fontWeight: 500 }}>{truncateFloat(payment.netReceivedAmount, 2, '.')}</b>,
      <b sx={{ fontWeight: 500 }}>{payment.currency}</b>,
    ]);
  });
  return paymentsPropsToTable;
}

export const getPaymentById = (payments, paymentId) => {
  let paymentFounded = payments.find(p => p.id === paymentId);
  if (!paymentFounded) return "NOT_FOUNDED";
  else return paymentFounded;
}

export const isFinishedPayAndCanRefund = payment => {
  const daysPassed = differenceInDaysFromTwoTS(payment.lastUpdateTS, new Date().getTime());
  return payment.status === SUB_ORDER_PAYED && daysPassed <= 5;
}

export const notMethodChoosedYet = payment => {
  return [SUB_ORDER_PLAN_SELECTED, SUB_ORDER_PENDING_PAY, SUB_MP_PENDING].includes(payment.status);
}
export const isFinishedPay = payment => payment.status === SUB_ORDER_PAYED;

export const canUseOldPayment = oldPayment => {
  return oldPayment.id && [SUB_ORDER_CANCELLED_BY_USER, ...allPendingPayPaymentsStates].includes(oldPayment.status);
}

export const paymentCouldHadProof = paymentStatus => {
  return paymentStatusCouldHadProof.includes(paymentStatus);
}