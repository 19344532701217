import React from "react";
import { Grid } from "@mui/material";
import TextFieldWithInfo from "components/TextField/TextFieldWithInfo";

const AccountDataForEmailBasedPayout = ({ formData, setFormData, accountName, confirmEmail, setConfirmEmail, validator }) => {

  const { email } = formData;

  const handleEmailChange = (event) => {
    setFormData(event);
  };

  const handleConfirmEmailChange = (event) => {
    setConfirmEmail(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      {/* Email Principal */}
      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          name="email"
          required
          label={`Email de ${accountName}`}
          helperTextDown={`Ingresá el email de tu cuenta de ${accountName} a donde recibirás tus regalías.`}
          value={email}
          onChange={handleEmailChange}
          validatorProps={{ restrictions: 'required|email', message: "Ingresa un email válido.", validator }}
        />
      </Grid>

      {/* Confirmación de Email */}
      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          required
          error={confirmEmail !== email}
          name="confirmEmail"
          label={`Confirmar Email de ${accountName}`}
          value={confirmEmail}
          helperTextDown="Confirma el Email."
          onChange={handleConfirmEmailChange}
          validatorProps={{ restrictions: 'required|email', message: "Ingresa un email válido", validator }}
        />
      </Grid>
    </Grid>
  );
};

export default AccountDataForEmailBasedPayout;
