import { IconButton, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material/';
import { fugaGreen, yellowPending, redWarning } from 'variables/colors';
import { CRIPTO, CUPON, DEPOSITO_USD, PAYONEER, WISE, payoutPayMethodViewToId, planesLaFlota } from 'variables/financial';
import { COPYRIGHT_INFRACTION } from 'variables/varias';
import { PAYPAL, DEPOSITO } from '../variables/financial';

export const statusPayout = [{ name: "Todos los estados", id: "all" }, { name: "Solicitados", id: "REQUESTED" }, { name: "Completados", id: "COMPLETED" }];

export const iconOpenActionsPayouts = (elemId, setOpenActionsDialog) => {
  return <IconButton onClick={() => setOpenActionsDialog({ open: true, payoutId: elemId })}>
    <Settings />
  </IconButton>
}

const getColorForPayoutStatus = payoutStatusId => {
  if (payoutStatusId === "REQUESTED") return yellowPending;
  if (payoutStatusId === "COMPLETED") return fugaGreen;
  if (payoutStatusId === COPYRIGHT_INFRACTION) return redWarning
}

const getTextForPayoutStatus = payoutStatusId => {
  if (payoutStatusId === "REQUESTED") return "Solicitado";
  if (payoutStatusId === "COMPLETED") return "Completado";
  if (payoutStatusId === COPYRIGHT_INFRACTION) return "En revisión: Infraction"
}

export const getPayoutStatus = payoutStatusId => {
  return <Typography sx={{ fontWeight: 600, color: getColorForPayoutStatus(payoutStatusId) }} > {getTextForPayoutStatus(payoutStatusId)}</Typography >
}

export const groupByNameToIdPayouts = groupByName => {
  const reducer = {
    "Usuario": "ownerEmail",
    "Mes del Pago": "transferMonth",
    "Moneda": "currency"
  }
  return reducer[groupByName] || "ownerEmail";
}

export const getQueryPayouts = (payouts, searchParams, filterParams) => {
  let payoutsResult = payouts;
  if (searchParams.field !== 'none')
    payoutsResult = payouts.filter(payout => payout[`${searchParams.field}`] === searchParams.value);

  if (filterParams.value !== 'all')
    payoutsResult = payoutsResult.filter(payout => payout[`${filterParams.field}`] === filterParams.value);

  return payoutsResult;
}

export const statusNameToStatusId = stateName => {
  return statusPayout.find(stateInfo => stateInfo.name === stateName)?.id || "all";
}

export const getPayIdField = payout => {
  if (payout.currency === "ARS") return 'otherPayId';
  if (payout.paypalEmail) return 'paypalId';
  if (payout.payoneerEmail) return 'payoneerId';
  return "otherPayId";
}

export const getPayoutById = (payouts, payoutId) => {
  if (!payouts || payouts.length === 0) return {};
  let payoutFounded = payouts.find(payout => payout.id === payoutId);
  if (!payoutFounded) return {};
  else return payoutFounded;
}

const checkBankUsdTransferIsValid = (validator, transferType) => {
  let currentVal = validator.current;
  const commonValues = (
    currentVal.fieldValid('fullName') &&
    currentVal.fieldValid('country') &&
    currentVal.fieldValid('city') &&
    currentVal.fieldValid('bankAddress') &&
    currentVal.fieldValid('postalCode') &&
    currentVal.fieldValid('accountNumber') &&
    currentVal.fieldValid('email')
  )
  let accountValue = false;
  if (commonValues) {
    if (transferType === "ACH") accountValue = currentVal.fieldValid('achRoutingNumber');
    if (transferType === "Wire") accountValue = currentVal.fieldValid('wireRoutingNumber');
    if (transferType === "SWIFT") accountValue = currentVal.fieldValid('swiftCode');
  }
  return commonValues && accountValue;
}

export const checkPayoutFormValidations = (medioDePago, usdTransferFormData, validator) => {
  console.log("MEDIO DE PAGO: ", medioDePago);
  if (validator.current.fieldValid('telefono') && validator.current.fieldValid('userLastName')
    && validator.current.fieldValid('userName')) {
    if (medioDePago.account === "bank") return validator.current.fieldValid('cbuCvuAlias') && validator.current.fieldValid('cuit');
    if (medioDePago.account === "cripto") return validator.current.fieldValid('network') && validator.current.fieldValid('address');
    if (medioDePago.account === "paypal" || medioDePago.account === "wise") return validator.current.fieldValid('email');
    if (medioDePago.account === "bank_usd") return checkBankUsdTransferIsValid(validator, usdTransferFormData.transferType);
    if (medioDePago.account === "cupon"); return true;
  }
  else return false;
}

// Ahora ya tengo 'paymentMethod' como campo en PAYOUT, debería normalizarlos... 
export const getMethodPayFromPayout = paymentMethod => {
  if (paymentMethod === CRIPTO) return "Cripto";
  if (paymentMethod === WISE) return "Wise";
  if (paymentMethod === PAYPAL) return "PayPal";
  if (paymentMethod === PAYONEER) return "Payoneer";
  if (paymentMethod === DEPOSITO) return "Depósito";
  if (paymentMethod === DEPOSITO_USD) return "Depósito USD";
  if (paymentMethod === CUPON) return "Cupón";
  return "Sin información";
}

export const getMethodPayIdFromView = paymentMethodView => {
  return payoutPayMethodViewToId[paymentMethodView] || "NO_ID";
}

export const getPaymentId = (payout, isAdmin) => {
  // TODO
  // ACA SI ES CRIPTO, le muestro el TxID al user.
  if (isAdmin) return (payout.commercialId !== "" && payout.commercialId !== "NO_ID") ? payout.commercialId : payout.id;
  else return payout.id;
}

export const getPlanPriceInUsd = userPlan => {
  let planInfo = planesLaFlota.find(plan => plan.id === userPlan);
  if (planInfo) return planInfo.priceUsd;
  else return 200;
}

export const getTotalsEmptyToShow = {
  email: <b>{"Totales"}</b>, cantPayouts: <b>{0}</b>,
  lastPayAskedDay: <b>{0}</b>, totalPayed: <b>{'USD 0,00'}</b>,
  pendingPay: <b>{'USD 0,00'}</b>
};

export const getIdToEdit = paymentMethod => {
  if (paymentMethod === CRIPTO) return "Cripto ID";
  if (paymentMethod === WISE) return "Wise ID";
  if (paymentMethod === PAYPAL) return "PayPal ID";
  if (paymentMethod === DEPOSITO) return "MP ID";
  if (paymentMethod === CUPON) return "Cupón";
  return "APP ID";
}

export const getUserFinancialDataFromAccount = (usdTransferData, localTransferData, emailBasedData, criptoData, medioDePago) => {
  if (medioDePago.account === "bank") return localTransferData;
  if (medioDePago.account === "bank_usd") return usdTransferData;
  if (medioDePago.account === "wise" || medioDePago.account === "paypal") return emailBasedData;
  if (medioDePago.account === "cripto") return criptoData;
}

export const transferTotalIsValid = (transferTotalUsd, royaltiesAvailable, transferType, usdToArsRate, planPriceInArs, medioDePago) => {
  if (transferTotalUsd > royaltiesAvailable) return false;
  if (transferTotalUsd < 50 && transferType === "Wire") return false;
  if (transferTotalUsd < 50 && transferType === "SWIFT") return false;
  if (transferTotalUsd < 50 && transferType === "ACH") return false;
  if (transferTotalUsd < 50 && medioDePago.account === "cripto") return false;
  if (medioDePago.account === "cupon" && (transferTotalUsd < 1 || (transferTotalUsd > (usdToArsRate * planPriceInArs + 0.1)))) return false;
  if (transferTotalUsd < 10 && medioDePago.account !== "cupon") return false;
  return true;
}

export const helperTextAvailable = (transferTotalUsd, royaltiesAvailable, transferType, usdToArsRate, planPriceInArs, medioDePago) => {
  if (transferTotalUsd < 1 && medioDePago.account === "cupon") return "Si elegís cupón de crédito para pagar tu suscripción el monto no debe ser menor a 1 USD";
  if (transferTotalUsd < 10 && medioDePago.account === "cupon") return "Ingresa la cantidad de Dólares (USD) a retirar.";
  if ((transferTotalUsd > (usdToArsRate * planPriceInArs + 0.1)) && medioDePago.account === "cupon") return `Si elegís cupón de crédito para pagar tu suscripción el monto debe ser menor al precio de tu suscripción: ${planPriceInArs} ARS.`;
  if (transferTotalUsd > royaltiesAvailable) return "El monto ingresado es mayor al monto disponible."
  if (transferTotalUsd < 10) return "No podés retirar un monto menor a 10 USD";
  if (transferTotalUsd < 50 && transferType === "Wire") return "Si elegís Wire Transfer el monto no debe ser menor a 50 USD";
  if (transferTotalUsd < 50 && transferType === "ACH") return "Si elegís transferencia ACH el monto no debe ser menor a 50 USD";
  if (transferTotalUsd < 50 && transferType === "SWIFT") return "Si elegís SWIFT el monto no debe ser menor a 50 USD";
  if (transferTotalUsd < 50 && medioDePago.account === "cripto") return "Si elegís Criptomonedas el monto no debe ser menor a 25 USD";
  return "Ingresa la cantidad de Dólares (USD) a retirar.";
}