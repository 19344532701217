// Important: You can only edit a delivery instruction when its state is NOT_ADDED or ADDED.
export const fugaAlbumsState = ["PENDING", "CANCELLED", "TRACKS_MISSING", "UPC_MISSING", "PUBLISHED", "DELIVERED", "TAKEN_DOWN"
  , "COPYRIGHT_INFRACTION", "DELETED", "DELIVERED_NEED_APPLE_REVISION", "DELIVERED_APPLE_REJECTED"];

export const notDeliveredFugaStatus = ["PENDING", "PUBLISHED", "ACCEPTED", "SUBMITTED", "REJECTED"];

export const fugaDspDeliveryState = ["CANCELLED", "PROCESSING", "DELIVERED", "STALLED", "NOT_ADDED", "ADDED",
  "REJECTED", "BLOCKED", "TAKEN_DOWN", "ERROR", "SCHEDULED"]

export const fugaDspIsDeliveredState = ["PROCESSING", "DELIVERED", "SCHEDULED"];

export const dspIdsWithLiveLinks = { spotify_dsp_id: 746109, apple_dsp_id: 1330598 };

export const demoDspsFuga = [{ dspName: 'Spotify (Demo) 2', dspId: 1723792805, fugaId: 5032161672 },
{ dspName: "Apple Music (Demo) 2", dspId: 1723776097, fugaId: 5032161635 }];

export const imagesSizeTypes = [
  { id: "muse_header_pinned_view", name: "92x92" },
  { id: "muse_header_view", name: "280x280" },
  { id: "firebase", name: "200x200 (nuestras)" }
];

export const forbiddenVersions = ['original', 'exclusive', 'oficial', 'official', 'estudio', 'clean', 'full version', 'studio'];

export const dspsFuga = [
  {
    "fugaId": 4963279799,
    "dspName": "Spotify",
    "dspId": 746109
  },
  {
    "fugaId": 4963279860,
    "dspName": "YouTube Music & Content ID",
    "dspId": 3405271817
  },
  {
    "fugaId": 4963279622,
    "dspName": "Apple Music",
    "dspId": 1330598
  },
  {
    "fugaId": 4963279795,
    "dspName": "TIDAL",
    "dspId": 3440259
  },
  {
    "fugaId": 4963279798,
    "dspName": "TikTok",
    "dspId": 1809226414
  },
  {
    "fugaId": 4963279659,
    "dspName": "Deezer",
    "dspId": 2100357
  },
  {
    "fugaId": 4963279657,
    "dspName": "Facebook Music / Instagram",
    "dspId": 1499657856
  },
  // {
  //   "fugaId": 4963279658,
  //   "dspName": "Facebook Fingerprinting",
  //   "dspId": 1415672002
  // },
  {
    "fugaId": 4963279617,
    "dspName": "Amazon",
    "dspId": 99268
  },
  {
    "fugaId": 4963279614,
    "dspName": "7Digital",
    "dspId": 247916
  },
  {
    "fugaId": 4963279615,
    "dspName": "Ami Entertainment",
    "dspId": 104465305
  },
  {
    "fugaId": 4963279616,
    "dspName": "Anghami",
    "dspId": 20799134
  },
  {
    "fugaId": 4963279612,
    "dspName": "AWA",
    "dspId": 847103579
  },
  {
    "fugaId": 4963279655,
    "dspName": "Bmat",
    "dspId": 1158892521
  },
  {
    "fugaId": 4963279660,
    "dspName": "Boomplay",
    "dspId": 1514168496
  },
  {
    "fugaId": 4963279652,
    "dspName": "Dreamus Company (FLO)",
    "dspId": 2215088010
  },
  {
    "fugaId": 4963279861,
    "dspName": "fizy",
    "dspId": 1988507361
  },
  {
    "fugaId": 4963279679,
    "dspName": "Genie Music",
    "dspId": 2697871022
  },
  {
    "fugaId": 4963279680,
    "dspName": "Gracenote",
    "dspId": 9538495
  },
  {
    "fugaId": 4963279681,
    "dspName": "Hungama",
    "dspId": 1268816855
  },
  {
    "fugaId": 4963279855,
    "dspName": "iHeartRadio",
    "dspId": 3441649
  },
  {
    "fugaId": 4963279864,
    "dspName": "iMusica",
    "dspId": 103725
  },
  {
    "fugaId": 4963279677,
    "dspName": "Jaxsta Music",
    "dspId": 1186352005
  },
  {
    "fugaId": 4963279709,
    "dspName": "JioSaavn",
    "dspId": 316911752
  },
  {
    "fugaId": 4963279706,
    "dspName": "Joox",
    "dspId": 1517454273
  },
  {
    "fugaId": 4963279705,
    "dspName": "KkBox",
    "dspId": 121452605
  },
  {
    "fugaId": 4963279703,
    "dspName": "Kuack Media",
    "dspId": 1226212715
  },
  {
    "fugaId": 4963279707,
    "dspName": "LINE Music",
    "dspId": 1232212955
  },
  {
    "fugaId": 4963279708,
    "dspName": "MePlaylist",
    "dspId": 3357532813
  },
  {
    "fugaId": 4963279737,
    "dspName": "MonkingMe",
    "dspId": 3287973537
  },
  {
    "fugaId": 4963279740,
    "dspName": "Napster",
    "dspId": 103731
  },
  {
    "fugaId": 4963279738,
    "dspName": "Naver Music",
    "dspId": 1674795424
  },
  {
    "fugaId": 4963279742,
    "dspName": "NetEase Cloud Music",
    "dspId": 1382854531
  },
  {
    "fugaId": 4963279739,
    "dspName": "Nuuday A/S",
    "dspId": 464139
  },
  {
    "fugaId": 4963279741,
    "dspName": "Pandora",
    "dspId": 7851192
  },
  {
    "fugaId": 4963279759,
    "dspName": "Peloton",
    "dspId": 2528780514
  },
  {
    "fugaId": 4963279760,
    "dspName": "Pretzel Rocks",
    "dspId": 2406442214
  },
  {
    "fugaId": 4963279761,
    "dspName": "Qobuz",
    "dspId": 9940949
  },
  {
    "fugaId": 4963279762,
    "dspName": "Resso",
    "dspId": 1963181516
  },
  {
    "fugaId": 4963279763,
    "dspName": "Shazam",
    "dspId": 4266325
  },
  {
    "fugaId": 4963279770,
    "dspName": "Slacker",
    "dspId": 16149060
  },
  {
    "fugaId": 4963279790,
    "dspName": "SoundCloud",
    "dspId": 35299696
  },
  {
    "fugaId": 4963279794,
    "dspName": "Tencent",
    "dspId": 1461025062
  },
  {
    "fugaId": 4963279796,
    "dspName": "TIM Music",
    "dspId": 1207204780
  },
  {
    "fugaId": 4963279820,
    "dspName": "TouchTunes / PlayNetwork",
    "dspId": 1130831671
  },
  {
    "fugaId": 4963279822,
    "dspName": "Triller",
    "dspId": 3373969336
  },
  {
    "fugaId": 4963279831,
    "dspName": "Xiami",
    "dspId": 1234931270
  },
];