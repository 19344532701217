import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { userIsAdmin } from "utils/users.utils";
import { toWithOutError } from 'utils';
import useFirestoreQuery from '../../customHooks/useFirestoreQuery';
import { getElementsAdminQueryFS } from "services/FirestoreServices";

import CustomizedTable from 'components/Table/CustomizedTable';
import { cohesiveRed, whiteColor } from "variables/colors";
import AccountingBar from "components/Navbars/AccountingBar";
import DiscountsActionsDialog from "./DiscountsActionsDialog";
import { getDiscountsPropsForDataTableAdmin, getDiscountsPropsForDataTableUser, getQueryDiscounts, sortDiscountsByField } from "utils/discounts.utils";
import { discountsAddLocal, getDiscountstByFieldRedux } from "redux/actions/DiscountsActions";
import { discountStatesListToShow, discountTypesListToShow, discountsStatusNameToStatusId, discountsStringsShowToId } from "variables/discounts.variables";

const DiscountsTable = ({ setOpenErrorSearch, setOpenEmptySearch }) => {

  const dispatch = useDispatch();
  const currentUserData = useSelector(store => store.userData);
  const discounts = useSelector(store => store.discounts.discounts);
  const rol = currentUserData.rol;
  const isAdmin = userIsAdmin(rol);
  // COSAS DEL BUSCADOR
  const defaultDiscountType = "Todos los tipos";
  const defaultCurrencyLabel = "USD/ARS";

  const [discountsLoading, setDiscountsLoading] = useState(false);
  const [emailSearchValue, setEmailSearchValue] = useState("");
  const [nameSearchValue, setNameSearchValue] = useState("");
  const [currencySearchValue, setCurrencySearchValue] = useState(defaultCurrencyLabel);
  const [typeSearchValue, setTypeSearchValue] = useState(defaultDiscountType);

  const [discountsFiltered, setDiscountsFiltered] = useState(discounts);

  useEffect(() => {
    let groupBy = filterDiscountsParams.groupBy;
    let discountsQueried = getQueryDiscounts(discounts, searchDiscountParams,
      { field: filterDiscountsParams.field, value: groupBy.id }, isAdmin ? 'admin' : currentUserData.id);
    setDiscountsFiltered(discountsQueried);
  }, [discounts])

  let defaultFilterParams = {
    field: "status", values: [],
    groupBy: { id: 'all', name: "Todos los estados" }, orderBy: { field: 'lastUpdateTS', order: 'desc' }
  }

  const [openDiscountActionsDialog, setOpenDiscountActionsDialog] = useState({ open: false, discountId: "" });
  const [usersTableIsOpen, setUsersTableIsOpen] = useState(true);
  const [pageDiscounts, setPageDiscounts] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [searchDiscountParams, setSearchDiscountParams] = useState({ field: 'none', value: "" });
  const [filterDiscountsParams, setFilterDiscountsParams] = useState(defaultFilterParams);

  let sortedDiscounts = sortDiscountsByField(discountsFiltered, "lastUpdateTS");

  // const [userSelected, setUserSelected] = useState(false);

  const stateDiscountsSnap = useFirestoreQuery(
    getElementsAdminQueryFS("discounts", 20, sortedDiscounts[0]?.lastUpdateTS || new Date().getTime(),
      isAdmin ? 'admin' : currentUserData.id, "lastUpdateTS"))

  useEffect(() => {
    if (stateDiscountsSnap.status === "loading") return "Loading...";
    if (stateDiscountsSnap.status === "error") return `Error al cargar los ultimos cupones: ${stateDiscountsSnap.error.message}`;
    if (stateDiscountsSnap.status === "success" && stateDiscountsSnap.data.length > 0) dispatch(discountsAddLocal(stateDiscountsSnap.data));
  }, [stateDiscountsSnap])

  const discountsTableElements = isAdmin
    ? getDiscountsPropsForDataTableAdmin(sortedDiscounts, setOpenDiscountActionsDialog)
    : getDiscountsPropsForDataTableUser(sortedDiscounts, setOpenDiscountActionsDialog);
  const discountsTableHeaders = isAdmin
    ? ["Acciones", "Nombre", "Tipo de Cupón", "Cantidad", "# Usado", "% o Dinero", "Monto o %", "Dueño", "Fecha Creación", "Estado", "Actualizado"]
    : ["Nombre", "Tipo de Cupón", "Cantidad", "# Usado", "% o Dinero", "Monto o %", "Fecha Creación", "Usado por", "Estado"];
  const discountsTableWidth = isAdmin
    ? ["3%", "19%", "12%", "4%", "6%", "8%", "8%", "15%", "10%", "5%", "10%"] : ["15%", "15%", "5%", "10%", "8%", "7%", "15%", "5%", "20%"];

  const cleanSearchResults = () => {
    setDiscountsFiltered(discounts);
    setFilterDiscountsParams(defaultFilterParams);
    setCurrencySearchValue(defaultCurrencyLabel);
    setTypeSearchValue(defaultDiscountType);
    setEmailSearchValue("");
    setNameSearchValue("");
    setDiscountsLoading(false);
  }

  const onSearchEmailHandler = async email => {
    if (!email) return;
    setDiscountsLoading(true);

    setSearchDiscountParams({ field: 'ownerEmail', value: email });
    let discountsFinded = await toWithOutError(dispatch(getDiscountstByFieldRedux('ownerEmail', email, 20)));

    if (discountsFinded === "ERROR") { setDiscountsLoading(false); setOpenErrorSearch(true); setDiscountsFiltered([]); return "ERROR"; }
    if (discountsFinded === "EMPTY") { setDiscountsLoading(false); setOpenEmptySearch(true); setDiscountsFiltered([]); return "EMPTY"; }

    setDiscountsFiltered(discountsFinded);
    setDiscountsLoading(false);
  }

  const onSearchCurrencyHandler = async currency => {
    setSearchDiscountParams({ field: 'currency', value: currency });
    if (currency === defaultCurrencyLabel) { cleanSearchResults(); return; }

    let discountsFinded = await toWithOutError(dispatch(getDiscountstByFieldRedux('currency', currency, 20)));

    if (discountsFinded === "ERROR") { setDiscountsLoading(false); setOpenErrorSearch(true); setDiscountsFiltered([]); return "ERROR"; }
    if (discountsFinded === "EMPTY") { setDiscountsLoading(false); setOpenEmptySearch(true); setDiscountsFiltered([]); return "EMPTY"; }

    setDiscountsFiltered(discountsFinded);
    setDiscountsLoading(false);
  }

  const onSearchTypeHandler = async type => {
    setSearchDiscountParams({ field: 'type', value: discountsStringsShowToId[type] });
    if (type === defaultDiscountType) { cleanSearchResults(); return; }

    let discountsFinded = await toWithOutError(dispatch(getDiscountstByFieldRedux('type', discountsStringsShowToId[type], 20)));

    if (discountsFinded === "ERROR") { setDiscountsLoading(false); setOpenErrorSearch(true); setDiscountsFiltered([]); return "ERROR"; }
    if (discountsFinded === "EMPTY") { setDiscountsLoading(false); setOpenEmptySearch(true); setDiscountsFiltered([]); return "EMPTY"; }

    setDiscountsFiltered(discountsFinded);
    setDiscountsLoading(false);
  }

  const onSearchNameHandler = async name => {
    setDiscountsLoading(true);

    setSearchDiscountParams({ field: 'name', value: name });
    let discountFinded = await toWithOutError(dispatch(getDiscountstByFieldRedux('name', name, 5)));

    if (discountFinded === "ERROR") { setDiscountsLoading(false); setOpenErrorSearch(true); setDiscountsFiltered([]); return "ERROR"; }
    if (discountFinded === "EMPTY") { setDiscountsLoading(false); setOpenEmptySearch(true); setDiscountsFiltered([]); return "EMPTY"; }

    setDiscountsFiltered(discountFinded);
    setDiscountsLoading(false);
  }

  const handleEnterKeyPress = (event, searchProps) => {
    if (event.key === 'Enter') {
      if (searchProps.name === "Dueño Email") onSearchEmailHandler(searchProps.value.trim().toLowerCase());
      if (searchProps.name === "Moneda") onSearchCurrencyHandler(searchProps.value);
      if (searchProps.name === "Nombre Cupón") onSearchNameHandler(searchProps.value.trim());
      if (searchProps.name === "Tipo") onSearchTypeHandler(searchProps.value);
    }
  }

  const nameSearchProps = { name: "Nombre Cupón", handleEnterKeyPress, onSearchHandler: onSearchNameHandler, value: nameSearchValue.trim(), setValue: setNameSearchValue };
  const emailSearchProps = { name: "Dueño Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler, value: emailSearchValue.trim().toLowerCase(), setValue: setEmailSearchValue };
  const currencySearchProp = { possibleValues: ["USD/ARS", "ARS", "USD"], shortName: "Moneda", name: "Moneda", handleEnterKeyPress, onSearchHandler: onSearchCurrencyHandler, value: currencySearchValue, setValue: setCurrencySearchValue };
  const typeSearchProp = { possibleValues: [defaultDiscountType, ...discountTypesListToShow], shortName: "Tipo", name: "Tipo", handleEnterKeyPress, onSearchHandler: onSearchTypeHandler, value: typeSearchValue, setValue: setTypeSearchValue };

  const allSearchers = isAdmin ? [currencySearchProp, typeSearchProp, emailSearchProps, nameSearchProps] : [];

  let appBarSx = { borderRadius: '0em', backgroundColor: whiteColor };

  const handleChangePage = (event, newPage) => {
    setPageDiscounts(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeGroupBy = async groupByName => {
    setDiscountsLoading(true);
    let statusId = discountsStatusNameToStatusId[groupByName];
    setFilterDiscountsParams({ ...filterDiscountsParams, groupBy: { id: statusId, name: groupByName || "Todos los estados" } });
    if (groupByName === "Todos los estados") { cleanSearchResults(); return; }

    console.log("STAUTS ID: ", statusId);
    let discountsFinded = await toWithOutError(dispatch(getDiscountstByFieldRedux('status', statusId, 20, isAdmin)));

    if (discountsFinded === "ERROR") { setDiscountsLoading(false); setOpenErrorSearch(true); setDiscountsFiltered([]); return "ERROR"; }
    if (discountsFinded === "EMPTY") { setDiscountsLoading(false); setOpenEmptySearch(true); setDiscountsFiltered([]); return "EMPTY"; }

    setPageDiscounts(0);
    setDiscountsLoading(false)
  }

  let discountsTableParams = {
    rows: discountsTableElements, headers: discountsTableHeaders, columnsWidth: discountsTableWidth, needPaginationInTable: true,
    totalCount: discountsFiltered.length, handleChangePage, page: pageDiscounts, headerColor: cohesiveRed, handleChangeRowsPerPage, rowsPerPage,
    headersHeight: 65, maxLengthChars: 50, maxWidthText: 300, rowsAlignHeaders: 'center', rowsAlignCells: 'center', rowsHeight: 30,
    loading: discountsLoading,
  };

  const discountsGroupByState = ["Todos los estados", ...discountStatesListToShow];

  const groupByProps = isAdmin
    ? [{ helper: "Agrupar según", values: discountsGroupByState, handleChangeGroupBy, value: filterDiscountsParams.groupBy }]
    : [{ values: [] }];

  return (
    <Grid item xs={12} sx={{ textAlign: "center" }}>

      {openDiscountActionsDialog.open && <DiscountsActionsDialog isOpen={openDiscountActionsDialog.open}
        handleClose={() => setOpenDiscountActionsDialog({ open: false, discountId: "" })}
        discountId={openDiscountActionsDialog.discountId} />}

      <Grid item xs={12} padding={0} >
        <AccountingBar searchArrayProps={allSearchers} cleanSearchResults={cleanSearchResults}
          appBarSx={appBarSx} appBarTitle='Cupones' mainSearchColor={cohesiveRed} isOpen={usersTableIsOpen}
          handleCollapseTable={() => setUsersTableIsOpen(!usersTableIsOpen)} groupByArray={groupByProps} />
      </Grid>

      {usersTableIsOpen && <Grid item xs={12} sx={{ margin: 'auto' }}>
        <CustomizedTable {...discountsTableParams} />
      </Grid>}
    </Grid>
  )
}

export default DiscountsTable;