import React, { useState } from "react";
import { Grid, Typography, MenuItem, TextField, Divider } from "@mui/material";
import TextFieldWithInfo from "components/TextField/TextFieldWithInfo";
import CheckboxWithInfo from "components/Checkbox/CheckboxWithInfo";
import { cohesiveGrey, fugaGreen, warningColor } from "variables/colors";

const AccountDataForInternationalPayout = ({ formData, setFormData, resetFormData, validator }) => {

  let { fullName, email, country, city, bankAddress, postalCode, accountNumber, accountType,
    achRoutingNumber, wireRoutingNumber, swiftCode, transferType } = formData;

  const handleCheckboxChange = (type) => {
    resetFormData();
    setFormData({ target: { name: 'transferType', value: type } });
  };

  const handleChangeAccountType = (event) => {
    setFormData({ target: { name: 'accountType', value: event.target.value } });
  };

  const getTransferDescription = () => {
    switch (transferType) {
      case "ACH":
        return (
          <Typography variant="body2" color="textSecondary">
            <strong>ACH (Automated Clearing House)</strong> es una transferencia bancaria dentro de EE.UU.
            Generalmente, tu banco <strong>no te cobrará</strong> o cobrará una comisión muy baja, pero puede tardar de <strong>1 a 3 días hábiles</strong>.
            Recomendado si tienes una cuenta bancaria en EE.UU.
          </Typography>
        );
      case "Wire":
        return (
          <Typography variant="body2" sx={{ color: warningColor }}>
            <strong>Wire Transfer</strong> es una transferencia bancaria rápida dentro de EE.UU.
            Puede tardar menos de 24 horas, pero los bancos suelen cobrar una comisión adicional por transferencia bancaria entrante de <strong>entre $12 y $20 USD</strong>.
            Se recomienda si necesitas el dinero rápidamente y estás dispuesto a pagar la comisión.
          </Typography>
        );
      case "SWIFT":
        return (
          <Typography variant="body2" sx={{ color: warningColor }}>
            <strong>SWIFT</strong> es la opción para enviar USD fuera de EE.UU.
            Tiene una comisión fija de <strong>$2.90 USD</strong> y puede demorar de <strong>2 a 5 días hábiles</strong>.
            Además, otros bancos intermediarios pueden cobrar comisiones adicionales.
            Úsalo si necesitas recibir el dinero en un banco internacional.
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={1}>

      {/* Título */}
      <Grid item xs={12} textAlign="center">
        <Typography sx={subSubtitlesStyles}>Método de Transferencia</Typography>
      </Grid>

      {/* Tipo de Transferencia */}
      <Grid item xs={12} container justifyContent="center" spacing={2}>
        <Grid item>
          <CheckboxWithInfo
            checked={transferType === "ACH"}
            onChecked={() => handleCheckboxChange("ACH")}
            label="ACH (EE.UU.)"
            color={fugaGreen}
            checkBoxHelper="Transferencia dentro de EE.UU. Baja o sin comisión, demora 1-3 días."
          />
        </Grid>

        <Grid item>
          <CheckboxWithInfo
            checked={transferType === "Wire"}
            onChecked={() => handleCheckboxChange("Wire")}
            label="Wire (EE.UU.)"
            color={fugaGreen}
            checkBoxHelper="Transferencia rápida dentro de EE.UU. Costo entre $12 y $20 USD."
          />
        </Grid>

        <Grid item>
          <CheckboxWithInfo
            checked={transferType === "SWIFT"}
            onChecked={() => handleCheckboxChange("SWIFT")}
            label="SWIFT (Fuera de EE.UU.)"
            color={fugaGreen}
            checkBoxHelper="Transferencia fuera de EE.UU. Costo fijo de $2.90 USD, demora 2-5 días."
          />
        </Grid>
      </Grid>

      {/* Descripción del método de transferencia */}
      <Grid item xs={12} sx={{ marginLeft: "15%", marginRight: "15%", marginBottom: "1%" }} textAlign="center">
        {getTransferDescription()}
      </Grid>

      {/* Campos Dinámicos según Tipo de Transferencia */}
      {transferType === "ACH" && (
        <>
          <Grid item xs={6}>
            <TextFieldWithInfo
              fullWidth
              required
              name="achRoutingNumber"
              label="Número de ruta ACH"
              value={achRoutingNumber}
              onChange={setFormData}
              validatorProps={{
                restrictions: 'required|numeric|min:9|max:9',
                message: "Debés ingresar el número de ruta ACH que se compone de 9 números. ",
                validator,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldWithInfo
              fullWidth
              required
              name="accountNumber"
              label="Número de cuenta"
              value={accountNumber}
              onChange={setFormData}
              validatorProps={{
                restrictions: 'required|numeric',
                message: "Debés ingresar el número de cuenta.",
                validator,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              select
              name="accountType"
              label="Tipo de Cuenta"
              value={accountType}
              onChange={handleChangeAccountType}
            >
              <MenuItem value="Corriente">Corriente</MenuItem>
              <MenuItem value="Ahorros">Ahorros</MenuItem>
            </TextField>
          </Grid>
        </>
      )}

      {transferType === "Wire" && (
        <>
          <Grid item xs={6}>
            <TextFieldWithInfo
              fullWidth
              required
              name="wireRoutingNumber"
              label="Número de ruta Fedwire"
              value={wireRoutingNumber}
              onChange={setFormData}
              validatorProps={{
                restrictions: 'required|numeric|min:9|max:9',
                message: "Debés ingresar el número de ruta Fedwire que se compone de 9 números. ",
                validator,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldWithInfo
              fullWidth
              required
              name="accountNumber"
              label="Número de cuenta"
              value={accountNumber}
              onChange={setFormData}
              validatorProps={{
                restrictions: 'required|numeric',
                message: "Debés ingresar el número de cuenta.",
                validator,
              }}
            />
          </Grid>
        </>
      )}

      {transferType === "SWIFT" && (
        <>
          <Grid item xs={6}>
            <TextFieldWithInfo
              fullWidth
              required
              name="swiftCode"
              label="Código SWIFT/BIC"
              value={swiftCode}
              onChange={setFormData}
              validatorProps={{
                restrictions: 'required|min:8|max:11',
                message: "Debés ingresar un código SWIFT/BIC válido (8-11 caracteres).",
                validator,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldWithInfo
              fullWidth
              required
              name="accountNumber"
              label="IBAN/Número de cuenta"
              value={accountNumber}
              onChange={setFormData}
              validatorProps={{
                restrictions: 'required',
                message: "Debés ingresar tu IBAN/Número de cuenta.",
                validator,
              }}
            />
          </Grid>

        </>
      )}

      <Grid item xs={12} textAlign="center" sx={{ marginTop: "16px" }}>
        <Typography sx={subtitlesStyles} >Datos del Destinatario</Typography>
      </Grid>

      {/* Datos Comunes */}
      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          required
          name="fullName"
          label="Nombre completo"
          value={fullName}
          onChange={setFormData}
          helperTextDown="Debe coincidir con el nombre en la cuenta bancaria."
          validatorProps={{
            restrictions: 'required|min:3|max:100',
            message: "Debés ingresar un nombre válido, asociado a tu cuenta de banco.",
            validator,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          name="email"
          label="E-mail"
          value={email}
          onChange={setFormData}
          helperTextDown="Debe coincidir con el e-mail registrado en el banco. Si no tiene un e-mail asociado al banco, déjalo en blanco."
          validatorProps={{
            restrictions: 'email',
            message: "Debés ingresar un email válido.",
            validator,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          required
          name="country"
          label="País del banco"
          value={country}
          onChange={setFormData}
          validatorProps={{
            restrictions: 'required|min:2|max:50',
            message: "Debés ingresar un país válido.",
            validator,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          required
          name="city"
          label="Ciudad del banco"
          value={city}
          onChange={setFormData}
          validatorProps={{
            restrictions: 'required|min:2|max:50',
            message: "Debés ingresar una ciudad válida.",
            validator,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          required
          name="bankAddress"
          label="Dirección del banco"
          value={bankAddress}
          onChange={setFormData}
          validatorProps={{
            restrictions: 'required|min:5|max:100',
            message: "Debés ingresar una dirección válida.",
            validator,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          required
          name="postalCode"
          label="Código Postal del banco"
          value={postalCode}
          onChange={setFormData}
          validatorProps={{
            restrictions: 'required|numeric|min:3|max:10',
            message: "Debés ingresar un código postal válido.",
            validator,
          }}
        />
      </Grid>

    </Grid>
  );
};

export default AccountDataForInternationalPayout;

const subSubtitlesStyles = { fontSize: "25px", fontWeight: "", color: cohesiveGrey };
const subtitlesStyles = { fontSize: "30px", fontWeight: "bold", color: fugaGreen };