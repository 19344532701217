import React from "react";
import { Grid } from "@mui/material";
import TextFieldWithInfo from "components/TextField/TextFieldWithInfo";

const ApplicantDataForm = ({ formData, setFormData, validator }) => {
  return (
    <Grid container spacing={2}>

      {/* Nombre del Solicitante */}
      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          name="userName"
          required
          label="Nombre del Solicitante"
          helperTextDown="Nombre del dueño de la cuenta donde recibirá el dinero."
          value={formData.userName}
          onChange={setFormData}
          validatorProps={{
            restrictions: "required|min:1|max:100",
            message: "Ingresa un nombre.",
            validator,
          }}
        />
      </Grid>

      {/* Apellido del Solicitante */}
      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          required
          name="userLastName"
          label="Apellido"
          value={formData.userLastName}
          helperTextDown="Apellido del dueño de la cuenta donde recibirá el dinero."
          onChange={setFormData}
          validatorProps={{
            restrictions: "required|min:1|max:100",
            message: "Ingresa un apellido.",
            validator,
          }}
        />
      </Grid>

      {/* Celular de Contacto */}
      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          required
          name="telefono"
          label="Celular de Contacto"
          value={formData.telefono}
          placeholder="+549115771715"
          helperTextDown="+549 + (código de área) + N° celular"
          onChange={setFormData}
          validatorProps={{
            restrictions: "required|min:1|max:20",
            message: "Ingresa un número de celular válido.",
            validator,
          }}
        />
      </Grid>

    </Grid>
  );
};

export default ApplicantDataForm;
