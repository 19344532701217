import React, { useState, useEffect } from "react";
import { Grid, Backdrop, CircularProgress } from '@mui/material';

import { resourceNotYoursText, waitForPayouts } from '../../utils/textToShow.utils';
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import CustomizedTable from "components/Table/CustomizedTable";

import { useDispatch, useSelector } from 'react-redux';
import { userIsAdmin, userIsRoot } from 'utils/users.utils';
import { whiteColor } from 'assets/jss/material-dashboard-react.js';
import { fugaGreen } from 'variables/colors';
import AccountingBar from "components/Navbars/AccountingBar";
import WaitingDialog from "components/Dialogs/WaitingDialog";

import {
  createPayoutRowForUser, getPayoutAccountingRows, getTotalsPayoutsAccountingRow, getPayoutsAccountingHeaders,
  payoutsGroupByValues, getPayoutsHeadersForAdmin, getPayoutsHeadersForUser, createPayoutRowForAdmin
} from "factory/payouts.factory";
import { getPayoutsForTableView, getPayoutsAccountingForTableView } from "services/BackendCommunication";
import { getMethodPayIdFromView, groupByNameToIdPayouts, statusNameToStatusId } from "utils/payouts.utils";
import PayoutActionsDialog from "./PayoutActionsDialog";
import { payoutsAddAndDeleteOthersStore } from "redux/actions/PayoutsActions";
import { getRetirosButtons } from 'utils/royalties.utils';
import { getSkeletonRows } from "utils/tables.utils";
import { getPayoutsForTableViewByAllIds } from "services/FirestoreServices";
import { payoutPayMethodToShow } from "variables/financial";

const Payouts = () => {
  const dispatch = useDispatch();
  const currentUserData = useSelector(store => store.userData);
  const rol = currentUserData.rol;
  const payoutsStore = useSelector(store => store.payouts.payouts);

  const isAdmin = userIsAdmin(rol);
  const isRoot = userIsRoot(rol);
  const payoutsCantRowValues = isRoot ? 14 : 13;
  const payoutsAccCantValues = 5;

  // INIT SEARCH STUFF
  const defaultPaymentLabel = "Todos los métodos";
  const defaultCurrencyLabel = "USD/ARS";
  const [emailSearchValue, setEmailSearchValue] = useState("");
  const [emailAccSearchValue, setEmailAccSearchValue] = useState("");
  const [idSearchValue, setIdSearchValue] = useState("");
  const [currencySearchValue, setCurrencySearchValue] = useState(defaultCurrencyLabel);
  const [paymentMethodSearchValue, setPaymentMethodSearchValue] = useState(defaultPaymentLabel);
  // END SEARCH STUFF

  const defaultAccParams = {
    field: "ownerEmail", value: isAdmin ? "" : currentUserData.email, caller: "all",
    filterBy: { field: "status", id: "all", name: "Todos los estados", values: "all" },
    groupBy: { id: 'ownerEmail', name: "Usuario" }, orderBy: { field: 'totalPayed', order: 'DESC' }
  };

  // const [emptyResults, setEmptyResults] = useState(false);
  const [loadingPayouts, setLoadingPayouts] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [payoutsRows, setPayoutsRows] = useState([]);
  const [openNotAdminWarning, setOpenNotAdminWarning] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [payoutsTableIsOpen, setPayoutsTableIsOpen] = useState(true);

  const [accountingRows, setAccountingRows] = useState(getSkeletonRows(payoutsAccCantValues, 2));
  const [filterPayoutsParams, setFilterPayoutsParams] = useState(defaultAccParams);
  const [accountingTableIsOpen, setAccountingTableIsOpen] = useState(true);
  const [openPayoutActionsDialog, setOpenPayoutActionsDialog] = useState({ open: false, payoutId: "" });

  const searchFields = ['ownerEmail', 'status', 'currency', 'paymentMethod'];

  // Pagos individuales
  useEffect(() => {
    if (payoutsStore && payoutsStore.length > 0) {
      setPayoutsRows(payoutsStore.map(wdRow =>
        isAdmin ? createPayoutRowForAdmin(wdRow, setOpenPayoutActionsDialog, isRoot) : createPayoutRowForUser(wdRow, false)
      ));
    }
    else {
      setPayoutsRows([]);
    };
  }, [payoutsStore])

  useEffect(() => {
    const getPayoutsCountAndRows = async () => {
      let { field, value, caller } = filterPayoutsParams;
      let payoutsAndCount = {};
      if (caller === "Pagos") return;
      setPayoutsRows(getSkeletonRows(payoutsCantRowValues, isAdmin ? rowsPerPage : 3));
      if (field === "id_all") payoutsAndCount = await getPayoutsForTableViewByAllIds(value, dispatch);
      if (searchFields.includes(field)) payoutsAndCount = await getPayoutsForTableView(field, value, rowsPerPage, rowsPerPage * page, isAdmin, dispatch);
      let filterPayouts = payoutsAndCount.payouts;
      // Si trajo payouts de mas...
      if (!isAdmin) filterPayouts = filterPayouts.filter(payout => payout.ownerId === currentUserData.id);
      setTotalCount(isAdmin ? payoutsAndCount.count : filterPayouts.length);
      dispatch(payoutsAddAndDeleteOthersStore(filterPayouts));
    }

    getPayoutsCountAndRows();
  }, [page, rowsPerPage, filterPayoutsParams])

  // Total de cada usuario
  useEffect(() => {
    const getAccountingInfo = async () => {
      let accountingValues = [];
      let { groupBy, field, value, orderBy, caller } = filterPayoutsParams;
      if (caller === "Retiros") return;
      setAccountingRows(getSkeletonRows(payoutsAccCantValues, accountingRows.length > 0 ? accountingRows.length : 2));
      accountingValues = await getPayoutsAccountingForTableView(field, value, groupBy.id, orderBy, isAdmin, dispatch);

      if (accountingValues === "EMPTY" || accountingValues === "ERROR") accountingValues = [];
      let accountingRowsToShow = getPayoutAccountingRows(accountingValues.accWithOutPending, accountingValues.accOnlyPending, groupBy, 50, orderBy);
      let totals = value
        ? accountingRowsToShow.length !== 0 ? [] : getTotalsPayoutsAccountingRow([])
        : getTotalsPayoutsAccountingRow(accountingValues?.accWithOutPending || [], accountingValues.accOnlyPending);
      setAccountingRows([totals, ...accountingRowsToShow]);
    }
    getAccountingInfo();
  }, [filterPayoutsParams])

  const handleCollapseAccounting = () => setAccountingTableIsOpen(!accountingTableIsOpen);
  const handleCollapseRoyalties = () => setPayoutsTableIsOpen(!payoutsTableIsOpen);

  const headersPayoutsName = isAdmin
    ? isRoot ? getPayoutsHeadersForAdmin().map(headerWithWidth => headerWithWidth.name)
      : getPayoutsHeadersForAdmin().map(headerWithWidth => headerWithWidth.name).slice(1)
    : getPayoutsHeadersForUser().map(headerWithWidth => headerWithWidth.name);
  const headersPayoutsWidth = isAdmin
    ? isRoot ? getPayoutsHeadersForAdmin().map(headerWithWidth => headerWithWidth.width)
      : getPayoutsHeadersForAdmin().map(headerWithWidth => headerWithWidth.width).slice(1)
    : getPayoutsHeadersForUser().map(headerWithWidth => headerWithWidth.width);
  const headersAccountingName = getPayoutsAccountingHeaders(filterPayoutsParams.groupBy).map(headerWithWidth => headerWithWidth.name);
  const headersAccountingWidth = getPayoutsAccountingHeaders(filterPayoutsParams.groupBy).map(headerWithWidth => headerWithWidth.width);


  const handleChangePage = (event, newPage) => {
    setPayoutsRows(getSkeletonRows(payoutsCantRowValues, rowsPerPage));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPayoutsRows(getSkeletonRows(payoutsAccCantValues, rowsPerPage));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let appBarSx = { borderRadius: '0em', backgroundColor: whiteColor };
  let payoutsTableParams = {
    rows: payoutsRows, headers: headersPayoutsName, columnsWidth: headersPayoutsWidth,
    totalCount, handleChangePage, page, handleChangeRowsPerPage, rowsPerPage, headerColor: fugaGreen,
    headersHeight: 65, maxLengthChars: 17, maxWidthText: 250, rowsAlignCells: 'center', rowsAlignHeaders: 'center',
    rowsHeight: 30, needPaginationInTable: false
  };

  let accountingTableParams = {
    rows: accountingRows, headers: headersAccountingName, columnsWidth: headersAccountingWidth,
    totalCount: 0, headersHeight: 45, rowsHeight: 30, maxLengthChars: 60, maxWidthText: 300,
    rowsAlignCells: 'left', headerColor: fugaGreen, needPaginationInTable: false
  };

  // INIT SEARCH STUFF
  const setSkeletonRows = caller => {
    if (caller === "Retiros") setPayoutsRows(getSkeletonRows(payoutsCantRowValues, rowsPerPage));
    if (caller === "Pagos") setAccountingRows(getSkeletonRows(payoutsAccCantValues, accountingRows.length));
  }

  const onSearchEmailHandler = (email, caller) => {
    if (!email) return;
    setSkeletonRows(caller);
    setFilterPayoutsParams({ ...filterPayoutsParams, caller, field: "ownerEmail", value: email });
  }

  const onSearchCurrencyHandler = currency => {
    if (!currency) return;
    setSkeletonRows('Retiros');
    setFilterPayoutsParams({ ...filterPayoutsParams, caller: "Retiros", field: "currency", value: currency === "USD/ARS" ? "" : currency });
  }

  const onSearchPaymentMethodHandler = paymentMethod => {
    if (!paymentMethod) return;
    setSkeletonRows('Retiros');
    const paymentMethodValue = paymentMethod === defaultPaymentLabel ? "" : getMethodPayIdFromView(paymentMethod);
    setFilterPayoutsParams({ ...filterPayoutsParams, caller: "Retiros", field: "paymentMethod", value: paymentMethodValue })
  }

  const onSearchIdHandler = id => {
    if (!id) return;
    setSkeletonRows('Retiros');
    setFilterPayoutsParams({ ...filterPayoutsParams, caller: "Retiros", field: "id_all", value: id })
  }

  const handleEnterKeyPress = (event, searchProps, caller) => {
    if (event.key === 'Enter') {
      if (searchProps.name === "Email") onSearchEmailHandler(searchProps.value, caller);
      if (searchProps.name === "ID's") onSearchIdHandler(searchProps.value);
      if (searchProps.name === "Moneda") onSearchCurrencyHandler(searchProps.value)
    }
  }

  const paymentValues = [defaultPaymentLabel, ...payoutPayMethodToShow];

  const emailSearchProps = { possibleValues: [], shortName: "Email", name: "Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler, value: emailSearchValue.trim().toLowerCase(), setValue: setEmailSearchValue };
  const currencySearchProp = { possibleValues: ["USD/ARS", "ARS", "USD"], shortName: "Moneda", name: "Moneda", handleEnterKeyPress, onSearchHandler: onSearchCurrencyHandler, value: currencySearchValue, setValue: setCurrencySearchValue };
  const paymentMethodSearchProp = { possibleValues: paymentValues, shortName: "Pago", name: "Método de pago", handleEnterKeyPress, onSearchHandler: onSearchPaymentMethodHandler, value: paymentMethodSearchValue, setValue: setPaymentMethodSearchValue };
  const idSearchProps = { possibleValues: [], shortName: "ID's", name: "ID's", handleEnterKeyPress, onSearchHandler: onSearchIdHandler, value: idSearchValue.trim(), setValue: setIdSearchValue };
  const emailAccSearchProps = { possibleValues: [], shortName: "Email", name: "Email", handleEnterKeyPress, onSearchHandler: onSearchEmailHandler, value: emailAccSearchValue.trim().toLowerCase(), setValue: setEmailAccSearchValue };

  const handlePagosChangeGroupBy = groupByName => {
    setFilterPayoutsParams({
      ...filterPayoutsParams,
      caller: 'Pagos',
      groupBy: { id: groupByNameToIdPayouts(groupByName), name: groupByName },
      orderBy: { field: groupByNameToIdPayouts(groupByName) === "transferMonth" ? 'transferMonth' : 'totalPayed', order: "DESC" }
    })
  }

  const handleRetirosChangeFilterBy = filterByName => {
    setFilterPayoutsParams({
      ...filterPayoutsParams,
      caller: 'Retiros',
      field: "status",
      value: filterByName === "Todos los estados" ? "" : statusNameToStatusId(filterByName),
      filterBy: {
        id: statusNameToStatusId(filterByName), name: filterByName,
      }
    })
  }

  const groupByProps = [{
    helper: "Agrupar según", values: payoutsGroupByValues,
    handleChangeGroupBy: handlePagosChangeGroupBy, value: filterPayoutsParams.groupBy
  }];
  const filterPayoutsByProps = [{
    helper: "Filtrar por estado", values: ["Todos los estados", "Solicitados", "Completados"],
    handleChangeGroupBy: handleRetirosChangeFilterBy, value: filterPayoutsParams.filterBy
  }];

  const cleanAccountingParams = () => {
    setFilterPayoutsParams({ ...defaultAccParams, caller: "Pagos" });
    setAccountingRows(getSkeletonRows(payoutsAccCantValues, accountingRows.length > 10 ? accountingRows.length : isAdmin ? 10 : 3));
    setEmailAccSearchValue("");
  }

  const cleanPayoutsParams = () => {
    setPayoutsRows(getSkeletonRows(payoutsCantRowValues, accountingRows.length > 10 ? accountingRows.length : isAdmin ? 10 : 3));
    setFilterPayoutsParams({ ...defaultAccParams, caller: "Retiros" });
    setEmailSearchValue("");
    setCurrencySearchValue(defaultCurrencyLabel);
    setPaymentMethodSearchValue(defaultPaymentLabel);
    setIdSearchValue("");
  }

  const cleanSearchResults = caller => {
    if (caller === "Pagos") cleanAccountingParams();
    if (caller === "Retiros") cleanPayoutsParams();
  }
  // END SEARCH STUFF

  const buscadoresAccounting = isAdmin ? [emailAccSearchProps] : []
  const buscadoresRoyalties = isAdmin ? [currencySearchProp, paymentMethodSearchProp, emailSearchProps, idSearchProps] : [];

  const handleCloserWaitingRoyalties = () => setLoadingPayouts(false);

  return (
    <>
      <Backdrop open={false}>
        <CircularProgress />
      </Backdrop>

      <PayoutActionsDialog isOpen={openPayoutActionsDialog.open}
        handleClose={() => setOpenPayoutActionsDialog({ open: false, payoutId: "" })}
        payoutId={openPayoutActionsDialog.payoutId} />

      <WaitingDialog isOpen={loadingPayouts} title="Cargando Pagos" contentTexts={waitForPayouts}
        handleClose={handleCloserWaitingRoyalties} successImageSource="/images/success.jpg" size="sm" />

      <InfoActionDialog id='unable-permissions' isOpen={openNotAdminWarning} handleClose={() => setOpenNotAdminWarning(false)}
        title={"Necesitas permisos de Administrador"} contentTexts={resourceNotYoursText} />

      {/* <InfoActionDialog isOpen={emptyResults} handleClose={() => setEmptyResults(false)}
        title={"Sin resultados"} contentTexts={emptyPaysResult} /> */}

      <Grid item xs={12} sx={{ textAlign: "center" }}>

        {getRetirosButtons(buttonColorStyle, "Ver Regalías")}

        <Grid item xs={12} padding={0} >
          <AccountingBar searchArrayProps={buscadoresRoyalties} cleanSearchResults={cleanSearchResults}
            appBarSx={appBarSx} appBarTitle='Retiros' mainSearchColor={fugaGreen} isOpen={payoutsTableIsOpen}
            handleCollapseTable={handleCollapseRoyalties} groupByArray={filterPayoutsByProps} />
        </Grid>

        {payoutsTableIsOpen && <Grid item xs={12} paddingBottom={2} sx={{ margin: 'auto' }}>
          <CustomizedTable {...payoutsTableParams} />
        </Grid>}

        {isRoot && <Grid item xs={12} paddingTop={2} >
          <AccountingBar searchArrayProps={buscadoresAccounting} cleanSearchResults={cleanSearchResults}
            appBarSx={appBarSx} appBarTitle='Pagos' mainSearchColor={fugaGreen} isOpen={accountingTableIsOpen}
            handleCollapseTable={handleCollapseAccounting} groupByArray={groupByProps} />
        </Grid>}

        {accountingTableIsOpen && isRoot && <Grid item xs={12} sx={{ margin: 'auto' }}>
          <CustomizedTable {...accountingTableParams} />
        </Grid>}

      </Grid>

    </>
  )
}

export default Payouts;

const buttonColorStyle = {
  color: 'white', width: "200px", backgroundColor: fugaGreen, '&:hover': { backgroundColor: fugaGreen, color: 'white' }, raisedPrimary: {
    color: 'white',
  },
};