import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
// core components
import { Grid, Typography, Card, CircularProgress, Backdrop } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getAlbumById, getStateColor, getOurStateFromFugaState, getAppleObsHeight, createImageFromUrlData, getAlbumReleaseDate, albumCouldBeReleased, albumCanBeRedeliveredByState } from "utils/albums.utils";
import { getAlbumFugaImage, getDspsDeliveryStatesFuga, targetUrl } from "services/BackendCommunication";
import { useAxios } from '../../customHooks/useAxios';
import { getLocalDateString, getLocalHoursAndMinutes } from '../../utils/timeRelated.utils';
import ArtistInAddTrack from '../Artists/ArtistInAddTrack';
import { getTracksDataTableFromFugaAssets } from '../../utils/tables.utils';
import { cloneDeepLimited, toWithOutError } from 'utils';
import { albumsPublishAndDeliveryRedux, getFugaAlbumByFugaIdRedux } from "redux/actions/AlbumsActions";
import InfoActionDialog from '../../components/Dialogs/InfoActionDialog';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import useQuery from '../../customHooks/useQuery';
import { useNavigate } from 'react-router';
import { albumsEditRedux } from '../../redux/actions/AlbumsActions';
import SuccessDialog from 'components/Dialogs/SuccessDialog';
import { userIsAdmin, userCanMakeRelease } from 'utils/users.utils';
import { areMissingTracksFuga, getMissingTracksFuga } from '../../utils/tracks.utils';
import useWindowDimensions from '../../customHooks/useWindowDimensions';
import AlbumActionsDialog from 'views/Albums/AlbumActionsDialog';
import DspsDialog from '../DSP/DspsDialog';
import { getAlbumDeliveryState } from '../../utils/delivery.utils';
import { COPYRIGHT_INFRACTION, DELIVERED_APPLE_REJECTED, DELIVERED_APPLE_USER_CORRECTION, INVESTIGATE_INFRACTION } from "variables/varias";
import { usersGetOneByIdRedux } from 'redux/actions/UsersActions';
import { getArtistByFieldRedux } from 'redux/actions/ArtistsActions';
import { artistCanMakeRelease } from "utils/artists.utils";
import Table from 'components/Table/Table';
import { uploadsSignOut } from 'redux/actions/UploadsActions';
import { filesMissingTitle, tracksFugaMissingText, weTransferLink } from '../../utils/textToShow.utils';
import AlbumHeader from "./AlbumTotalInfoSubComponents/AlbumHeader";
import AlbumActionButtons from "./AlbumTotalInfoSubComponents/AlbumActionButtons";
import EditAlbumTitleDialog from "./AlbumTotalInfoSubComponents/EditAlbumTitleDialog";
import ImageInputAlbum from "./AlbumTotalInfoSubComponents/ImageInputAlbum";
import { EditTrackDialog } from "views/Tracks/EditTrackDialog";

const AlbumTotalInfo = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const queryString = useQuery();

  const userData = useSelector(store => store.userData);
  const userEmail = userData.email;
  let isAdmin = userIsAdmin(userData.rol);
  const albums = useSelector(store => store.albums.albums);
  const dataAlbum = getAlbumById(albums, params.albumId);
  const { width } = useWindowDimensions();

  const tracksTableHeaders = ["N°", "Nombre", "ISRC", "Artistas", "Duración (mm:ss)", "Archivo de Audio"];

  const defaultAlbumState = {
    state: "", stateAsStringToShow: "...", stateInfoStyle: "",
    stateStringLength: 150, dspsStates: [], needUpdate: false
  }

  const [openMissingFilesDialog, setOpenMissingFilesDialog] = useState({ open: false, title: "", text: [""] });
  const [albumWasReleased, setAlbumWasReleased] = useState(false);
  const [canEditRelease, setCanEditRelease] = useState(false);
  const [areMissingTracks, setAreMissingTracks] = useState(false);
  const [openSelectDSP, setOpenSelectDSP] = useState(false);
  const [buttonState, setButtonState] = useState("none");
  const [imageAsUrlData, setImageAsUrlData] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [openErrorSearch, setOpenErrorSearch] = useState(false);
  const [isEditing, setIsEditing] = useState(queryString.edit || false);
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, beginner: "", title: "", subtitle: [""], values: "" });
  const [openAlbumActionsDialog, setOpenAlbumActionsDialog] = useState({ open: false, albumId: dataAlbum.id });
  const [albumState, setAlbumState] = useState(defaultAlbumState);
  const [consumerRelaseTime, setConsumerRelaseTime] = useState(null);
  const [isOpenEditTitle, setIsOpenEditTitle] = useState(false);
  const [reloadImage, setReloadImage] = useState(true);
  const [fugaImageData, setFugaImageDate] = useState("");
  const [openEditTrack, setOpenEditTrack] = useState({ open: false, fugaTrackData: {} });

  const appleObsAsArray = dataAlbum.appleObs?.split('\n') || [];
  const albumsIsAppleRejected = albumState.state === DELIVERED_APPLE_REJECTED ||
    (isAdmin && albumState.state === DELIVERED_APPLE_USER_CORRECTION);

  const urlFugaAlbum = dataAlbum.fugaId && `${targetUrl}albums/${dataAlbum.fugaId}`;
  const albumFetch = useAxios(urlFugaAlbum, true);
  let albumFugaData = albumFetch.data;
  let albumFugaStatus = albumFetch.status;

  useEffect(() => {
    const getImageFugaAlbum = async albumFugaId => {
      let albumFugaImage = await toWithOutError(getAlbumFugaImage(albumFugaId, true, dispatch));
      setFugaImageDate(albumFugaImage);
    }
    if (dataAlbum.fugaId && reloadImage) getImageFugaAlbum(dataAlbum.fugaId);
  }, [dataAlbum.fugaId, reloadImage]);

  useEffect(() => {
    if (fugaImageData !== "") {
      setReloadImage(false);
      const imageObjectURL = createImageFromUrlData(fugaImageData);
      setImageAsUrlData(imageObjectURL);
    }
  }, [fugaImageData]);

  const reloadAlbumCover = () => setReloadImage(true);

  useEffect(() => {
    const checkIfCanEditRelease = async userId => {
      let lastArtistData = {};
      const userDataLast = await toWithOutError(dispatch(usersGetOneByIdRedux(userId, true)));
      if (!userCanMakeRelease(userDataLast.userStatus, userDataLast.id)) { setCanEditRelease(isAdmin || false); return }
      else[lastArtistData] = await toWithOutError(dispatch(getArtistByFieldRedux('id', dataAlbum.artistId, 1)));
      setCanEditRelease(isAdmin || artistCanMakeRelease(lastArtistData.state, userDataLast.email));
      return;
    }
    checkIfCanEditRelease(userData.id);
    return () => { dispatch(uploadsSignOut()); };
  }, [])

  useEffect(() => {
    const getDspsDeliveryStates = async () => {
      let dspsStates = await getDspsDeliveryStatesFuga(dataAlbum.fugaId, dataAlbum.ownerEmail, dispatch);
      setAlbumState({ ...albumState, dspsStates, needUpdate: true })
      return dspsStates;
    }

    if (albumFetch.status === "fetched" && albumFugaData.consumer_release_time) {
      let consumerLocalTime = getLocalHoursAndMinutes(albumFugaData.consumer_release_time);
      setConsumerRelaseTime(consumerLocalTime);
    }

    if (albumFetch.status === "fetched" && albumCouldBeReleased(albumFugaData)) setAlbumWasReleased(true);

    let audioTracksMissing = areMissingTracksFuga(albumFugaData.assets);
    let newState = getAlbumDeliveryState(areMissingTracks, dataAlbum.state, albumState.dspsStates);
    let newStateAsString = newState ? getOurStateFromFugaState(newState, isAdmin, dataAlbum.releaseDayjsFormat) : "...";

    if (albumFetch.status === "fetched" && areMissingTracks !== audioTracksMissing) {
      setAreMissingTracks(audioTracksMissing);
      setAlbumState({ ...albumState, stateAsStringToShow: newStateAsString, state: newState, needUpdate: true })
    }

    if (albumFetch.status === "fetched" && albumState.dspsStates.length === 0) {
      getDspsDeliveryStates();
    }

    if (albumState.dspsStates.length > 0 && albumState.needUpdate) {
      setAlbumState({
        state: newState,
        stateAsStringToShow: newStateAsString,
        stateInfoStyle: { color: getStateColor(newState, isAdmin), fontSize: "1em", fontWeight: 500 },
        stateStringLength: newStateAsString.length * 15 > 150 ? newStateAsString.length * 15 : '150px',
        dspsStates: albumState.dspsStates,
        needUpdate: false
      })
    }
  }, [albumFetch, albumState])

  useEffect(() => {
    if (albumFugaStatus === "error") { setOpenLoader(false); setOpenErrorSearch(true) }
    if (albumFugaStatus === "fetching") setOpenLoader(true);
    if (albumFugaStatus === "fetched") setOpenLoader(false);
  }, [albumFugaStatus])

  const tracksDataTable = albumFugaStatus === "fetched" ? getTracksDataTableFromFugaAssets(albumFugaData.assets, isEditing, setOpenEditTrack) : [];
  const albumArtists = albumFugaStatus === "fetched" ? albumFugaData.artists : [];

  const handleCloseEditDialog = () => setOpenEditDialog({ open: false, title: "", subtitle: [""], values: "" });

  const handleConfirmEditAlbum = async (newValue, fieldName) => {
    setButtonState("loading");
    let editResult = await toWithOutError(dispatch(albumsEditRedux(dataAlbum,
      fieldName === "date" ? { ...newValue } : { [fieldName]: newValue }, userEmail, true, albumFugaData)));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("none");
    setOpenEditDialog({ open: false, title: "", subtitle: "", values: "" });
  }

  const handleEditReleaseDate = () => setOpenEditDialog({
    open: true, type: "date", title: "Modifica la fecha de lanzamiento", subtitle: [""]
    , handleConfirm: (newDate) => handleConfirmEditAlbum(newDate, 'date')
    , initialValues: { dayOfMonth: dataAlbum.dayOfMonth, month: dataAlbum.month, year: dataAlbum.year }
    , values: "", handleCloseDialog: handleCloseEditDialog
  })

  const goToPrincipalArtist = () => navigate(`/admin/edit-artist/${dataAlbum.artistId}`);

  const handleEditOrRedeliver = async () => {
    // Por mercuriales
    if (albumFugaData.id === 6464155126) return;
    dispatch(uploadsSignOut());
    if (!isEditing) { setIsEditing(true); return };
    if (!albumWasReleased || areMissingTracks) {
      // need to check if now are all files ok.
      setOpenLoader(true);
      let newFugaAlbumDataResponse = await toWithOutError(dispatch(getFugaAlbumByFugaIdRedux(dataAlbum.fugaId)))
      setOpenLoader(false);
      if (newFugaAlbumDataResponse === "ERROR") { console.log("ERROR"); return; }
      if (areMissingTracksFuga(newFugaAlbumDataResponse.assets)) {
        setOpenMissingFilesDialog({
          open: true, title: filesMissingTitle,
          text: tracksFugaMissingText(getMissingTracksFuga(albumFugaData.assets))
        });
        return;
      }
      await handleDelivery(dataAlbum);
      return;
    }
    handleOpenDsps();
  }

  const handleDelivery = async albumUploaded => {
    setOpenLoader(true);
    let deliverToApple = Boolean(albumUploaded.dsps.find(dspInfo => dspInfo.dspName === "Apple Music"));
    let responsePublishAndDelivery = await toWithOutError(dispatch(albumsPublishAndDeliveryRedux(albumUploaded, albumUploaded.dsps, 'all', deliverToApple)));
    if (responsePublishAndDelivery === "ERROR") return "ERROR";
    setButtonState('success');
    setIsEditing(false);
    setOpenLoader(false);
    let newState = responsePublishAndDelivery === "PUBLISHED" ? "PUBLISHED" : "DELIVERED";
    let newStateAsString = newState ? getOurStateFromFugaState(newState, isAdmin, dataAlbum.releaseDayjsFormat) : "...";
    setAlbumState({
      state: newState,
      stateAsStringToShow: newStateAsString,
      stateInfoStyle: { color: getStateColor(newState, isAdmin), fontSize: "1em", fontWeight: 500 },
      stateStringLength: newStateAsString.length * 15 > 150 ? newStateAsString.length * 15 : '150px',
      dspsStates: albumState.dspsStates,
      needUpdate: false
    });
  }

  const handleCancelEditing = () => setIsEditing(false);
  const handleActionDialog = () => setOpenAlbumActionsDialog({ albumId: dataAlbum.id, open: !openAlbumActionsDialog.open });

  const handleOpenDsps = () => setOpenSelectDSP(true);

  let canRedeliver = albumCanBeRedeliveredByState(dataAlbum.state, isAdmin);

  const cardAppleRejectionReasonsStyle = {
    borderRadius: "30px", width: "80em",
    height: getAppleObsHeight(appleObsAsArray, width),
    marginTop: "1em"
  };

  const getCardShowArtistHeight = artists => {
    let artistsInOneRow = width > 1200 ? 4 : 2;
    let totalRows = Math.floor(artists.length / artistsInOneRow);
    let heightsInEM = 10 + (totalRows * 4);
    return heightsInEM + 'em';
  }

  const handleCloseMissingFiles = () => setOpenMissingFilesDialog({ open: false, title: "", text: [""] });

  const handleChangePrimaryArtist = artistIndex => {
    const newArtists = cloneDeepLimited(albumArtists);
    // TODO aca editar el artista en el album en fuga y FS y pedir info total nuevamente
    newArtists[artistIndex].primary = !albumArtists[artistIndex].primary;
  };

  return (
    <Grid container justifyContent="center" sx={{ widht: "90em" }}>

      <Backdrop open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DspsDialog isOpen={openSelectDSP} setIsOpen={setOpenSelectDSP} currentAlbumData={dataAlbum}
        albumWasReleased={albumWasReleased} albumWasEdited={isEditing} setAlbumIsEditing={setIsEditing} />

      <AlbumActionsDialog isOpen={openAlbumActionsDialog.open} handleClose={handleActionDialog}
        albumId={openAlbumActionsDialog.albumId} caller={'total-info-album'} />

      {openEditTrack.open && <EditTrackDialog openEditTrack={openEditTrack.open}
        setOpenEditTrack={setOpenEditTrack} trackFugaData={openEditTrack.fugaTrackData}
        userCreds={{ ownerId: dataAlbum.ownerId, ownerEmail: dataAlbum.ownerEmail }} />}

      <SuccessDialog isOpen={buttonState === "success"} title={`Se ha realizado el redelivery de tu lanzamiento`}
        contentTexts={[[`Los cambios que has hecho se verán reflejados en las tiendas en los próximos días.`]]}
        handleClose={() => navigate(`/admin/dashboard`)} successImageSource="/images/successArtists.jpg" />

      <EditOrAddFieldsDialog loading={buttonState === "loading"}
        buttonState={buttonState} type={openEditDialog.type || ""} editOptions={openEditDialog} setEditOptions={setOpenEditDialog} />

      {isOpenEditTitle && <EditAlbumTitleDialog setIsOpen={setIsOpenEditTitle} targetUserEmail={dataAlbum.ownerEmail}
        oldAlbumData={dataAlbum} fugaAssets={albumFugaData.assets} />}

      <InfoActionDialog id='error-info-album' isOpen={openErrorSearch} handleClose={() => setOpenErrorSearch(false)}
        title={"Hubo un error al traer la información del lanzamiento."} contentTexts={["Por favor, intente nuevamente."]} />

      <InfoActionDialog id='missing-files' isOpen={openMissingFilesDialog.open} handleClose={handleCloseMissingFiles}
        title={openMissingFilesDialog.title} contentTexts={openMissingFilesDialog.text} />

      {/* BOTONES */}
      <AlbumActionButtons
        canRedeliver={canRedeliver}
        canEditRelease={canEditRelease}
        isEditing={isEditing}
        areMissingTracks={areMissingTracks}
        handleEditOrRedeliver={handleEditOrRedeliver}
        handleCancelEditing={handleCancelEditing}
        handleActionDialog={handleActionDialog}
        handleOpenDsps={handleOpenDsps}
        albumData={dataAlbum}
      />

      {/* IMAGEN */}
      <ImageInputAlbum
        dataAlbum={dataAlbum}
        imageAsUrlData={imageAsUrlData}
        isEditing={isEditing}
        fugaCoverImageId={albumFugaData.cover_image ? albumFugaData.cover_image.id : ""}
        onImageUploadSuccess={reloadAlbumCover}
      />

      <AlbumHeader
        isEditing={isEditing}
        title={dataAlbum.title}
        version={dataAlbum.version}
        artists={albumFugaStatus === 'fetched' ? [albumFugaData.display_artist] : ""}
        handleEditTitle={() => setIsOpenEditTitle(true)}
        goToPrincipalArtist={goToPrincipalArtist}
        creationDate={`${albumFugaStatus === "fetched" ? getLocalDateString(albumFugaData.created_date) : ""}`}
        releaseDate={getAlbumReleaseDate(dataAlbum)}
        consumerReleaseTime={consumerRelaseTime} // Este prop pasa el tiempo local del lanzamiento
        upc={dataAlbum.upc}
        label={albumFugaStatus === 'fetched' ? albumFugaData.label.name : ""}
        isAdmin={isAdmin}
        fugaId={dataAlbum.fugaId}
        handleEditReleaseDate={handleEditReleaseDate} // Comportamiento de edición de la fecha
        albumState={albumState} // Estado del álbum
        albumFugaStatus={albumFugaStatus}
      />

      {appleObsAsArray.length > 0 && albumsIsAppleRejected &&
        <Card sx={cardAppleRejectionReasonsStyle}>
          <Grid container>
            <Grid item xs={12} textAlign='left'>
              <Typography padding={1} sx={appleTitleRejectionsReasons}>
                Razones por lo cual el lanzamiento fue rechazado en Apple:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ul>{appleObsAsArray.map(appleObs => { return <li>{appleObs}</li> })}</ul>
            </Grid>
          </Grid >
        </Card >}

      {!isEditing && areMissingTracks && <Card sx={cardAreMissingStyle}>
        <Grid item xs={12} textAlign='center'>
          <Typography sx={isEditingTextStyle}>
            Hay archivos faltantes, debés hacer click en el botón de <b>EDITAR</b> para cargar nuevamente un archivo.
          </Typography>
          <Typography sx={isEditingTextStyle}>
            Si continúa fallando la carga del archivo, puedes enviarlo por: <>{weTransferLink}</>
          </Typography>
        </Grid>
      </Card>}

      {isEditing &&
        <Grid item xs={12} textAlign='center' padding={1}>
          <Typography sx={isEditingTextStyle}>
            Recuerda que después de aplicar los cambios (incluidos cambios de Archivos de Audio)
            , debés hacer click en el botón de <b>Redistribuir</b> o <b>Distribuir</b>.
          </Typography>
        </Grid>}

      <Grid container justifyContent='center' padding={1}>
        <Card sx={{ width: "90em", borderRadius: '30px' }}>
          <Table
            tableHeaderColor="primary"
            tableHead={tracksTableHeaders}
            tableData={tracksDataTable}
          />
        </Card>
      </Grid>

      <Card sx={{ width: '90em', borderRadius: "30px", height: getCardShowArtistHeight(albumArtists) }}>

        <Grid item xs={12} padding="1em 1em 1em">
          <Typography sx={artistsTitleStyle}>{`${albumArtists.length > 1 ? "Artistas" : "Artista"} del Lanzamiento`}</Typography>
        </Grid>

        <Grid container item xs={12} spacing={1} sx={gridArtistViewsStyle}>
          {albumArtists.length > 0
            ? albumArtists.map((_, index) =>
              <ArtistInAddTrack
                key={index}
                index={index}
                gridSize={width < 1427 ? width < 1200 ? 6 : 4 : 3}
                // handleDelete={handleDeleteOtherArtist}
                handleSliderChange={() => handleChangePrimaryArtist(index)}
                artists={albumArtists}
                allOtherArtists={[]}
                disableSlider={true} />)
            : []
          }
        </Grid>
      </Card>

    </Grid >
  );
}

export default AlbumTotalInfo;

const cardAreMissingStyle = { borderRadius: "30px", width: "80em", height: '50px', marginTop: "1em" };
const isEditingTextStyle = { color: "rgba(0,0,0,0.9)", fontSize: '1.1em', marginLeft: '20px' };
const appleTitleRejectionsReasons = { color: "rgba(0,0,0,0.9)", marginLeft: "20px", fontWeight: 450, fontSize: '1.3em' };
const gridArtistViewsStyle = { marginLeft: '4px' };
const artistsTitleStyle = { fontSize: "1.5em", fontWeight: 500 };