import { useState } from 'react';
import { getChildData, cloneDeepLimited } from '../utils';

const useForm = (initialValues) => {
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    const isCheckbox = type === 'checkbox';

    setFormValues((data) => {
      const dataClone = cloneDeepLimited(data);
      const keys = name.split('.');
      const nestedSegments = keys.slice(0, -1);
      const [finalSegment] = keys.slice(-1);
      const finalData = nestedSegments.reduce(getChildData, dataClone);
      finalData[finalSegment] = isCheckbox ? checked : value;
      return dataClone;
    });
  };

  const resetForm = () => {
    setFormValues(cloneDeepLimited(initialValues));
  };

  return [formValues, handleChange, resetForm];
};

export default useForm;
