// AlbumLinksButton.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, CircularProgress, Backdrop } from '@mui/material';
import { albumGetLiveLinkRedux } from 'redux/actions/AlbumsActions';
import { toWithOutError } from 'utils';
import LiveLinksDialog from '../LiveLinksDialog';
import { albumByFsStateCouldBeApproved, albumCouldBeReleased } from 'utils/albums.utils';

const AlbumLinksButton = ({ albumData, buttonColorStyle }) => {
  const dispatch = useDispatch();

  const [openLiveLinksDialog, setOpenLiveLinksDialog] = useState("idle");
  const [liveLinksInfo, setLiveLinksInfo] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);

  const handleOpenLiveLinks = async () => {
    setOpenLoader(true);
    const liveLinksResponse = await toWithOutError(dispatch(albumGetLiveLinkRedux(albumData)));
    setOpenLoader(false);

    if (liveLinksResponse === "ERROR") {
      setOpenLiveLinksDialog("error");
    } else if (liveLinksResponse.length === 0) {
      setOpenLiveLinksDialog("not found yet");
    } else {
      setLiveLinksInfo(liveLinksResponse);
      setOpenLiveLinksDialog("founded");
    }
  };

  let albumWasApproved = albumByFsStateCouldBeApproved(albumData);

  return (
    <Grid item padding={1}>
      <Backdrop open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {albumWasApproved && <Button
        variant="contained"
        onClick={handleOpenLiveLinks}
        sx={buttonColorStyle}
      >
        Links
      </Button>}

      <LiveLinksDialog
        isOpen={openLiveLinksDialog !== "idle"}
        handleClose={() => setOpenLiveLinksDialog("idle")}
        liveLinksInfo={liveLinksInfo}
      />
    </Grid>
  );
};

export default AlbumLinksButton;
