import { v4 as uuidv4 } from 'uuid';
import { DISCOUNT_BY_CREDIT_FRIENDS, DISCOUNT_BY_FRIENDS, DISCOUNT_BY_ROYALTIES, DISCOUNT_STATUS_ACTIVE, DISCOUNT_STATUS_CLAIMED } from 'variables/discounts.variables';

const letterForType = {
  DISCOUNT_BY_FRIENDS: "F",
  DISCOUNT_BY_ROYALTIES: "R",
  DISCOUNT_BY_UPGRADE: "U",
  DISCOUNT_BY_VIP: "V",
  DISCOUNT_BY_CREDIT_FRIENDS: "CF",
  DISCOUNT_AUTO_RENEW: "AutoRenew"
}

export const generateDiscountNameFromUserAndType = (ownerEmail, ownerId, type, isAdmin) => {
  let emailWithOutFormat = ownerEmail.includes("@")
    ? ownerEmail.split("@")[0] : "ERROR_FORMAT_EMAIL";
  if (isAdmin) return `${emailWithOutFormat}-AUTO-RENEW-${new Date().getFullYear()}`;
  let tsString = String(new Date().getTime()).slice(5, 10);
  return `${emailWithOutFormat}-${ownerId.slice(5, 10)}-${tsString}-${letterForType[type]}`;
}

export const createDiscount = (ownerId, ownerEmail, claimersEmail, claimersId, name, type) => {
  return {
    ownerEmail: ownerEmail,
    ownerId: ownerId,
    claimersEmail: claimersEmail,
    claimersId: claimersId,
    percentageOrAmount: "percentage",
    amount: 1000,
    currency: "ARS",
    percentage: 10,
    id: uuidv4(),
    status: DISCOUNT_STATUS_ACTIVE,
    usedTS: 0,
    name,
    amountOfEqualDiscounts: 1,
    cantUsed: 0,
    type,
    lastUpdateTS: new Date().getTime(),
    createdTS: new Date().getTime(),
    usedAsFriendsCredit: 0
  }
}

export const discountModelForInviteFriends = ownerUser => {
  return {
    ownerEmail: ownerUser.email,
    ownerId: ownerUser.id,
    claimersEmail: [],
    claimersId: [],
    percentageOrAmount: "percentage",
    amount: 0,
    currency: "",
    percentage: 15,
    id: uuidv4(),
    status: DISCOUNT_STATUS_ACTIVE,
    usedTS: 0,
    name: generateDiscountNameFromUserAndType(ownerUser.email, ownerUser.id, DISCOUNT_BY_FRIENDS),
    amountOfEqualDiscounts: 20,
    cantUsed: 0,
    type: DISCOUNT_BY_FRIENDS,
    lastUpdateTS: new Date().getTime(),
    createdTS: new Date().getTime(),
    usedAsFriendsCredit: 0
  }
}

export const discountModelForRoyaltyPayout = (payoutRecord, isAdmin) => {
  return {
    ownerEmail: payoutRecord.ownerEmail,
    ownerId: payoutRecord.ownerId,
    claimersEmail: isAdmin ? [payoutRecord.ownerEmail] : [],
    claimersId: isAdmin ? [payoutRecord.ownerId] : [],
    percentageOrAmount: "amount",
    amount: payoutRecord.currency === "ARS" ? payoutRecord.transferTotalAskedCurrency : payoutRecord.transferTotalUsd,
    currency: payoutRecord.currency,
    percentage: 0,
    id: uuidv4(),
    status: isAdmin ? DISCOUNT_STATUS_CLAIMED : DISCOUNT_STATUS_ACTIVE,
    usedTS: isAdmin ? new Date().getTime() : 0,
    name: payoutRecord.commercialId,
    amountOfEqualDiscounts: 1,
    cantUsed: isAdmin ? 1 : 0,
    type: DISCOUNT_BY_ROYALTIES,
    lastUpdateTS: new Date().getTime(),
    createdTS: new Date().getTime(),
    usedAsFriendsCredit: 0
  }
}

export const defaultDiscountFormData = {
  ownerEmail: "",
  ownerId: "",
  claimersEmail: [],
  claimersId: [],
  percentageOrAmount: "amount",
  createdTS: new Date().getTime(),
  amount: 0,
  currency: "ARS",
  percentage: 0,
  id: "",
  status: DISCOUNT_STATUS_ACTIVE,
  usedTS: 0,
  name: "",
  amountOfEqualDiscounts: 1,
  cantUsed: 0,
  type: "",
  lastUpdateTS: new Date().getTime(),
  dueDateTS: 0,
  usedAsFriendsCredit: 0
}

export const setDiscountPostPay = (discount, payment) => {
  return {
    ...discount,
    cantUsed: discount.cantUsed + 1,
    claimersEmail: [...discount.claimersEmail, payment.ownerEmail],
    claimersId: [...discount.claimersId, payment.ownerId],
    status: (discount.cantUsed + 1 >= discount.amountOfEqualDiscounts)
      ? DISCOUNT_STATUS_CLAIMED : discount.status,
    usedTS: new Date().getTime(), lastUpdateTS: new Date().getTime()
  }
}

export const setCreditDiscountFromFriendDiscount = (discountFriends, oldCreditDiscount) => {
  if (discountFriends.type !== DISCOUNT_BY_FRIENDS) return "ERROR";
  let totalCredit = (discountFriends.cantUsed * discountFriends.percentage) - discountFriends.usedAsFriendsCredit;
  if (oldCreditDiscount.percentage) {
    totalCredit = totalCredit + oldCreditDiscount.percentage;
  }
  return {
    ...defaultDiscountFormData,
    ownerEmail: discountFriends.ownerEmail,
    ownerId: discountFriends.ownerId,
    claimersEmail: [discountFriends.ownerEmail],
    claimersId: [discountFriends.ownerId],
    name: oldCreditDiscount.name
      ? oldCreditDiscount.name
      : generateDiscountNameFromUserAndType(discountFriends.ownerEmail, discountFriends.ownerId, DISCOUNT_BY_CREDIT_FRIENDS),
    type: DISCOUNT_BY_CREDIT_FRIENDS,
    percentage: totalCredit <= 100 ? totalCredit : 100,
    percentageOrAmount: "percentage",
    id: oldCreditDiscount.id ? oldCreditDiscount.id : uuidv4(),
    lastUpdateTS: new Date().getTime()
  }
}

export const getCuponReducedInfo = discount => {
  const { currency, amount, type, name } = discount;
  return {
    name, type,
    amountArs: currency === "ARS" ? amount : 0,
    amountUsd: currency === "USD" ? amount : 0
  }
}
