import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getCustomQueryUsers, getUsersPlanCounts } from 'services/UserStats';
import { planChipsColor } from 'variables/colors';
import { querySetStore } from 'redux/actions/QueryActions';


const UserPlanStats = () => {

  const dispatch = useDispatch();
  const defaultCountUsers = { '1': 0, '2': 0, '3': 0, '5': 0, '10': 0, '15': 0, '20': 0, '50': 0, '100': 0 };
  const [usersPlanCounts, setUsersPlanCounts] = useState(defaultCountUsers);
  const [usersByDealCounts, setUsersByDealCounts] = useState(0);

  useEffect(() => {
    const getUserCountsByPlan = async () => {
      let { countsUsersByPlan, countUsersByDeal } = await getUsersPlanCounts(dispatch);
      console.log("COUNT USERS: ", { countsUsersByPlan, countUsersByDeal });
      setUsersPlanCounts(countsUsersByPlan);
      setUsersByDealCounts(countUsersByDeal);
    }
    getUserCountsByPlan();
  }, []);

  const handleFilterByPlan = async planOrDeal => {
    let queryName = `byPlan-${planOrDeal}`;
    let resultQuery = await getCustomQueryUsers(queryName, 30, dispatch);
    dispatch(querySetStore({ name: queryName, limit: 30, collection: 'users' }));
  }

  return (
    <Grid container spacing={1} sx={{ mt: 2, mb: 2, justifyContent: 'center' }}>
      {Object.keys(usersPlanCounts).map((plan) => (
        <Box sx={plansChipsSx} key={plan} onClick={() => handleFilterByPlan(plan)}>
          <Typography sx={{ color: 'white' }}>Plan {plan}: {usersPlanCounts[plan] || 0}</Typography>
        </Box>
      ))}
      <Divider sx={{ borderWidth: "3px", marginLeft: '8px', marginRight: '8px' }} orientation="vertical" flexItem />
      <Box sx={dealsChipsSx} key='deals' onClick={() => handleFilterByPlan('shareDeal')}>
        <Typography sx={{ color: 'white' }}>Deals: {usersByDealCounts}</Typography>
      </Box>
    </Grid >
  );
};

export default UserPlanStats;

const plansChipsSx = { flexGrow: 0, cursor: 'pointer', backgroundColor: planChipsColor, padding: 1, borderRadius: '16px', marginLeft: '8px' };
const dealsChipsSx = { flexGrow: 0, cursor: 'pointer', backgroundColor: planChipsColor, padding: 1, borderRadius: '16px' };