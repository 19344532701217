import React, { useState, useEffect } from "react";

import {
  Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
  Tooltip, IconButton, Backdrop, CircularProgress
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';

import TextFieldWithInfo from 'components/TextField/TextFieldWithInfo';
import { postChangeStateToInfractions } from "utils/textToShow.utils";
import BasicCheckbox from "components/Checkbox/BasicCheckbox";
import { Info } from '@mui/icons-material';
import { toWithOutError } from 'utils';
import { usersGetOneByIdRedux } from "redux/actions/UsersActions";
import { queryRoyaltiesWithOp } from 'services/BackendCommunication';
import { userIsAdmin } from 'utils/users.utils';
import { albumsTakingDownByInfractionRedux } from "redux/actions/AlbumsActions";
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import { getOurStateFromFugaState } from "utils/albums.utils";

const AlbumInfractionDialog = ({ albumData, openInfractionDialog, setOpenInfractionDialog }) => {

  const dispatch = useDispatch();
  const currentUserData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(currentUserData.rol);

  const [emailObservations, setEmailObservations] = useState("");
  const [openLoaderSendingInfraction, setOpenLoaderSendingInfracion] = useState(false);
  const [buttonState, setButtonState] = useState("none");
  const [actions, setActions] = useState({ sendEmail: false, hideRoyalties: false, takedown: true });
  const [royaltiesGenerated, setRoyaltiesGenerated] = useState("...")

  useEffect(() => {
    const getRoyaltiesGenerated = async upc => {
      let royalties = await queryRoyaltiesWithOp(albumData.ownerId, 'upc', upc, 'sum', 'ourNetRevenue', [], isAdmin, dispatch)
      setRoyaltiesGenerated((royalties.length > 0 && royalties[0].revenuesUSD > 0) ? royalties[0].revenuesUSD : 0);
    }
    if (albumData.ownerId) {
      getRoyaltiesGenerated(albumData.upc?.toString() || "");
    }
  }, [albumData])


  const handleCancelDialog = () => {
    setOpenInfractionDialog(false);
  }

  const handleSendInfraction = async () => {
    setOpenLoaderSendingInfracion(true);
    let allResponses = await toWithOutError(dispatch(albumsTakingDownByInfractionRedux(albumData, actions, emailObservations)));
    if (allResponses === "ERROR") { setButtonState('error'); setOpenLoaderSendingInfracion(false) }
    setButtonState('success');
    setOpenLoaderSendingInfracion(false);
  }

  const closeInfractionDialog = () => {
    setButtonState('none');
    setOpenInfractionDialog(false);
  }

  const handleWantToSentObsEmail = (event) => setActions({ ...actions, sendEmail: event.target.checked });
  const handleWantToHideRoyalties = (event) => setActions({ ...actions, hideRoyalties: event.target.checked });
  const handleWantToTakedownRelease = (event) => setActions({ ...actions, takedown: event.target.checked });

  const successDialogTitle = actions.sendEmail ? 'Se ha notificado al usuario.' : 'Acciones realizadas.';

  return (
    <Dialog open={openInfractionDialog} onClose={handleCancelDialog} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>

      <InfoActionDialog id='infraction-dialog' isOpen={buttonState === 'success'} handleClose={closeInfractionDialog}
        title={successDialogTitle} contentTexts={[""]} />

      <Backdrop open={openLoaderSendingInfraction}>
        <CircularProgress />
      </Backdrop>

      <DialogContent>

        <DialogTitle id="form-title" textAlign='center' sx={{ fontSize: "2em", padding: '0' }}>Acciones a realizar</DialogTitle>
        <DialogTitle id="title-album" textAlign='center' sx={{ fontSize: "1.5em", padding: '0' }}>{`${albumData.title}`}</DialogTitle>

        <DialogContentText textAlign='left' paddingBottom={1}>
          {postChangeStateToInfractions(getOurStateFromFugaState(albumData.state, isAdmin))}
        </DialogContentText>

        {albumData.ownerEmail && <DialogContentText key={'owner email'}>
          Email Usuario: <b>{`${albumData.ownerEmail}`}</b>
        </DialogContentText>}

        {albumData.ownerId && <DialogContentText key={'owner id'}>
          ID Usuario: <b>{`${albumData.ownerId}`}</b>
        </DialogContentText>}

        {albumData.nombreArtist && <DialogContentText key={'artist'}>
          Artista: <b>{`${albumData.nombreArtist}`}</b>
        </DialogContentText>}

        <DialogContentText key={'royalties'}>
          Regalías del lanzamiento: <b>{Number.isFinite(royaltiesGenerated) ? `${royaltiesGenerated} USD` : "... USD"}</b>
        </DialogContentText>

        <Grid container item xs={12} paddingTop={1}>
          <Grid item xs={5} textAlign="initial" >
            <BasicCheckbox
              label={"Quieres enviarle un mail?"}
              onChecked={handleWantToSentObsEmail}
              checked={actions.sendEmail}
              color="#508062"
              labelColor="black"
            />
          </Grid>
          <Grid item xs={1} textAlign="start">
            <Tooltip title="El mail indicará el UPC, nombre del lanzamiento y el artista." >
              <IconButton>{<Info />}</IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        {actions.sendEmail && <Grid item xs={10}>
          <TextFieldWithInfo
            name="observaciones"
            fullWidth
            required
            label="Observaciones"
            multiline={true}
            value={emailObservations}
            onChange={(event) => setEmailObservations(event.target.value)}
            helperText="Este texto irá en el apartado de Observaciones del mail."
          />
        </Grid>}

        <Grid container item xs={12}>
          <Grid item xs={10} textAlign="initial" >
            <BasicCheckbox
              label={"Querés investigar las regalías del lanzamiento?"}
              onChecked={handleWantToHideRoyalties}
              checked={actions.hideRoyalties}
              color="#508062"
              labelColor="black"
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} >
          <Grid item xs={10} textAlign="initial" >
            <BasicCheckbox
              label={"Querés dar de baja el lanzamiento?"}
              onChecked={handleWantToTakedownRelease}
              checked={actions.takedown}
              color="#508062"
              labelColor="black"
            />
          </Grid>
        </Grid>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancelDialog} sx={buttonColorStyle}>
          Atras
        </Button>
        <Button onClick={handleSendInfraction} sx={buttonColorStyle}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlbumInfractionDialog;

const buttonColorStyle = { color: "#508062" };