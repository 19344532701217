import { planesLaFlota } from "variables/financial";

export const getCommonSpecsOfPlans = () => {
  return ["Lanzamientos ilimitados",
    "Sellos Discográficos personalizados",
    "Códigos UPC e ISRC incluidos!",
    "Todas las DSP's",
    "85% de Regalías",
    "Monetización a nivel global",
    "Retiro en USD o ARS",
    "Spotify for Artists",
    "YouTube for Artists",
    "SmartLink a las DSP's 👌🏻",
    "Soporte rápido por email 📧",
    "YouTube Content ID, Shazam, TikTok, Instagram y muchas más!",
  ]
}

export const artistsCantText = (cantArtists, upgrading, isCurrentPlan) => {
  if (cantArtists === 1) return '1 Artista';
  if (cantArtists >= 5 && upgrading) return 'De 5 a 100 Artistas!';
  if (cantArtists >= 5 && !isCurrentPlan) return 'De 5 a 100 Artistas!';
  return `${cantArtists} Artistas`;
}

export const artistsCantSubNameText = (cantArtists, upgrading, isCurrentPlan, subtitle) => {
  if (cantArtists >= 5 && upgrading) return "a partir de";
  if (cantArtists >= 5 && !isCurrentPlan) return "a partir de";
  return subtitle;
}

export const artistsCantSubText = (cantArtists, upgrading, isCurrentPlan, discountText) => {
  if (cantArtists === 1) return '';
  if (cantArtists >= 5 && upgrading) return "Desde " + discountText;
  if (cantArtists >= 5 && !isCurrentPlan) return "Desde " + discountText;
  return discountText;
}

export const getPlanCardButtonText = (cantArtists, upgrading, isCurrentPlan, userIsActive) => {
  if (!userIsActive && !upgrading) return "Contratar";
  if (upgrading && isCurrentPlan && cantArtists >= 5) return "Cambiar";
  if (upgrading && !isCurrentPlan) return "Cambiar";
  if (upgrading && isCurrentPlan && cantArtists < 5) return "Plan Actual";
  return "Plan Actual";
}