import React from "react";
import { Grid, Link } from "@mui/material";
import TextFieldWithInfo from "components/TextField/TextFieldWithInfo";

const AccountDataForLocalBankTransfer = ({ formData, setFormData, validator }) => {

  const { cbuCvuAlias, cuit } = formData;

  const cuitHelperLink = <Link sx={{ fontSize: '0.75rem' }} href="https://www.cuitonline.com/" target="_blank" variant="body2" underline="hover">
    Si no lo sabés hacé click acá.
  </Link>

  const handleCbuChange = (event) => {
    setFormData(event);
  };

  const handleCuitChange = (event) => {
    setFormData(event);
  };

  return (
    <Grid container spacing={1}>

      {/* CBU/CVU */}
      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          name="cbuCvuAlias"
          required
          label="CBU/CVU"
          helperTextDown="Ingresá el CBU/CVU de la Cuenta Bancaria donde enviaremos el dinero. Son 22 números."
          value={cbuCvuAlias}
          onChange={handleCbuChange}
          validatorProps={{
            restrictions: 'required|numeric|min:22|max:22',
            message: "Ingresa un CBU/CVU válido, debe tener 22 números.",
            validator,
          }}
        />
      </Grid>

      {/* CUIT */}
      <Grid item xs={6}>
        <TextFieldWithInfo
          fullWidth
          required
          name="cuit"
          label="CUIT"
          value={cuit}
          placeholder="20-12345678-0"
          helperTextDown={cuitHelperLink}
          onChange={handleCuitChange}
          validatorProps={{
            restrictions: 'required',
            message: "Ingresa un número de CUIT válido.",
            validator,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AccountDataForLocalBankTransfer;
